
/* assessment_skill
---------------------------------------- */
.skillDetailInfoLabel {
	vertical-align: top;
	line-height: 18px;
}
.skillDetailInfoIc {
	display: inline-block;
	width: 18px;
	height: 18px;
	background-image: url(/images/ic_hatena.png);
    margin-left: 12px;
	background-size: contain;
	cursor: pointer;
	vertical-align: top;
}
.skillDetailSideView {
	position: fixed;
	top: 65px;
	bottom: 0px;
	width: 440px;
	z-index: 14;
	box-shadow: var(
		--ds-shadow-overlay,
		0 2px 4px 0 rgba(0, 0, 0, 0.5),
		0 0 0 1px rgba(9, 30, 66, 0.08)
	);
	background: var(--ds-surface, #ffffff);
	height: calc(100vh - 195px);
	border-radius: 10px;
	margin: 2px;

	&.left {
		left: 200px;
	}
	&.middle {
		left: 42%;
	}
	&.right {
		right: 0px;
	}
	.viewArea {
		margin: 20px;
	}
	.viewHead {
		margin-bottom: 20px;
	}
	.viewTitle {
		font-size: 18px;
		font-weight: bold;
		margin-bottom: 20px;
	}
	.viewItem {
		padding-bottom: 5px;
		label {
			font-size: 11px;
		}
		div {
			font-size: 16px;
			margin: 5px 0px;
			border-bottom: 1px solid var(--color-border);
			overflow-wrap: break-word;
			white-space: pre-line;
		}
	}
	.scrollArea {
		overflow-y: scroll;
		height: calc(100vh - 320px);
	}
	.viewTable {
		table {
			width: 380px;
		}
		tr {
			text-align: left;
			border-bottom: 1px solid var(--color-border);
			th:first-child {
				width: 65px;
			}
		}
		th, td {
			vertical-align: top;
			text-align: left;
			padding: 5px;
			word-break: break-word;
		}
		th {
			font-size: 11px;
		}
		td {
			font-size: 16px;
		}
	}
}
