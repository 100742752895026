.listBox
	width: 700px
	display: flex
	&__box
		width: 270px
		&--w400
			width: 400px
	&__controller
		padding: 0 30px;
	&__label
		display: block
		text-align: center
		padding-bottom: .3em
	&__select
		width: 100%
		height: 100%
		border: 1px solid var(--color-border)
		border-radius: 3px
		padding: 5px 0
		overflow-y: scroll

		option
			color: var(--color-font-default)
	&__option
		font-size: 13px
		padding: 2px 10px
		color: var(--color-font-default)
		text-shadow: none;
		&[disabled]
			color var(--color-font-gray-02)
	&__button
		display: block
		cursor: pointer
		margin-top: 5px
		&:first-child
			margin-top: 0
		&[name="up"]
			margin-top: 30px
	&__row
		display: flex
		flex-wrap: wrap
		&--alignCenter
			align-items: center
