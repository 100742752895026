
/* お知らせ一覧
---------------------------------------- */
.modInfoList
	border-top: solid 1px var(--color-border)
	&__item
		border-bottom: solid 1px var(--color-border)
		font-size: 13px
		a
			display: block
			padding: 18px 0
			color: var(--color-font-default)
			position: relative
			&:after
				border-right: 2px solid var(--color-border)
				border-top: 2px solid var(--color-border)
				content: ""
				display: block
				height: 10px
				position: absolute
				right: 12px
				top: 50%
				margin-top: -5px
				transform: rotate(45deg)
				transition: transform 0.1s
				width: 10px
			&:hover
				background-color: $color-highlight
				&:after
					border-right-color: $color-orange-01
					border-top-color: $color-orange-01
		span
			display: block
			padding: 18px 0
			color: var(--color-font-default)
.listItem
	display: inline-block
	margin-left: 35px
	font-size: 13px
	&--date
		margin-left: 0


// 一覧へ
.toArchive
	margin-top: 15px
	text-align: right
	a
		display: inline-block
		padding: 5px 35px 5px 0
		font-size: 13px
		text-align: left
		position: relative
		&:hover
			text-decoration: underline
		&:after
			border-right: 2px solid $color-blue
			border-top: 2px solid $color-blue
			content: ""
			display: block
			height: 10px
			position: absolute
			right: 12px
			top: 50%
			margin-top: -6px
			transform: rotate(45deg)
			transition: transform 0.1s
			width: 10px


/* お知らせ詳細
---------------------------------------- */
.modArticle
	line-height: 1.5
	&__head
		padding: 25px 0
	&__body
		padding: 0 0 25px
.articleDate
	display: inline-block
	margin-right: 20px
.articleHeading
	display: inline-block
	font-size: 15px
	font-weight: bold
.detailToArchive
	margin-top: 15px
	a
		display: inline-block
		padding: 5px 0 5px 35px
		font-size: 13px
		text-align: left
		position: relative
		&:hover
			text-decoration: underline
		&:after
			border-left: 2px solid $color-blue
			border-top: 2px solid $color-blue
			content: ""
			display: block
			height: 10px
			position: absolute
			left: 12px
			top: 50%
			margin-top: -6px
			transform: rotate(-45deg)
			transition: transform 0.1s
			width: 10px


/* alertBlock
---------------------------------------- */
.alertBlock
	margin-bottom: 60px
	.formHeading
		color: $color-orange-01
	.modInfoList
		&__item
			span
				color: $color-orange-01

