.tree__diagram
	background-color: #fff
	height: 55vh
	min-height: 290px
	left: 0
	min-width: 700px
	padding: 23px 12px 25px 20px
	position: absolute
	top: 0
	width: calc(100% - 545px)
	&--extends
		height: 100%
	&__actions
		.tree__wrapper--since-2019-04 &
			display: none
		height: 38px
		&--organization-show
			.tree__diagram__actions--left
				width: 100%

		&:after
			clear: both
			content: ''
			display: block
		&--left,
		&--right
			height: 100%
			> *
				display: inline-block
				vertical-align: top
		&--left
			float: left
			text-align: left
			> *
				margin: 0 5px
				&:first-child
					margin-left: 0

			div[style*='margin-top'],
			div[style*='margin-top'] + *,
			div[style*='margin-top'] + * + *
				position: absolute;
				top: 23px

			div[style*='margin-top']
				right: 110px
			div[style*='margin-top'] + *
				right: 30px
			div[style*='margin-top'] + * + *
				top: 65px
				right: 30px
		&--right
			float: right
			text-align: right
		&__button
			.tree__wrapper--since-2019-04 &
				border: solid 1px #666666
				border-radius: 2px
				background-color: #fff
				color: var(--color-font-default)
			background-color: #f08200
			color: #fff
			cursor: pointer
			font-size: 14px
			height: 38px
			line-height: 38px
			text-align: center
			// width: 140px
			padding: 0 1em;
			a
				display: block
				height: 100%
				width: 100%
				color: #fff
				&:link, &:visited, &:hover &:active
					color: #fff
			&--open-history
				background-color: #999
				float: right
				margin: 10px 21px 0 6px
			&--save-as-simulation
				width: 190px
			&--save-memo
				height: 30px
				line-height: 30px
				margin: 0 auto
				width: 120px
		&__data
			&--base-date
				font-size: 12px
				line-height: 38px
				margin-right: 5px
				display: flex
				align-items: center

				input
					position: relative
					z-index: 9
					margin-left: 5px
					height: 25px
				button
					margin-top: 5px
		&__input
			&--name,
			&--base-date
				border: 1px solid var(--color-border-30)
				border-radius: 2px
				color: #999
				font-size: 12px
				height: 38px
				line-height: 1
				padding: 12px 10px
			&--name
				margin-right: 10px
				width: 180px
			&--base-date
				width: 140px!important
		&__icon--memo
			background-image: url(/images/ic_note.png)
			background-position: center
			background-repeat: no-repeat
			height: 32px
			width: 28px
			position: relative
		&__trigger-toggle-memo
			cursor: pointer
			height: 100%
			left: 0px
			position: absolute
			top: 0px
			width: 100%
		&__memo-box
			background-color: #fff
			border-radius: 5px
			box-shadow: 0px 0px 3px 0px rgba(0,0,0,.2)
			display: none
			height: 122px
			left: -246px
			position: absolute
			top: 40px
			width: 298px
			z-index: 8
			&.display
				display: block
		&__memo-textarea
			height: 73px
			padding: 18px
			resize: none
			width: 100%
		&__button--close-memo-box
			background-image: url(/images/ic_del3.png)
			cursor: pointer
			height: 20px
			position: absolute
			right: -10px
			top: -10px
			width: 20px
	.input-calendar
		height: 100%
		margin-right: 0px
		&-wrapper
			left: 0px
			top: 37px
			z-index: 100
		.calendar-icon
			height: 100%
			svg
				display: none
	&__toggle-buttons
		height: 28px
		margin-top: 12px
		width: 266px

		.tree__wrapper--since-2019-04 &
			position: relative;
			z-index: 10
	&__toggle-button
		&--open,
		&--close
			background-color: #999
			background-position: 14px center
			background-repeat: no-repeat
			border-radius: 2px
			color: #fff
			cursor: pointer
			font-size: 12px
			height: 100%
			line-height: 1
			padding: 8px 39px
			padding-right: 0
			width: 130px
		&--open
			background-image: url(/images/ic_open_2.png)
			float: left
		&--close
			background-image: url(/images/ic_close_2.png)
			float: right
		&--member
			background-image: url(/images/member.png)
			// float: left
	&__wrapper
		.tree__wrapper--since-2019-04 &
			margin-top: -35px
			height: 70vh

			.tree__diagram__section
				overflow: hidden;
				max-height: calc(95vh - 250px);

				.tree__diagram__section
					// overflow: auto;
					max-height: none;


			.tree__diagram__section > .tree__diagram__section__children
				max-height: calc(95vh - 250px - 40px);
				overflow: auto

				.tree__diagram__section__children
					overflow: hidden
					max-height: none

		height: calc(55vh - 160px)
		margin-top: 30px
		user-select: none
		padding-bottom: 50px
		&--new
			position: relative
	&__company-name,
	&__section
		border-radius: 2px
		color: var(--color-font-default)
		font-size: 12px
		line-height: 1
		position: relative
		width: 215px
		&--new
			width: auto
		&__parent
			background-color: #f4f4f4
			cursor: pointer
			margin-top: .3em
			padding: .7em 0 .7em 2.7em
			position: relative
			&--root
				padding-top: 1em
				cursor: default
				> .tree__diagram__section__handle
					cursor: default
				> .tree__diagram__section__toggle-button
					cursor: pointer

				.tree__wrapper--since-2019-04 &
					background: transparent
			&--active
				background-color: #cdcdcd
				cursor: default
				> .tree__diagram__section__handle
					cursor: default
				> .tree__diagram__section__toggle-button
					cursor: pointer
			&--new
				width: 215px
		&__handle
			background: url(/images/ic_draggable.png) center center / contain
			cursor: all-scroll
			height: 100%
			left: 0
			position: absolute
			top: 0
			width: 2em
		&__children
			// margin-left: 35px
			margin-left: 15px
			& &
				margin-left: 27px

			.tree__wrapper--since-2019-04 &
				margin-left: 0px
				& &
					margin-left: 0px

		&--dragging
			position: absolute
			z-index: 500
			margin: 0
			pointer-events: none
			.tree__diagram__section__parent
				margin-left: 12px !important
			> .tree__diagram__section__parent
				margin: 0
				margin-left: 0 !important
		&--placeholder
			background-color: #f7fdff
			border: 1px dashed #c7daff
			font-size: 12px
			height: 29px
			margin-top: .3em
			text-align: center
			width: 280px
			// margin-left: 0 !important
		&__toggle-button
			display: none
			height: 20px
			left: 2.4em
			position: absolute
			bottom: 50%
			transform: translateY(55%)
			width: 20px

		&--close,
		&--open
			> .tree__diagram__section__parent
				padding-left: 4.3em
		&.tree__diagram__section--close &__toggle-button,
		&.tree__diagram__section--open  &__toggle-button
			display: block
		&.tree__diagram__section--close > &__parent > &__toggle-button
			background: url("/images/ic_close_1.png") center center / 1.3em 1.3em no-repeat
			//background-image: url(/images/ic_close_1.png)
		&.tree__diagram__section--open  > &__parent > &__toggle-button
			background: url("/images/ic_open_1.png") center center / 1.3em 1.3em no-repeat
			//background-image: url(/images/ic_open_1.png)
		&__user-move-label
			background-color: #fff8f3
			border: 1px dashed #ffc7c7
			font-size: 12px
			height: 38px
			line-height: 1
			margin-top: 10px
			padding: 13px 0 13px 10px
			text-align: center
	&__company-name
		padding: .7em
		background-color: #f4f4f4

.tree__wrapper--organization .tree__diagram__wrapper
	height 57.5vh
