.tabs
	overflow: hidden
	position: relative
	z-index: 10
	&--item
		float: left
		border: 1px solid
		background:$color-tab
		padding: 14px 30px 14px
		font-size: 16px;
		input[type="text"]
			background: $color-tab
			border: none
			border-radius: 3px
			&:focus
				border: solid 1px $color-gray-01
				outline: none
		&.is-active
			background: $color-white;
			input[type="text"]
				background: $color-white;
				border: none
.tab__item
	display: inline-block
	background: $color-gray-03
	border: 1px solid var(--color-border)
	font-size: 16px
	height: 40px
	line-height: 40px
	padding: 0 10px 0 25px
	border-top-left-radius: 2px
	border-top-right-radius: 2px
	margin-right: 4px
	margin-top: 4px
	cursor: pointer
	span
		display: inline-block
		vertical-align: middle
	&.is-active
		background: $color-white
		border-bottom: 1px solid $color-white
	&--add
		width: 42px
		text-align: center
		padding: 0
		background: $color-orange-01
		border: solid 1px $color-orange-01
.tab__txt
	margin-right: 20px
	color: var(--color-font-default)

.tab__panel
	padding: 0 20px 30px
	border: 1px solid var(--color-border)
	position: relative
	z-index: 0
	top: -1px
