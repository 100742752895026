.buttons
	width: 100%
	overflow: hidden
	padding-top: 40px
	display: flex
	flex-wrap: wrap
	justify-content: center
	align-items: center
	&--words
		justify-content: flex-start
		padding-top: 0
		margin-bottom: 20px
		margin-left: -3px
	&--pl270
		justify-content: flex-start
		padding-left: 270px
		margin-top: 30px
		.form__checkbox__label
			display: block
			margin-bottom: 13px
			margin-left: 10px
	&--pt0
		padding-top: 0
.button
	margin: 0 9px
	border-radius: 2px
	background-color: $color-orange-01
	padding: 12px 20px 10px
	letter-spacing: normal
	color: $color-white
	font-size: 14px
	text-align: center
	cursor: pointer
	&--wre
		width: 150px
	&--w120
		width: 120px
	&--w150
		width: 150px
	&--w200
		width: 200px
	&--cancel
	&--gray
		background-color: $color-gray-02
	&--lightgray
		background-color: $color-gray-03
		color: $color-gray-01
	&--disable
		background-color: $color-disable
		color: $color-gray-03
		pointer-events: none
	&--file
		margin: 0
		width: 90px
		background-color: $color-gray-03
		padding: 8px 0 6px
		color: $color-gray-01
	&--word
		margin: 0 3px
		width: 34px
		padding: 12px 0 10px
		border-radius: 0
		color: $color-gray-02
		background-color: $color-gray-04
		&:hover
		&.active
			background-color: $color-orange-01
			color: $color-white
	&--small
		padding: 5px 12px
		font-size: 12px
	&--heightlarge
		padding-top: 21px
		padding-bottom: 19px
	&--organization
		padding: 9px 20px 8px
	&--mx-0
		margin-left: 0
		margin-right: 0
	&--noBorder
		border: none
	&__info
		background: #999
		color: #fff
		display: flex
		align-items: center
		padding: 10px
		width: 40px
		height: 40px
		border-radius: 2px
		cursor: pointer
		&.is-active
			width: 100%
			height: auto
			.button__info__txt
				display: inline
	&__info__txt
		margin-left: 20px
		display: none
		line-height: 1.4
	&__info__ic
		display: inline-block
		width: 18px
		height: 18px
		background-image: url(/images/ic_info.png)
	&:hover
		opacity: .7
		transition: .5s
	&--calendar
		display: inline-block;
		margin: 0;
		overflow: hidden;
		margin-bottom: 10px;
		margin-left: 2px;
		padding: 5px 4px 4px
	&__subtitle
		font-size: 12px
		display: block



.buttonIcon
	display: inline-block
	width: 32px
	height: 32px
	background-repeat: no-repeat
	background-position: center center
	background-size: contain
	cursor: pointer
	.formTable &
		position: relative
		top: 6px
		left: 5px
		display: inline-block
		width: 25px
		height: 25px
		background-repeat: no-repeat
		background-position: center center
		background-size: contain
		cursor: pointer
		&--add + .buttonIcon
				margin-left: 3px
		&--resetPosition
			top: 0
			left: 0

	&--small
		width: 20px
		height: 20px
	&--add
		background-image: url(/images/ic_add2.png)
		& + .buttonIcon
			margin-left: 3px
	&__tab--add
		width: 12px
		height: 12px
		background-image: url(/images/ic_add3.png)
	&--del
		background-image: url(/images/ic_del2.png)
	&--del--light
		background-image: url(/images/ic_del2.png)
	&--del--popup
	&--del--calender
		background-image: url(/images/ic_del2.png)
		position: absolute
		top: 16px
		right: 16px
		left: auto

// ソート
.btn__sort
	display: inline-block
	width: 15px
	height: 12px
	margin: 0 3px
	vertical-align: bottom
	background-repeat: no-repeat
	background-position: center
	cursor: pointer
	&--desc
		background-image: url(/images/ic_arrow_down.png)
	&--desc-current
		background-image: url(/images/ic_arrow_down_current.png)
	&--asc
		background-image: url(/images/ic_arrow_up.png)
	&--asc-current
		background-image: url(/images/ic_arrow_up_current.png)

@media screen and (max-width: 1050px)
	.buttons
		padding-top: 0

	.button
		font-size: 5vmin
		margin: 0 5vmin
		&--wre
			width: auto
			padding: 3vmin 8vmin
