// 個別調整CSS

// 契約内容表示：ロゴ画像
.logo_image
	width: 125px
	img
		max-width: 100%
		width: auto
		height: auto

// メニュー内のセレクトボックス
.main__menu__item--selectbox
	margin-left: 0
	padding-left: 20px
	vertical-align: middle

// メニュー内のセレクトボックス
.main__menu__calendar
	padding-top: 9px;
	.form__date
		width: auto;
	.input-calendar-wrapper
		z-index: 10

// 企業管理Home
.masterHomeLower
	margin-top: 40px
	display: flex
	-webkit-align-items: flex-start
	align-items: flex-start
	-webkit-justify-content: space-between
	justify-content: space-between
	&__links
		width: 45%
	&__corps
		width: 45%

.memberHomeLower
	&__block
		clear: both
		&::after
			content: ""
			display: block
			clear: both
	&__corps
		width: 30%
		margin-right: 5%
		float: left
	&__workflowRequestCount
		width: 32.5%
		float: left

// 権限 シミュレーション結果
.resultHead
	position: relative
	.toggle__item
		display: block
		position: absolute
		right: 0
		bottom: 0
		z-index: 1
.permission__name
	color: $color-orange-01
	font-weight: bold

// 従業員 絞り込み検索
#popupEmployeeSearch
	.formTable__cell
		padding:3px

// 施策情報履歴 絞り込み検索
#programMemberSearchModal
	.formTable__cell
		padding:3px


.modal--center
	margin: auto
	position: absolute
	left: 0
	right: 0
	top: 0
	bottom: 0


// 契約企業管理
.main--enterprise
	min-width: 1050px

// ワークフロー：申請
.main--workflowApply
	min-width: 700px

// ワークフロー：承認
.main--workflowAgree
	min-width: 950px

// 給与管理：テンプレート
.main.payrollEditor
	transform: none
	background: transparent
	padding: 1em

	.payrollEditor__menu
		height: 700px
		height: calc(100vh - 90px)
		overflow: auto
		position: fixed
		left: 215px
	.payrollEditor__items
		overflow: visible
		padding-right: 0

// 給与管理：テンプレート詳細
.main-supplyDetail
	min-width: 700px

// 長いテキストの制御
.overflowText
	width: 95px
	margin: 0 auto
	overflow: hidden
	text-overflow: ellipsis
	white-space: nowrap
