clearfix()
	+cache('clearfix')
		&::after
			content ""
			display block
			clear both

.payrollEditor
	display: flex
	min-width: 1194px
	padding: 34px
	background-color: $color-white

	&__wrapper
		position: fixed
		left: 402px
		overflow: auto
		width: calc(100vw - 402px)
		max-width: 930px
		height: calc(100vh - 90px)

	&__column
		box-shadow: 0 1px 0 #e9e9e9, 0 0 5px rgba(66, 66, 66, .2)
		background: $color-white

	&__heading
		font-size: 1.3rem
		line-height: 1.25
		.icon
			margin-left: 13px

	.icon,
	&__icon
		display: inline-block

		&:before
			content: ''
			display: inline-block
			vertical-align: middle

		&--plus
			cursor: pointer

			&:before
				width: 16px
				height: 16px
				background: url(/images/ic_add.png) no-repeat
			&:hover
				&:before
					background: url(/images/ic_add_a.png) no-repeat
		&--draggable
			&:before
				width: 11px
				height: 10px
				background: url(/images/ic_draggable_02.png) no-repeat

		&--delete
			cursor: pointer
			&:before
				width: 16px
				height: 16px
				background: url(/images/ic_del4.png) no-repeat

	&__menu
		//float: left
		width: 176px
		margin-right: 10px
		padding: 16px 11px 20px 13px

		.payrollEditor__heading
			margin-bottom: 13px

	&__fields
		width: 930px
		font-size: .9em
		padding: 20px 20px 30px

		.payrollEditor__heading
			margin-bottom: 10px

		.buttons
			padding-top: 0

	&__items
		height: 100%
		overflow-y: auto
		padding-right: 12px

	&__item
		position: relative
		margin: 10px 0
		padding: 0 6px 0 8px
		border-radius: 2px
		background-color: $color-gray-04
		cursor: move
		&:first-child
			margin-top: 0
		&:last-child
			margin-bottom: 0
		// ラベル変更
		&--edit
			.payrollEditor__item-clickable
				display: none
		// drag済み
		&--empty
			background-color: $color-white
			border: 1px solid #e4e4e4
			cursor: default
			.payrollEditor__item-icon
				opacity: 0
			.payrollEditor__item-label input
				color: rgba(102, 102, 102, .5)
				user-select: none
			.payrollEditor__item-restore
				display: block
		&--dragging
			opacity: 0
		&--selected
			background-color: #cdcdcd


	&__item-inner
		display: flex
		flex-wrap: nowrap
		justify-content: flex-start
		align-items: center
		height: 36px

	&__item-icon
		width: 19px
		padding-right: 8px

	&__item-label
		position: relative
		max-width: 90px
		padding-right: 5px
		font-size: 1.2rem

		input
			width: 100%
			color: var(--color-font-default)
			font-size: inherit
			border: 1px solid transparent
			&:focus
				background-color: $color-white
				border-color: #e4e4e4

	&__item-clickable
		position: absolute
		top: 0
		left: 0
		width: 100%
		height: 100%
		cursor: text

	&__item-delete
		width: 16px
		margin-left: auto
		.icon
			display: none

	&__item-restore
		display: none
		position: absolute
		top: 0
		left: 0
		z-index: 10
		width: 100%
		height: 100%

	// hover
	&__item
		&:hover
			.payrollEditor__item-delete .icon
				display: inline-block
		&--empty
			&:hover
				.payrollEditor__item-delete .icon
					display: none

	&__drop-area
		position: relative
		height: 100%
		border: 1px dashed #c7c7c7
		background-color: #fbfbfb
		text-align: center
		opacity: 0
		transition: opacity .2s ease

		&:before
			content: attr(data-text)
			display: inline-block
			position: absolute
			top: 0
			bottom: 0
			left: 0
			right: 0
			height: 1em
			margin: auto
			font-size: 1.2rem
		&.can-drop
			opacity: 1

	&__drag-preview
		width: 140px
		.payrollEditor__item
			box-shadow: 0 1px 3px rgba(0, 0, 0, .2)

	&__top-view
		margin-bottom: 40px
		font-size: 1.2rem
		clearfix()

	&__basic-info
		float: left
		width: 434px
		margin-right: 31px
		margin-top: 10px

		table
			width: 100%
			th, td
				border: 1px solid #eee
				text-align: left
				vertical-align: top
			th
				width: 86px
				padding: 13px 8px 13px 14px
				border-top: none
				border-bottom: none
			td
				position: relative
				padding: 0
				z-index: -1
			tr:nth-child(odd)
				th, td
					background-color: $color-white
			tr:nth-child(even)
				th, td
					background-color: $color-table-bg-even
			tr:first-child
				th
					border-top: 1px solid #eee
			tr:last-child
				th
					border-bottom: 1px solid #eee


			.payrollEditor__table-cell
				position: relative
				z-index: 5
				min-height: 41px
			.payrollEditor__drop-area
				//display: none !important
				position: absolute
				top: 0
				left: 0
				z-index: 1
				width: 100%
				height: 100%

	&__table-cell
		font-size: 1.2rem
		padding: 13px 18px;
		border: 1px solid transparent
		cursor: move
		&:hover
			border-color: #c7c7c7
		&--empty
			cursor: default
			&:hover
				border-color: transparent
		&--error
			border-color: $color-red !important
			color: $color-red
		&--drag-preview
			width: 346px
			background: $color-white
			//border-color: #c7c7c7
			box-shadow: 0 1px 3px rgba(0, 0, 0, .2)
		&--static
			cursor: default
			&:hover
				border-color: transparent

	&__remarks
		float: left
		width: 403px
		margin-top: 10px

	&__remarks-area
		position: relative
		width: 100%;
		height: 200px

		.payrollEditor__table-cell
			position: relative
			z-index: 5
			width: 100%
			height: 100%
			padding: 13px 18px
			border: 1px solid #eee

		.payrollEditor__drop-area
			position: absolute
			top: 0
			left: 0
			z-index: 1
			width: 100%
			height: 100%



	&__bottom-view
		display: flex
		justify-content: space-between
		font-size: 1.2rem

	&__col4
		width: 217px

.payrollEditorDetail
	margin-bottom: 30px

	&__header
		height: 32px
		background-color: $color-gray-03
		text-align: center
		line-height: 32px

	&__caption
		display: table
		position: relative
		//z-index: 2
		width: 100%
		height: 37px
		border: 1px solid #eee
		box-shadow: 0 1px 0 #e9e9e9, 0 0 4px rgba(66, 66, 66, .09)

	&__left
		display: table-cell
		width: 129px
		padding: 0 10px
		vertical-align: middle

	&__right
		display: table-cell
		width: 86px
		vertical-align: middle

	&__inputs
		display: flex
		align-items: center

	&__caption-text
		position: relative
		text-align: center
		width: 33.3333%
		height: 37px

		span
			position: absolute
			top: 50%
			left: 50%
			width: 1em
			transform: translate(-50%, -50%)

	&__inner
		position: relative
		.payrollEditor__drop-area
			min-height: 38px

	&__items
		border: 1px solid #eee

		&.is-active
			border-color: #c7c7c7
		&.is-empty
			border: none

	&__item
		position: relative
		display: table
		border-top: 1px solid #eee
		font-size: 1.2rem

		&:first-child
			margin-top: -1px !important
		&:nth-child(odd)
			background-color: $color-white
		&:nth-child(even)
			background-color: #f9fcfd
		&:hover
			z-index: 10
			margin: 0 -1px -1px
			border: 1px solid #c7c7c7

		&.is-move
			border: 1px solid #c7c7c7

		.payrollEditorDetail__check
			border-left: 1px solid #eee
		.payrollEditorDetail__left
			cursor: move
			width: 60%
			font-size: .9em
		&--drag-preview
			width: 215px
			background-color: $color-white
			box-shadow: 0 1px 3px rgba(0, 0, 0, .2)
		&--static
			&:hover
				margin: 0
				border: none
				border-top: 1px solid #eee
			.payrollEditorDetail__left
				height: 37px
				cursor: default
			.payrollEditorDetail__right
				height: 37px
				padding: 0 10px
				text-align: right

	&__check
		position: relative
		width: 33.3333%
		height: 37px
		text-align: center

		.form__checkbox__label
			position: absolute
			top: 50%
			left: 50%
			transform: translate(-50%, -50%)

.paymentPager
	margin-bottom: 15px
	.button:first-child
		display: inline-block
		margin-left: 0

.paymentSelect
	margin-bottom: 15px
