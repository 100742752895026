
// 通知
.notice_wrap
	display: none
	width: 100%
	height: 100%
	position: fixed
	left: 0
	right: 0
	top: 0
	bottom: 0
	z-index: 10000
	margin: auto
.notice
	background: rgba(66,66,66,.8)
	width: 100%
	height: 80px
	color: $color-white
	font-size: 18px
	text-align: center
	line-height: 80px
	position: absolute
	left: 0
	top: 50%
	margin-top: -40px
	z-index: 100

@media screen and (max-width: 1050px)
	.notice
		font-size: 4vmin
