
/* ----------------------------------------
	data table
---------------------------------------- */

.data
	border-left: 1px solid var(--color-border)
	position: relative
	border-top: 1px solid var(--color-border)
	overflow-x: auto
	white-space:nowrap
	&--noPager
		border-top: 0
	&--def
		border-left: 1px solid var(--color-border)
		.dataTable
			border-top: 1px solid var(--color-border)
			background: #fff
			margin-bottom: 10px;


.dataTable
	font-size: 1.1rem
	vertical-align: top
	&__row
		border-bottom: 1px solid var(--color-border)
		&:nth-child(even)
			background: $color-table-bg-even
		&.disabled
			.dataTable__td
				background: $color-table-bg-disabled
		&.selected
			.dataTable__td
				background: $color-table-bg-selected
	&__row_orange
		border-bottom: 1px solid var(--color-border)
		&:nth-child(even)
			background: $color-table-bg-even-orange
		&.disabled
			.dataTable__td
				background: $color-table-bg-disabled
		&.selected
			.dataTable__td
				background: $color-table-bg-selected
	&__cell
		&--check
			width: 40px
			padding: 0
		&--control
			width: 40px
			padding: 0
	&__th
		padding: 0 2px
		height: 40px
		vertical-align: middle
		white-space: nowrap
		position: relative
		&:last-child
			border-right: 1px solid var(--color-border)
		&--empty
			&:before
				content: ""
				display: block
				width: 80px
				height: 13px
	&__td
		padding: 0 1px
		vertical-align: middle
		text-align: center
		border-right: 1px solid var(--color-border)
		height: 45px
		&--link
			cursor: pointer
			color: #49b0be
		&--w500
			width: 500px
		&--px1
			padding-left: 1em
			padding-right: 1em
	&--full
		width: 100%
		&__th.last-child
		&__td.last-child
			border-right: 0
	&__check-all-type-change-box
		position: absolute
		top: 13px
		left: 32px
		width: 260px
		// height: 84px
		border: 1px solid var(--color-border)
		border-radius: 2px
		background-color: #fff
		padding: 11px 0
		z-index: 40
		display: none
		&--display
			display: block
		&__row
			height: 30px
			width: 100%
			padding: 4px 0 4px 10px
			text-align: left
			&--selected
				background-color: $color-highlight
			.form__radio__label
				margin-right: 0

// スクロールしないテーブル
.dataTable_fix
	display: inline-block
	vertical-align: top
	.dataTable
		width: 627px
		border-right: 1px solid var(--color-border)
	.dataTable__employee
		width: 627px
		border-right: 1px solid var(--color-border)
		white-space: nowrap
		text-overflow: ellipsis
	.dataTable__row.disabled
		.dataTable__td:nth-of-type(1)
			border-left: solid 3px $color-gray-02
	.dataTable__row.selected
		.dataTable__td:nth-of-type(1)
			border-left: solid 3px $color-orange-01
	.dataTable__td
		padding: 0 2px
	&--short
		width: 150px
		.dataTable
			width: 150px


// スクロールするテーブル
.dataTable_scroll
	display: inline-block
	max-width: 760px
	margin-left: 1px
	overflow-x: scroll
	width: calc(100% - 628px)
	vertical-align: top
	.dataTable
		border-left: 0
		border-right: 1px solid var(--color-border)
		.dataTable__row
			white-space: nowrap
			padding: 0 12px !important
			&:last-child
				border-right: 0
		.dataTable__td:last-child
			border-right: 0
	&--short
		width: calc(100% - 151px)


// 動的バージョン
// スクロールしないテーブル
.dataTable_fix2
	display: inline-block
	vertical-align: top
	white-space: nowrap;
	.modTable__th
		white-space: nowrap;
		border-bottom: 1px solid var(--color-border)
		border-top: 0
		border-right: 0
	.dataTable
		border-right: 1px solid var(--color-border)
	.dataTable__row.disabled
		.dataTable__td:nth-of-type(1)
			border-left: solid 3px $color-gray-02
	.dataTable__row.selected
		.dataTable__td:nth-of-type(1)
			border-left: solid 3px $color-orange-01
	.dataTable__td
		padding: 0
.dataTable
	&--scroll
		max-width: 100%;
		width: 100%;

// スクロールするテーブル
.dataTable_scroll2
	display: inline-block
	overflow-x: scroll
	vertical-align: top
	white-space: nowrap;
	.modTable__th
		white-space: nowrap;
		border-bottom: 1px solid var(--color-border)
		border-top: 0
	.modTable__td
		border-top: 0
		white-space: nowrap;
	.dataTable
		border-left: 0
		.dataTable__row
			white-space: nowrap
			padding: 0 12px !important

.data__overlay
	position: absolute
	top: 60px
	left: 0
	height: 40px
	width: 100%
	background: rgba(66,66,66,0.8)
	display: flex
	align-items: center
	justify-content: flex-start
	padding-left: 55px
	opacity: 0
	transition: opacity .1s ease-in-out, z-index .1s ease-in-out
	z-index: -1
	&__item
		margin: 0 35px
		color: $color-gray-03
		font-size: 1.2rem
		cursor: pointer
		&:hover
			text-decoration: underline
	&__item01:before
		content: ''
		background: url(/images/ic_switch.png) no-repeat
		background-size: contain
		width: 24px
		height: 12px
		display: inline-block
		margin-right: 6px
		vertical-align: middle
		margin-bottom: 2px
	&__item02:before
		content: ''
		background: url(/images/ic_edit.png) no-repeat
		background-size: contain
		width: 15px
		height: 15px
		display: inline-block
		margin-right: 8px
		vertical-align: middle
		margin-bottom: 2px
	&__item03:before
		content: ''
		background: url(/images/ic_tree_c.png) no-repeat
		background-size: contain
		width: 15px
		height: 14px
		display: inline-block
		margin-right: 8px
		vertical-align: middle
		margin-bottom: 2px
	&__item04:before
		content: ''
		background: url(/images/ic_tree_info_c.png) no-repeat
		background-size: contain
		width: 15px
		height: 14px
		display: inline-block
		margin-right: 8px
		vertical-align: middle
		margin-bottom: 2px
	&__item05:before
		content: ''
		background: url(/images/ic_history.png) no-repeat
		background-size: contain
		width: 15px
		height: 14px
		display: inline-block
		margin-right: 8px
		vertical-align: middle
		margin-bottom: 2px
	&__item06:before
		content: ''
		background: url(/images/ic_detail.png) no-repeat
		background-size: contain
		width: 15px
		height: 16px
		display: inline-block
		margin-right: 8px
		vertical-align: middle
		margin-bottom: 2px
	&__item07:before
		content: ''
		background: url(/images/ic_import_gear.png) no-repeat
		background-size: contain
		width: 15px
		height: 16px
		display: inline-block
		margin-right: 8px
		vertical-align: middle
		margin-bottom: 2px
	&__item08:before
		content: ''
		background: url(/images/ic_output.png) no-repeat
		background-size: contain
		width: 15px
		height: 16px
		display: inline-block
		margin-right: 8px
		vertical-align: middle
		margin-bottom: 2px
	&--demo
		for n in 1..10
			&:nth-child({n})
				opacity: 1
				z-index: 1
				top: (40 * n)px

input[type="checkbox"],
input[type="radio"]
	display: none
	cursor: pointer
	&:focus
		outline: none

/*
.data__edit
	appearance: none
	width: 16px
	height: 16px
	display: inline-block
	margin: 0
	padding: 0
	background: url(/images/ic_controll.png) no-repeat
	border: none;
	&:focus
		border: none;
*/

.data__check
	appearance: none
	width: 14px
	height: 14px
	display: inline-block
	margin: 0
	padding: 0
	background: url(/images/ic_checkbox.png) no-repeat
	&:checked
		background: url(/images/ic_checkbox_c.png) no-repeat

.itemMasterSection
	.dataTable_fix
		// width: 521px
		.dataTable
			width: 521px
	.dataTable_scroll
		width: calc(100% - 522px)

.itemMasterStatus
	.dataTable_fix
		// width: 337px
		.dataTable
			width: 337px
	.dataTable_scroll
		width: calc(100% - 338px)

.itemMasterPattern
	.dataTable_fix
		// width: 312px
		.dataTable
			width: 312px
	.dataTable_scroll
		width: calc(100% - 313px)

.itemMasterLocation
	.dataTable_fix
		// width: 546px
		.dataTable
			width: 546px
	.dataTable_scroll
		width: calc(100% - 547px)

.itemMasterPosition
	.dataTable_fix
		// width: 407px
		.dataTable
			width: 407px
	.dataTable_scroll
		width: calc(100% - 408px)
