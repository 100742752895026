.modTable
	white-space: nowrap
	&--relative
		position: relative
		width: 90%
		overflow: scroll
	&__thead
		&--fixed
			position: absolute
			top: 0
			left: 0
	&__tbody
		&--fixed
			display: block

	&--align-center
		margin-left: auto
		margin-right: auto
	&--full
		width: 100%
	&--w500
		width: 500px
	&__th
	&__td
		background-color: $color-white
		padding: 14px 20px
		color: var(--color-font-default)
		font-size: 11px
	&__th
		border-top: solid 1px var(--color-border)
		&:first-child
			border-left: solid 1px var(--color-border)
		&:last-child
			border-right: solid 1px var(--color-border)
	&__td
		border: solid 1px var(--color-border)
		text-align: center
		&--link
			color: $color-link-default
			cursor: pointer
	&--th-fixed
		border: solid 1px var(--color-border)
		border-bottom: 0
		.modTable__tr:nth-child(even)
			.modTable__th
			.modTable__td
				background: $color-table-bg-even
		.modTable__th
			width: 89px
			border: 0
			&--w150
				width: 150px
		.modTable__tr:last-child
			.modTable__th
				border-bottom: solid 1px var(--color-border)
		.modTable__td
			border: 0
			border-bottom: solid 1px var(--color-border)
			border-left: solid 1px var(--color-border)
			text-align: left
			padding: 20px
			line-height: 1.6
	&--3col
		@extend .modTable--th-fixed
		.modTable__td
			padding: 10px 20px
			border-left: 0
	&--timeline
		.modTable__th
		.modTable__td
			padding: 14px 30px
		.modTable__tr:nth-child(even)
			.modTable__th
			.modTable__td
				background: $color-table-bg-even
	&__list
		&__item
			padding: 3px 0
		&--square__item
			list-style-type: square
			margin-left: 1.2em
	&--2tone
		.modTable__tr:nth-child(even)
			.modTable__th
			.modTable__td
				background: $color-table-bg-even

.modTable--borderless
	.modTable__th
	.modTable__td
			border: 0
