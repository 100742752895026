.gensenPDF
	width: 900px
	background-color: #fff
	padding: 20px

	h1
		margin: 0
		padding: 0
		font-size: 14pt !important
		font-weight: normal
		text-align: center !important
		line-height: 1 !important

	i.bl
		margin: 0
		padding: 0
		width: 1pt

	table
		width: 100% !important
		border-collapse: collapse

	th, td
		margin: 0
		padding: 0
		border-top: 0.5px solid #888888
		border-right: 0.5px solid #888888
		border-bottom: 0.5px solid #888888
		border-left: 0.5px solid #888888
		font-weight: normal

	.th
		font-size: 4.2pt

	.td
		font-size: 6.8pt

	.td-small
		font-size: 5.6pt

	.non
		background-color: #cccccc

	.td-f1
		font-size: 4.2pt

	.bt-n
		padding-top: 0; border-top: none

	.br-n
		padding-right: 0; border-right: none

	.bb-n
		padding-bottom: 0; border-bottom: none

	.bl-n
		padding-left: 0; border-left: none

	.bt-d
		border-top: 0.5px dotted #888888

	.br-d
		border-right: 0.5px dotted #888888

	.bb-d
		border-bottom: 0.5px dotted #888888

	.bl-d
		border-left: 0.5px dotted #888888

	.bt-t
		border-top: 0.2px solid #888888

	.br-t
		border-right: 0.2px solid #888888

	.bb-t
		border-bottom: 0.2px solid #888888

	.bl-t
		border-left: 0.2px solid #888888


	.b
		font-weight: bold

	.ta-l
		text-align: left

	.ta-c
		text-align: center

	.ta-r
		text-align: right

	.va-c
		vertical-align: middle

	.centering
		vertical-align: middle
		text-align: center
