
/* tree
---------------------------------------- */
.page_tree
	height: 1000px
	font-size: 12px
	a
		color: #222
		text-decoration: underline
	[draggable="true"]
		-khtml-user-drag: element
	[data-item="name"]
		display: inline-block
		margin: 5px 0
	[data-item="is_super"]
	[data-item="is_approval"]
	[data-item="user_remove"]
		display: inline-block
		margin: 5px
		border: 1px solid var(--color-border)
		padding: 2px 10px
		cursor: pointer
	[data-item="is_super"][data-is-super="true"]
	[data-item="is_approval"][data-is-approval="true"]
		border: 1px solid #aaa
		background-color: #aaa
		color: #fff
	[data-view="user-list-box"]
		display: none
	[data-view="user-setting-box"]
		display: none
	[data-view="section-section-sort-box"]
		display: none
	[data-view="section-user-sort-box"]
		display: none
	[data-item="section"]
		padding: 10px 5px
		cursor: pointer
	[data-item="section"] [data-item="name"]
		display: block
		padding: 10px 5px
		border: 1px solid var(--color-border)
	[data-item="section-sub"] [data-item="child-event"]
		background-color: yellow
		height: 50px
		opacity: 0
		display: none
	[data-item="section-sub"] [data-item="child-section"]
		margin-left: 50px
	[data-item="usercard"]
		padding: 10px 5px
		border: 1px solid var(--color-border)
		cursor: pointer
	.style-tree-root
		position: relative
	.style-control-box
		position: absolute
		top: 20px
		left: 10px
		border: 1px solid var(--color-border)
	.style-tree-box
		position: absolute
		top: 70px
		left: 10px
		border: 1px solid var(--color-border)
		width: 60%
		height: 500px
		overflow: auto
	.style-section-user-box
		position: absolute
		top: 70px
		right: 10px
		border: 1px solid var(--color-border)
		width: 30%
		height: 700px
		overflow: auto
	.style-section-list
		position: absolute
		top: 600px
		left: 10px
		border: 1px solid var(--color-border)
		width: 60%
		overflow: auto
	.style-user-setting-box
		background-color: $color-white
		position: fixed
		width: 100%
		height: 100%
	.style-user-setting-inner
		padding: 100px
	.style-user-list-box
		background-color: $color-white
		position: fixed
		width: 100%
		height: 100%
	.style-user-list-inner
		padding: 100px
	.style-section-section-sort-box
		background-color: $color-white
		position: fixed
		width: 100%
		height: 100%
	.style-section-section-sort-inner
		padding: 100px
	.style-section-user-sort-box
		background-color: $color-white
		position: fixed
		width: 100%
		height: 100%
	.style-section-user-sort-inner
		padding: 100px

	[data-event="opener"]
		font-size: 16px
		text-decoration: none
	.style-button
		margin: 5px
		border: 1px solid var(--color-border)
		padding: 2px 10px
		cursor: pointer
	.style-block-title
		margin-bottom: 10px
		font-weight: bold
	.style-section-name
		margin-top: 20px
		margin-bottom: 10px
	.style-sort-list li
		padding: 5px 0
	input[type="checkbox"]
		appearance: checkbox
		display: inline-block
		vertical-align: middle
		margin-right: 5px

@require '_tree_diagram'
@require '_tree_section_list'
@require '_tree_employee_list'
@require '_tree_modal'

.tree__wrapper
	height: 55vh
	position: relative
	min-width: 1230px
	width: 1240px
	min-height: 353px
	&--organization
		height: 80vh
		margin-bottom: -3vh

		.tree__diagram
			height: 100vh

.tree__wrapper--since-2019-04
	width: 100%;
	height: 87.5vh;
	margin-bottom: -180px;
	display: flex;
	& > *
		position: relative !important;
		flex: 1;
		box-sizing: border-box;
		height: 100% !important;
	& > *:first-child {
		padding-top: 13px;
	}

	& > *:nth-child(2)
		margin-left: 2em;

	& > * > *
		width: auto;
		box-sizing: border-box;
		min-width: auto;
		width: 100%;
		height: 100%;

	& .tree__employee-list__employees {
		height: auto;
	}

	& .tree__diagram
		border: 2px solid transparent;
	& .tree__diagram--editing
		border-color: #f08302;
