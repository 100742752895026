
/* header
---------------------------------------- */
.header
	height: 62px
	// transform: translateX(220px)
	transition: all .5s ease-in-out
	border-bottom: 1px solid var(--color-border)
	position: fixed
	left: 0
	top: 0
	width: 100%
	min-width: 950px
	background: $color-white
	z-index: 10
	&.inactive:before
		content: ''
		position: fixed
		display: block
		width: inherit
		height: inherit
		z-index: 99999999999999999
	&--login
		height: 62px
		border-bottom: 1px solid var(--color-border)
		position: fixed
		left: 0
		top: 0
		width: 100%
		background: $color-white
		z-index: 10
	&__logo
		display: block
		float: left
		margin-left: 26px
		line-height: 56px
		img
			vertical-align: middle
	&__user
		float: right
		display: table
		// transform: translateX(-220px)
		transition: all .5s ease-in-out
		position: relative
		top: -2px
		&__corp
		&__prof
		&__corp--logo
		&__corp--name
		&__prof--name
		&__prof--icon
			display: table-cell
			vertical-align: middle
		&__corp
		&__prof
			background: $color-white
			border-bottom: 1px solid var(--color-border)
		&__corp--logo
			height: 63px
			padding: 0 15px
			text-align: center
			border-left: 1px solid var(--color-border)
			border-right: 1px solid var(--color-border)
		&__corp--name
			min-width: 156px
			height: 63px
			line-height: 63px
			padding: 0 10px
			overflow: hidden
			color: var(--color-font-default)
			border-right: 1px solid var(--color-border)
			text-align: center
		&__prof
			min-width: 198px
			height: 63px
			line-height: 22px
			padding-left: 30px
			cursor: pointer
		&__prof--icon
			padding: 0 20px
			line-height: 0
			position: relative
			&:after
				content: ''
				position: absolute
				width: 0
				height: 0
				border-style: solid
				border-width: 0 3.5px 5px 3.5px
				top: 7px
				right: 10px
				transform: rotate(-180deg)
				border-color: transparent transparent $color-gray-02 transparent
		&__prof--color01
			color: $color-blue
		&__corp--logo__area img
			max-height: 40px
			max-width: 40px
		&__option
			width: 188px
			position: absolute
			top: 72px
			right: 6px
			transform: translateY(-200px)
			transition: transform .5s ease-in-out
			z-index: -1
			border-bottom: 1px solid $color-gray-01
			&:before
				content: ''
				position: absolute
				width: 0
				height: 0
				border-style: solid
				border-width: 0 6px 7px 6px
				top: -7px
				right: 44px
				border-color: transparent transparent $color-gray-01 transparent
			&__item
				display: block
				color: $color-white
				height: 44px
				line-height: 44px
				padding: 0 24px
				background: rgba(66,66,66,.8)
				&:hover
					background: rgba(102,102,102,.8)
			&--show
				transform: translateY(0)


/* nav
---------------------------------------- */
.nav
	width: 200px
	border-right: 1px solid #929292
	position: fixed
	left: 0
	top: 0
	top: 62px
	/*
	min-height: 100%
	margin-bottom: -32768px
	padding-bottom: 32768px
	*/
	height: calc(100% - 62px)
	overflow: scroll
	background: $color-main-bg-gray
	font-size: 1.3rem
	transform: translateX(0)
	transition: transform .5s ease-in-out
	z-index: 12
	padding-right: 2px
	&.inactive:before
		content: ''
		position: fixed
		display: block
		width: inherit
		height: inherit
		z-index: 99999999999999999
	&__btn
		position: absolute
		right: 0
		top: 15px
		display: block
		width: 30px
		height: 30px
		cursor: pointer
		&--open
			background: url(/images/btn_open.png) no-repeat
		&--close
			background: url(/images/btn_close.png) no-repeat
	&__heading
		background: $color-bav-bg-black
		color: var(--color-font-gray-02)
		height: 62px
		line-height: 70px
		padding: 0 18px
		&:before
			content: ''
			display: inline-block
			vertical-align: middle
			width: 16px
			height: 16px
			background: url(/images/ic_controll.png) no-repeat
			margin-right: 6px
	&__list1
		transition: transform .5s ease-in-out
		&__item
			border-bottom: 1px solid #929292;
			font-size: 1.4rem
			a
				cursor: pointer
			&>a
				display: block
				padding: 16px 0 16px 23px
				padding-left: 10px
				transition: padding-left .1s
				color: var(--color-font-default);
				position: relative
				&:before
					content: ""
					width: 0px
					-webkit-transition: width .15s
					transition: width .15s
					height: 2px
					position: absolute
					display: block
					background: $color-orange-01
					bottom: -1px
					left: 0
				&.js_nav_accordion_btn
					&:after
						border-right: 2px solid $color-gray-03
						border-top: 2px solid $color-gray-03
						content: ""
						display: block
						height: 10px
						position: absolute
						right: 15px
						top: 50%
						margin-top: -6px
						transform: rotate(135deg)
						transition: transform .1s
						width: 10px
					&.active-accordion
						color: $color-orange-01
				&.is-active
					background: $color-orange-01
					color: $color-white
					&:after
						border-right-color: $color-orange-01
						border-top-color: $color-orange-01
					&:hover
						color: $color-white
				&:hover
					color: $color-orange-01
					&:before
						width: 100%
	&__list2
		&__item
			&>a
				display: block
				background: #f4f4f4
				border-bottom: 1px solid #929292
				transition: border-width .1s, padding-left .1s
				padding: 9px 0 9px 24px;
				color: var(--color-font-default)
				font-size: 1.4rem
				position: relative
				left: -100%
				opacity: 0
				&:before
					content: ""
					width: 0px
					-webkit-transition: width .15s
					transition: width .15s
					height: 1px
					position: absolute
					display: block
					background: $color-orange-01
					bottom: -1px
					left: 0
				&.js_slide_btn
					&:after
						border-right: 2px solid $color-gray-03
						border-top: 2px solid $color-gray-03
						content: ""
						display: block
						height: 8px
						position: absolute
						right: 14px
						top: 12px
						transform: rotate(135deg)
						width: 8px
						transition: transform .1s
				&>span
					display: block
					position: relative
				&:hover
					border-bottom-color: transparent
					&:before
						width: 100%
				&:hover
					color: $color-orange-01
				&.is-active
					background: $color-orange-01
					color: $color-white
					&:before
						height: 100%
					&:after
						border-right-color: $color-white
						border-top-color: $color-white
	&__list3
		&__item
			&>a
				display: block
				background: #f4f4f4
				border-bottom: 1px solid #929292
				transition: border-width .1s, padding-left .1s
				padding: 9px 0 9px 40px;
				color: var(--color-font-default)
				font-size: 1.4rem
				position: relative
				left: -100%
				opacity: 0
				&:before
					content: ""
					width: 0px
					-webkit-transition: width .15s
					transition: width .15s
					height: 1px
					position: absolute
					display: block
					background: $color-orange-01
					bottom: -1px
					left: 0
				&.js_slide_btn
					&:after
						border-right: 2px solid var(--color-border)
						border-top: 2px solid var(--color-border)
						content: ""
						display: block
						height: 8px
						position: absolute
						right: 14px
						top: 12px
						transform: rotate(135deg)
						width: 8px
						transition: transform .1s
				&>span
					display: block
					position: relative
				&:hover
					border-bottom-color: transparent
					&:before
						width: 100%
				&:hover
					color: $color-orange-01
				&.is-active
					background: $color-orange-01
					color: $color-white
					&:before
						height: 100%
					&:after
						border-right-color: $color-white
						border-top-color: $color-white

	&__list3,
	&__list4
		&__item
			&>a
				display: block
				background: #f4f4f4
				border-bottom: 1px solid #929292
				transition: border-width .1s, padding-left .1s
				padding: 9px 0 9px 37px;
				color: var(--color-font-default)
				font-size: 1.4rem
				position: relative
				left: -100%
				opacity: 0
				&:before
					content: ""
					width: 0px
					-webkit-transition: width .15s
					transition: width .15s
					height: 1px
					position: absolute
					display: block
					background: $color-orange-01
					bottom: -1px
					left: 0
				&.js_slide_btn_sub,
				&.js_slide_btn_sub_sub,
				&.js_slide_btn_sub_sub_sub
					&:after
						border-right: 2px solid var(--color-border)
						border-top: 2px solid var(--color-border)
						content: ""
						display: block
						height: 8px
						position: absolute
						right: 14px
						top: 12px
						transform: rotate(135deg)
						width: 8px
						transition: transform .1s
				&>span
					display: block
					position: relative
				&:hover
					border-bottom-color: transparent
					&:before
						width: 100%
				&:hover
					color: $color-orange-01
				&.is-active
					background: $color-orange-01
					color: $color-white
					&:before
						height: 100%
					&:after
						border-right-color: $color-white
						border-top-color: $color-white
	&__list4
		&__item
			&>a
				padding: 9px 0 9px 49px;


/* アコーディオン */
.js_nav_accordion_target
.js_nav_accordion_target_sub
.js_nav_accordion_target_sub_sub
.js_nav_accordion_target_sub_sub_sub
	display: none
.nav_accordion--open
	border-bottom: 2px solid #f08200
	&>a
		color: var(--color-font-default)
		border-bottom: 1px solid #929292
		// background: $color-bav-bg-black
		// padding-left: 34px
		&:before
			// width: 100%
			// display: none
		&:after
			border-right-color: $color-orange-01!important
			border-top-color: $color-orange-01!important
			transform: rotate(-45deg)!important

.nav_accordion_sub--open
	border-bottom: 1px solid #f08200;
	&>a
		color: $color-orange-01
		&:after
			border-right-color: $color-orange-01!important
			border-top-color: $color-orange-01!important
			transform: rotate(-45deg)!important

/* sub
---------------------------------------- */
.sub
	width: 220px
	min-height: 100%
	margin-bottom: -32768px
	padding-bottom: 32768px
	position: fixed
	left: 0
	top: 62px
	background: #fbfbfb
	border-right: 1px solid var(--color-border)
	transition: left .5s ease-in-out
	z-index: 2
	&__menu
		&__heading
			font-size: 1.3rem
			height: 46px
			line-height: 46px
			border-bottom: 1px solid var(--color-border)
			color: var(--color-font-gray-01)
			padding-left: 20px
		&__item:last-child
			&>.sub__menu__link
				border: none
		&__link,
		&__link--2,
		&__link--3
			padding-left: 37px
			padding-top: 9px
			padding-bottom: 9px
			display: block
			font-size: 1.4rem
			color: var(--color-font-default)
			border-bottom: 1px solid #929292
			position: relative
			background: #f4f4f4
			left: -100%
			opacity: 0
			span
				display: block
				position: relative
			&:before
				content: ""
				width: 0px
				-webkit-transition: width .15s
				transition: width .15s
				height: 1px
				position: absolute
				display: block
				background: $color-orange-01
				bottom: 0px
				left: 0
			&:hover
				color: $color-orange-01
				border-bottom-color: transparent
				&:before
					width: 100%
			&.is-active
				color: $color-white
				background: $color-orange-01
		&__link--2
			padding-left: 49px
		&__link--3
			padding-left: 61px
/* スライドイン */
.sub
	&.sub--open
		left: 220px
.nav--close
	.sub
		&.sub--open
			left: 30px

/* ナビ開閉 */
.nav--close
	.nav
		transform: translateX(-190px)
	.header
	.main
		transform: translateX(30px)
		width: calc(100% - 30px)
	.header__user
		transform: translateX(-30px)
	.sub
		left: -220px
	.nav__list
		transform: translateX(-30px)

.sub--open
	.header
		// transform: translateX(220px)
	.main
		transform: translateX(440px)
		width: calc(100% - 440px)
	.header__user
		// transform: translateX(-220px)

.sub--open.nav--close
	.nav
		transform: translateX(-190px)
	.header
		transform: translateX(30px)
	.main
		transform: translateX(230px)
		width: calc(100% - 230px)
	.header__user
		transform: translateX(-30px)
	.nav__list
		transform: translateX(-30px)


/* main
---------------------------------------- */
.main
	margin-top: 62px
	padding: 24px 24px 50px
	// transform: translateX(200px)
	transition: all .5s ease-in-out
	position: relative
	margin-left: 200px
	width: calc(100% - 200px)
	&--white
		background-color: $color-white
	&__base
		background-color: $color-white
		box-shadow: 0 0 1px rgba(0,0,0,.2)
		padding: 30px
		&--w880
			width: 880px
	&__inner
		border-left: 1px solid var(--color-border)
		border-right: 1px solid var(--color-border)
		padding: 25px 26px 0
	&__heading02
		margin-bottom: 20px
		color: var(--color-font-default)
		font-size: 16px
		font-weight: bold
	&__detail
		p
			font-size: 13px
			line-height: 1.8
			margin-top: 18px
.mainTable
	color: var(--color-font-gray-01)
	font-size: 14px
	&__cell
		padding: 10px 0
		vertical-align: top
		text-align: left
	&__th
		width: 200px
		vertical-align: middle
	&--center
		margin: auto

.main__contents
	background: $color-white
	margin-top: 10px
	border: 1px solid var(--color-border)
	border-left: 0
.main__title
	padding-top: 28px
	border-left: 1px solid var(--color-border)
.main__title__label
	margin-left: 26px
	border-left: 4px solid $color-gray-01
	font-size: 14px
	font-weight: bold
	padding: 1px 8px
.main__menu
	background: $color-white
	box-shadow: 0 1px 0 var(--color-border)
	display: flex
	flex-wrap: nowrap
	justify-content: space-between
	height: 47px
	position: relative
	&--nobtn
		padding: 8px 0
		justify-content: flex-end
	&__item
		display: inline-block
		border-right: 1px solid var(--color-border)
		white-space: nowrap;
	a
		color: var(--color-font-default)
		&:hover
			color: $color-orange-01
	&__btn
		display: inline-block
		width: 164px
		height: 46px
		line-height: 46px
		font-size: 1.3rem
		text-align: center
		color: var(--color-font-default)
		cursor: pointer
		position: relative
		&.active
		&:hover
			color: $color-orange-01
			&:before
				background: url(/images/ic_add_a.png) no-repeat
			/*
			&:nth-of-type(1):before
				background: url(/images/ic_add_a.png) no-repeat
			&:nth-of-type(2):before
				background: url(/images/ic_del_a.png) no-repeat
			&:nth-of-type(3):before
				background: url(/images/ic_month_a.png) no-repeat
			&:nth-of-type(4):before
				background: url(/images/ic_hidden_a.png) no-repeat
			*/
		&:before
			content: ''
			width: 16px
			height: 16px
			display: inline-block
			vertical-align: middle
			margin-right: 6px
			margin-bottom: 2px
		&:before
			background: url(/images/ic_add.png) no-repeat
		/*
		&:nth-of-type(1):before
			background: url(/images/ic_add.png) no-repeat
		&:nth-of-type(2):before
			background: url(/images/ic_del.png) no-repeat
		&:nth-of-type(3):before
			background: url(/images/ic_month.png) no-repeat
		&:nth-of-type(4):before
			width: 21px
			height: 17px
			background: url(/images/ic_hidden.png) no-repeat
		*/
	&__search
		display: flex
		justify-content: center
		align-items: center
		padding-right: 9px
		&__item
			width: 299px
			height: 30px
			line-height: 30px
			border: 1px solid var(--color-border)
			border-radius: 1px
			padding-left: 40px
			background: $color-white url(/images/ic_search.png) no-repeat left 10px center
			background-size: 16px 16px
			&:focus
				outline: none
		&--right
			width: 100%
			justify-content: flex-end
	&__select
		height: 45px
		line-height: 45px
		padding: 0 10px 0 15px
	&--disabled
		opacity: .5
		pointer-events: none
	&__import
		&:before
			content: ''
			width: 16px
			height: 16px
			display: inline-block
			vertical-align: middle
			margin-right: 4px
			margin-bottom: 2px
			background: url(/images/ic_import.png) no-repeat

.main__menu
	&--delete:before
		background: url(/images/ic_del.png) no-repeat
	&--month:before
		background: url(/images/ic_month.png) no-repeat
	&--export:before
		background: url(/images/ic_export.png) no-repeat
	&--filter:before
		background: url(/images/ic_filter.png) no-repeat
	&--reload:before
		background: url(/images/ic_reload.png) no-repeat
	&--edit:before
		background: url(/images/ic_pencil.png) no-repeat
	&--window:before
		background: url(/images/ic_window.png) no-repeat
	&--toggle:before
		background: url(/images/ic_toggle.png) no-repeat
	&--import:before
		background: url(/images/ic_import.png) no-repeat
	&--return:before
		background: url(/images/ic_return.png) no-repeat
	&--link:before
		background: url(/images/ic_link.png) no-repeat
	&--check:before
		background: url(/images/ic_check_new.png) no-repeat
	&--list:before
		background: url(/images/ic_list_b.png) no-repeat
	&--none:before
		content: none;

	&--hidden:before
		background: url(/images/ic_hidden.png) no-repeat
		width: 21px
		height: 17px
	&--download
		padding-top: 12px
	&--download:before
		background: url(/images/ic_download2.png) no-repeat
		width: 27px
		height: 16px
		position: absolute
		top: 8px
		left: 50%
		margin: 0
		margin-left: -13px
	&--export:before
		background: url(/images/ic_export.png) no-repeat
	&--delete:hover:before
		background: url(/images/ic_del_a.png) no-repeat
	&--month:hover:before
		background: url(/images/ic_month_a.png) no-repeat
	&--hidden:hover:before
		background: url(/images/ic_hidden_a.png) no-repeat
	&--hidden.active:before
		background: url(/images/ic_hidden_a.png) no-repeat
	&--hidden.active:hover:before
		background: url(/images/ic_hidden_a.png) no-repeat
	&--download:hover:before
		background: url(/images/ic_download3.png) no-repeat
	&--download:active:before
		background: url(/images/ic_download3.png) no-repeat
	&--import:hover:before
		background: url(/images/ic_import_o.png) no-repeat
	&--export:hover:before
		background: url(/images/ic_export_o.png) no-repeat
	&--filter:hover:before
		background: url(/images/ic_filter_o.png) no-repeat
	&--reload:hover:before
		background: url(/images/ic_reload_o.png) no-repeat
	&--edit:hover:before
		background: url(/images/ic_pencil_o.png) no-repeat
	&--window:hover:before
		background: url(/images/ic_window.png) no-repeat
	&--toggle:hover:before
		background: url(/images/ic_toggle_o.png) no-repeat
	&--return:hover:before
		background: url(/images/ic_return_o.png) no-repeat
	&--link:hover:before
		background: url(/images/ic_link_o.png) no-repeat
	&--check:hover:before
		background: url(/images/ic_check_new_o.png) no-repeat
	&--list:hover:before
		background: url(/images/ic_list_o.png) no-repeat
	&--noflex
		background: $color-white
		box-shadow: 0 1px 0 var(--color-border)
		display: flex
		flex-wrap: nowrap
		> *
			margin-right: 20px
	&--alignLeft
		position: relative
		justify-content: flex-start
		.main__menu__calendar
			margin-left: 20px
		.main__menu__switch
			position: absolute
			right: 20px
			top: 0
	&--alignRight
		border-left: 1px solid var(--color-border)
	&--unlock:before
		background: url(/images/ic_unlock.png) no-repeat
	&--unlock:hover:before
		background: url(/images/ic_unlock_a.png) no-repeat

.page
	border-left: 1px solid var(--color-border)
	&--upper
		padding: 20px 0
	&--lower
		padding: 43px 0 53px
	&__content
		border-bottom: 1px solid var(--color-border)
		border-top: 1px solid var(--color-border)
		margin: 0 26px
		padding: 10px 13px 10px 280px
		position: relative
		// display: -webkit-box
		&--left
			left: 0
			position: absolute
			top: 10px
		&--center
			text-align: center
	&--nomargin
		border-left: 0
		.page__content
			margin: 0
	&--pb0
		padding-bottom: 0


/* footer
---------------------------------------- */
.footer
	text-align: center
	padding: 20px 0
	&__copy
		display: inline-block
		font-size: 1rem
		color: var(--color-font-gray-02)
