@import url("//fonts.googleapis.com/earlyaccess/notosansjapanese.css");
@-ms-viewport {
  width: auto !important;
}
.noto {
  font-family: 'Noto Sans Japanese', serif;
}
:root {
  --color-font-default: #363636;
  --color-font-default-rgb: 54, 54, 54;
  --color-font-gray-01: rgba(54,54,54,0.76);
  --color-font-gray-02: rgba(54,54,54,0.59);
  --color-border: #bdbdbd;
  --color-border-rgb: 189, 189, 189;
  --color-border-30: rgba(189,189,189,0.3);
  --color-orange-01: #f08200;
}
html {
  overflow-y: scroll;
  background: #f4f4f4;
  color: var(--color-font-default);
  font-size: 62.5%;
}
body {
  font-family: 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  font-size: 1.4rem;
  height: 100%;
  min-width: 1050px;
}
.responsive {
  min-width: 0;
}
a {
  color: #49b0be;
  text-decoration: none;
  word-break: break-all;
}
.js--dev,
.hidden {
  display: none;
}
.error {
  color: #d80c0c;
  font-size: 13px;
  margin-top: 4px;
  display: inilne-block;
}
.p30 {
  padding: 30px;
  border: 1px solid var(--color-border);
  border-top: none;
}
.p30--noborder {
  padding: 30px;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
.clearfix {
  clear: both;
  content: " ";
  display: block;
}
.spinner {
  margin: 100px auto;
  width: 50px;
  height: 60px;
  text-align: center;
  font-size: 10px;
}
.spinner > div {
  background-color: #f08200;
  height: 100%;
  width: 6px;
  display: inline-block;
  -webkit-animation: stretchdelay 1.2s infinite ease-in-out;
  animation: stretchdelay 1.2s infinite ease-in-out;
}
.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
@-webkit-keyframes stretchdelay {
  0%, 40%, 100% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}
.br1 {
  border-right: 1px solid #999;
}
.openedTray .linkedTray {
  bottom: 140px !important;
}
.actionTray {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -100px;
  height: 100px;
  background: #fff;
  transition: bottom linear 0.1s;
  z-index: 10;
}
.openedTray .actionTray {
  bottom: 0;
}
.actionTrayToggle {
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  top: -30px;
  height: 30px;
  color: #fff;
  background: #fe9902;
  text-align: center;
  font-size: 16px;
  padding: 7px;
  cursor: pointer;
  width: 120px;
  display: block;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.actionTrayToggle::after {
  content: "\2191";
}
.openedTray .actionTrayToggle::after {
  content: "\2193";
}
.actionTrayMenu {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  margin-top: 30px;
}
.actionTrayMenuItem {
  width: 200px;
  height: 40px;
  border-radius: 8px;
  background: #fd9;
  cursor: pointer;
  text-decoration: none;
  color: var(--color-font-default);
  display: block;
  font-size: 12px;
  padding: 0px 12px;
  text-align: center;
  line-height: 40px;
}
.actionTrayMenuItem:hover {
  background: #fc8;
}
.actionTrayMenuItem.disabled {
  background: #ccc;
  cursor: not-allowed;
  color: #fff;
}
.actionTrayMenuItem.disabled:hover {
  background: #ccc;
}
.isModalOn {
  display: block !important;
}
.formItemsArea {
  display: block;
  width: 100%;
  height: calc(100% - 120px);
  overflow-y: scroll;
}
.modalActionButtonArea {
  margin-top: 40px;
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}
.modalActionButtonArea button {
  width: 200px;
  height: 40px;
  border-radius: 8px;
  background: #fd9;
  cursor: pointer;
  text-decoration: none;
  color: var(--color-font-default);
  display: block;
  font-size: 12px;
  padding: 0px 12px;
  text-align: center;
  line-height: 40px;
}
.modalActionButtonArea button.typeCancel {
  background: #eee;
}
.dataSelectorTabs-is-active {
  background: #fff !important;
  box-shadow: -2px 0 0 rgba(0,0,0,0.5) inset !important;
  cursor: auto !important;
}
.skill_high {
  background: #9f9;
}
.skill_mid {
  background: #cf9;
}
.skill_low {
  background: #ff9;
}
.formItemsArea div {
  display: flex;
  margin-bottom: 10px;
}
.formItemsArea div label {
  width: 200px;
  text-align: right;
  margin-right: 10px;
  line-height: 36px;
}
/* ----------------------------------------
	layout
---------------------------------------- */
.header {
  height: 62px;
  transition: all 0.5s ease-in-out;
  border-bottom: 1px solid var(--color-border);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  min-width: 950px;
  background: #fff;
  z-index: 10;
}
.header.inactive:before {
  content: '';
  position: fixed;
  display: block;
  width: inherit;
  height: inherit;
  z-index: 100000000000000000;
}
.header--login {
  height: 62px;
  border-bottom: 1px solid var(--color-border);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background: #fff;
  z-index: 10;
}
.header__logo {
  display: block;
  float: left;
  margin-left: 26px;
  line-height: 56px;
}
.header__logo img {
  vertical-align: middle;
}
.header__user {
  float: right;
  display: table;
  transition: all 0.5s ease-in-out;
  position: relative;
  top: -2px;
}
.header__user__corp,
.header__user__prof,
.header__user__corp--logo,
.header__user__corp--name,
.header__user__prof--name,
.header__user__prof--icon {
  display: table-cell;
  vertical-align: middle;
}
.header__user__corp,
.header__user__prof {
  background: #fff;
  border-bottom: 1px solid var(--color-border);
}
.header__user__corp--logo {
  height: 63px;
  padding: 0 15px;
  text-align: center;
  border-left: 1px solid var(--color-border);
  border-right: 1px solid var(--color-border);
}
.header__user__corp--name {
  min-width: 156px;
  height: 63px;
  line-height: 63px;
  padding: 0 10px;
  overflow: hidden;
  color: var(--color-font-default);
  border-right: 1px solid var(--color-border);
  text-align: center;
}
.header__user__prof {
  min-width: 198px;
  height: 63px;
  line-height: 22px;
  padding-left: 30px;
  cursor: pointer;
}
.header__user__prof--icon {
  padding: 0 20px;
  line-height: 0;
  position: relative;
}
.header__user__prof--icon:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 3.5px 5px 3.5px;
  top: 7px;
  right: 10px;
  transform: rotate(-180deg);
  border-color: transparent transparent #999 transparent;
}
.header__user__prof--color01 {
  color: #49b0be;
}
.header__user__corp--logo__area img {
  max-height: 40px;
  max-width: 40px;
}
.header__user__option {
  width: 188px;
  position: absolute;
  top: 72px;
  right: 6px;
  transform: translateY(-200px);
  transition: transform 0.5s ease-in-out;
  z-index: -1;
  border-bottom: 1px solid #666;
}
.header__user__option:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 6px 7px 6px;
  top: -7px;
  right: 44px;
  border-color: transparent transparent #666 transparent;
}
.header__user__option__item {
  display: block;
  color: #fff;
  height: 44px;
  line-height: 44px;
  padding: 0 24px;
  background: rgba(66,66,66,0.8);
}
.header__user__option__item:hover {
  background: rgba(102,102,102,0.8);
}
.header__user__option--show {
  transform: translateY(0);
}
.nav {
  width: 200px;
  border-right: 1px solid #929292;
  position: fixed;
  left: 0;
  top: 0;
  top: 62px;
/*
	min-height: 100%
	margin-bottom: -32768px
	padding-bottom: 32768px
	*/
  height: calc(100% - 62px);
  overflow: scroll;
  background: #f4f4f4;
  font-size: 1.3rem;
  transform: translateX(0);
  transition: transform 0.5s ease-in-out;
  z-index: 12;
  padding-right: 2px;
}
.nav.inactive:before {
  content: '';
  position: fixed;
  display: block;
  width: inherit;
  height: inherit;
  z-index: 100000000000000000;
}
.nav__btn {
  position: absolute;
  right: 0;
  top: 15px;
  display: block;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.nav__btn--open {
  background: url("/images/btn_open.png") no-repeat;
}
.nav__btn--close {
  background: url("/images/btn_close.png") no-repeat;
}
.nav__heading {
  background: #2c2b2b;
  color: var(--color-font-gray-02);
  height: 62px;
  line-height: 70px;
  padding: 0 18px;
}
.nav__heading:before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  background: url("/images/ic_controll.png") no-repeat;
  margin-right: 6px;
}
.nav__list1 {
  transition: transform 0.5s ease-in-out;
}
.nav__list1__item {
  border-bottom: 1px solid #929292;
  font-size: 1.4rem;
}
.nav__list1__item a {
  cursor: pointer;
}
.nav__list1__item>a {
  display: block;
  padding: 16px 0 16px 23px;
  padding-left: 10px;
  transition: padding-left 0.1s;
  color: var(--color-font-default);
  position: relative;
}
.nav__list1__item>a:before {
  content: "";
  width: 0px;
  -webkit-transition: width 0.15s;
  transition: width 0.15s;
  height: 2px;
  position: absolute;
  display: block;
  background: #f08200;
  bottom: -1px;
  left: 0;
}
.nav__list1__item>a.js_nav_accordion_btn:after {
  border-right: 2px solid #dfdfdf;
  border-top: 2px solid #dfdfdf;
  content: "";
  display: block;
  height: 10px;
  position: absolute;
  right: 15px;
  top: 50%;
  margin-top: -6px;
  transform: rotate(135deg);
  transition: transform 0.1s;
  width: 10px;
}
.nav__list1__item>a.js_nav_accordion_btn.active-accordion {
  color: #f08200;
}
.nav__list1__item>a.is-active {
  background: #f08200;
  color: #fff;
}
.nav__list1__item>a.is-active:after {
  border-right-color: #f08200;
  border-top-color: #f08200;
}
.nav__list1__item>a.is-active:hover {
  color: #fff;
}
.nav__list1__item>a:hover {
  color: #f08200;
}
.nav__list1__item>a:hover:before {
  width: 100%;
}
.nav__list2__item>a {
  display: block;
  background: #f4f4f4;
  border-bottom: 1px solid #929292;
  transition: border-width 0.1s, padding-left 0.1s;
  padding: 9px 0 9px 24px;
  color: var(--color-font-default);
  font-size: 1.4rem;
  position: relative;
  left: -100%;
  opacity: 0;
}
.nav__list2__item>a:before {
  content: "";
  width: 0px;
  -webkit-transition: width 0.15s;
  transition: width 0.15s;
  height: 1px;
  position: absolute;
  display: block;
  background: #f08200;
  bottom: -1px;
  left: 0;
}
.nav__list2__item>a.js_slide_btn:after {
  border-right: 2px solid #dfdfdf;
  border-top: 2px solid #dfdfdf;
  content: "";
  display: block;
  height: 8px;
  position: absolute;
  right: 14px;
  top: 12px;
  transform: rotate(135deg);
  width: 8px;
  transition: transform 0.1s;
}
.nav__list2__item>a>span {
  display: block;
  position: relative;
}
.nav__list2__item>a:hover {
  border-bottom-color: transparent;
}
.nav__list2__item>a:hover:before {
  width: 100%;
}
.nav__list2__item>a:hover {
  color: #f08200;
}
.nav__list2__item>a.is-active {
  background: #f08200;
  color: #fff;
}
.nav__list2__item>a.is-active:before {
  height: 100%;
}
.nav__list2__item>a.is-active:after {
  border-right-color: #fff;
  border-top-color: #fff;
}
.nav__list3__item>a {
  display: block;
  background: #f4f4f4;
  border-bottom: 1px solid #929292;
  transition: border-width 0.1s, padding-left 0.1s;
  padding: 9px 0 9px 40px;
  color: var(--color-font-default);
  font-size: 1.4rem;
  position: relative;
  left: -100%;
  opacity: 0;
}
.nav__list3__item>a:before {
  content: "";
  width: 0px;
  -webkit-transition: width 0.15s;
  transition: width 0.15s;
  height: 1px;
  position: absolute;
  display: block;
  background: #f08200;
  bottom: -1px;
  left: 0;
}
.nav__list3__item>a.js_slide_btn:after {
  border-right: 2px solid var(--color-border);
  border-top: 2px solid var(--color-border);
  content: "";
  display: block;
  height: 8px;
  position: absolute;
  right: 14px;
  top: 12px;
  transform: rotate(135deg);
  width: 8px;
  transition: transform 0.1s;
}
.nav__list3__item>a>span {
  display: block;
  position: relative;
}
.nav__list3__item>a:hover {
  border-bottom-color: transparent;
}
.nav__list3__item>a:hover:before {
  width: 100%;
}
.nav__list3__item>a:hover {
  color: #f08200;
}
.nav__list3__item>a.is-active {
  background: #f08200;
  color: #fff;
}
.nav__list3__item>a.is-active:before {
  height: 100%;
}
.nav__list3__item>a.is-active:after {
  border-right-color: #fff;
  border-top-color: #fff;
}
.nav__list3__item>a,
.nav__list4__item>a {
  display: block;
  background: #f4f4f4;
  border-bottom: 1px solid #929292;
  transition: border-width 0.1s, padding-left 0.1s;
  padding: 9px 0 9px 37px;
  color: var(--color-font-default);
  font-size: 1.4rem;
  position: relative;
  left: -100%;
  opacity: 0;
}
.nav__list3__item>a:before,
.nav__list4__item>a:before {
  content: "";
  width: 0px;
  -webkit-transition: width 0.15s;
  transition: width 0.15s;
  height: 1px;
  position: absolute;
  display: block;
  background: #f08200;
  bottom: -1px;
  left: 0;
}
.nav__list3__item>a.js_slide_btn_sub:after,
.nav__list4__item>a.js_slide_btn_sub:after,
.nav__list3__item>a.js_slide_btn_sub_sub:after,
.nav__list4__item>a.js_slide_btn_sub_sub:after,
.nav__list3__item>a.js_slide_btn_sub_sub_sub:after,
.nav__list4__item>a.js_slide_btn_sub_sub_sub:after {
  border-right: 2px solid var(--color-border);
  border-top: 2px solid var(--color-border);
  content: "";
  display: block;
  height: 8px;
  position: absolute;
  right: 14px;
  top: 12px;
  transform: rotate(135deg);
  width: 8px;
  transition: transform 0.1s;
}
.nav__list3__item>a>span,
.nav__list4__item>a>span {
  display: block;
  position: relative;
}
.nav__list3__item>a:hover,
.nav__list4__item>a:hover {
  border-bottom-color: transparent;
}
.nav__list3__item>a:hover:before,
.nav__list4__item>a:hover:before {
  width: 100%;
}
.nav__list3__item>a:hover,
.nav__list4__item>a:hover {
  color: #f08200;
}
.nav__list3__item>a.is-active,
.nav__list4__item>a.is-active {
  background: #f08200;
  color: #fff;
}
.nav__list3__item>a.is-active:before,
.nav__list4__item>a.is-active:before {
  height: 100%;
}
.nav__list3__item>a.is-active:after,
.nav__list4__item>a.is-active:after {
  border-right-color: #fff;
  border-top-color: #fff;
}
.nav__list4__item>a {
  padding: 9px 0 9px 49px;
}
.js_nav_accordion_target,
.js_nav_accordion_target_sub,
.js_nav_accordion_target_sub_sub,
.js_nav_accordion_target_sub_sub_sub {
  display: none;
}
.nav_accordion--open {
  border-bottom: 2px solid #f08200;
}
.nav_accordion--open>a {
  color: var(--color-font-default);
  border-bottom: 1px solid #929292;
}
.nav_accordion--open>a:after {
  border-right-color: #f08200 !important;
  border-top-color: #f08200 !important;
  transform: rotate(-45deg) !important;
}
.nav_accordion_sub--open {
  border-bottom: 1px solid #f08200;
}
.nav_accordion_sub--open>a {
  color: #f08200;
}
.nav_accordion_sub--open>a:after {
  border-right-color: #f08200 !important;
  border-top-color: #f08200 !important;
  transform: rotate(-45deg) !important;
}
.sub {
  width: 220px;
  min-height: 100%;
  margin-bottom: -32768px;
  padding-bottom: 32768px;
  position: fixed;
  left: 0;
  top: 62px;
  background: #fbfbfb;
  border-right: 1px solid var(--color-border);
  transition: left 0.5s ease-in-out;
  z-index: 2;
}
.sub__menu__heading {
  font-size: 1.3rem;
  height: 46px;
  line-height: 46px;
  border-bottom: 1px solid var(--color-border);
  color: var(--color-font-gray-01);
  padding-left: 20px;
}
.sub__menu__item:last-child>.sub__menu__link {
  border: none;
}
.sub__menu__link,
.sub__menu__link--2,
.sub__menu__link--3 {
  padding-left: 37px;
  padding-top: 9px;
  padding-bottom: 9px;
  display: block;
  font-size: 1.4rem;
  color: var(--color-font-default);
  border-bottom: 1px solid #929292;
  position: relative;
  background: #f4f4f4;
  left: -100%;
  opacity: 0;
}
.sub__menu__link span,
.sub__menu__link--2 span,
.sub__menu__link--3 span {
  display: block;
  position: relative;
}
.sub__menu__link:before,
.sub__menu__link--2:before,
.sub__menu__link--3:before {
  content: "";
  width: 0px;
  -webkit-transition: width 0.15s;
  transition: width 0.15s;
  height: 1px;
  position: absolute;
  display: block;
  background: #f08200;
  bottom: 0px;
  left: 0;
}
.sub__menu__link:hover,
.sub__menu__link--2:hover,
.sub__menu__link--3:hover {
  color: #f08200;
  border-bottom-color: transparent;
}
.sub__menu__link:hover:before,
.sub__menu__link--2:hover:before,
.sub__menu__link--3:hover:before {
  width: 100%;
}
.sub__menu__link.is-active,
.sub__menu__link--2.is-active,
.sub__menu__link--3.is-active {
  color: #fff;
  background: #f08200;
}
.sub__menu__link--2 {
  padding-left: 49px;
}
.sub__menu__link--3 {
  padding-left: 61px;
}
.sub.sub--open {
  left: 220px;
}
.nav--close .sub.sub--open {
  left: 30px;
}
.nav--close .nav {
  transform: translateX(-190px);
}
.nav--close .header,
.nav--close .main {
  transform: translateX(30px);
  width: calc(100% - 30px);
}
.nav--close .header__user {
  transform: translateX(-30px);
}
.nav--close .sub {
  left: -220px;
}
.nav--close .nav__list {
  transform: translateX(-30px);
}
.sub--open .main {
  transform: translateX(440px);
  width: calc(100% - 440px);
}
.sub--open.nav--close .nav {
  transform: translateX(-190px);
}
.sub--open.nav--close .header {
  transform: translateX(30px);
}
.sub--open.nav--close .main {
  transform: translateX(230px);
  width: calc(100% - 230px);
}
.sub--open.nav--close .header__user {
  transform: translateX(-30px);
}
.sub--open.nav--close .nav__list {
  transform: translateX(-30px);
}
.main {
  margin-top: 62px;
  padding: 24px 24px 50px;
  transition: all 0.5s ease-in-out;
  position: relative;
  margin-left: 200px;
  width: calc(100% - 200px);
}
.main--white {
  background-color: #fff;
}
.main__base {
  background-color: #fff;
  box-shadow: 0 0 1px rgba(0,0,0,0.2);
  padding: 30px;
}
.main__base--w880 {
  width: 880px;
}
.main__inner {
  border-left: 1px solid var(--color-border);
  border-right: 1px solid var(--color-border);
  padding: 25px 26px 0;
}
.main__heading02 {
  margin-bottom: 20px;
  color: var(--color-font-default);
  font-size: 16px;
  font-weight: bold;
}
.main__detail p {
  font-size: 13px;
  line-height: 1.8;
  margin-top: 18px;
}
.mainTable {
  color: var(--color-font-gray-01);
  font-size: 14px;
}
.mainTable__cell {
  padding: 10px 0;
  vertical-align: top;
  text-align: left;
}
.mainTable__th {
  width: 200px;
  vertical-align: middle;
}
.mainTable--center {
  margin: auto;
}
.main__contents {
  background: #fff;
  margin-top: 10px;
  border: 1px solid var(--color-border);
  border-left: 0;
}
.main__title {
  padding-top: 28px;
  border-left: 1px solid var(--color-border);
}
.main__title__label {
  margin-left: 26px;
  border-left: 4px solid #666;
  font-size: 14px;
  font-weight: bold;
  padding: 1px 8px;
}
.main__menu {
  background: #fff;
  box-shadow: 0 1px 0 var(--color-border);
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  height: 47px;
  position: relative;
}
.main__menu--nobtn {
  padding: 8px 0;
  justify-content: flex-end;
}
.main__menu__item {
  display: inline-block;
  border-right: 1px solid var(--color-border);
  white-space: nowrap;
}
.main__menu a {
  color: var(--color-font-default);
}
.main__menu a:hover {
  color: #f08200;
}
.main__menu__btn {
  display: inline-block;
  width: 164px;
  height: 46px;
  line-height: 46px;
  font-size: 1.3rem;
  text-align: center;
  color: var(--color-font-default);
  cursor: pointer;
  position: relative;
/*
		&:nth-of-type(1):before
			background: url(/images/ic_add.png) no-repeat
		&:nth-of-type(2):before
			background: url(/images/ic_del.png) no-repeat
		&:nth-of-type(3):before
			background: url(/images/ic_month.png) no-repeat
		&:nth-of-type(4):before
			width: 21px
			height: 17px
			background: url(/images/ic_hidden.png) no-repeat
		*/
}
.main__menu__btn.active,
.main__menu__btn:hover {
  color: #f08200;
/*
			&:nth-of-type(1):before
				background: url(/images/ic_add_a.png) no-repeat
			&:nth-of-type(2):before
				background: url(/images/ic_del_a.png) no-repeat
			&:nth-of-type(3):before
				background: url(/images/ic_month_a.png) no-repeat
			&:nth-of-type(4):before
				background: url(/images/ic_hidden_a.png) no-repeat
			*/
}
.main__menu__btn.active:before,
.main__menu__btn:hover:before {
  background: url("/images/ic_add_a.png") no-repeat;
}
.main__menu__btn:before {
  content: '';
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 6px;
  margin-bottom: 2px;
}
.main__menu__btn:before {
  background: url("/images/ic_add.png") no-repeat;
}
.main__menu__search {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 9px;
}
.main__menu__search__item {
  width: 299px;
  height: 30px;
  line-height: 30px;
  border: 1px solid var(--color-border);
  border-radius: 1px;
  padding-left: 40px;
  background: #fff url("/images/ic_search.png") no-repeat left 10px center;
  background-size: 16px 16px;
}
.main__menu__search__item:focus {
  outline: none;
}
.main__menu__search--right {
  width: 100%;
  justify-content: flex-end;
}
.main__menu__select {
  height: 45px;
  line-height: 45px;
  padding: 0 10px 0 15px;
}
.main__menu--disabled {
  opacity: 0.5;
  pointer-events: none;
}
.main__menu__import:before {
  content: '';
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 4px;
  margin-bottom: 2px;
  background: url("/images/ic_import.png") no-repeat;
}
.main__menu--delete:before {
  background: url("/images/ic_del.png") no-repeat;
}
.main__menu--month:before {
  background: url("/images/ic_month.png") no-repeat;
}
.main__menu--export:before {
  background: url("/images/ic_export.png") no-repeat;
}
.main__menu--filter:before {
  background: url("/images/ic_filter.png") no-repeat;
}
.main__menu--reload:before {
  background: url("/images/ic_reload.png") no-repeat;
}
.main__menu--edit:before {
  background: url("/images/ic_pencil.png") no-repeat;
}
.main__menu--window:before {
  background: url("/images/ic_window.png") no-repeat;
}
.main__menu--toggle:before {
  background: url("/images/ic_toggle.png") no-repeat;
}
.main__menu--import:before {
  background: url("/images/ic_import.png") no-repeat;
}
.main__menu--return:before {
  background: url("/images/ic_return.png") no-repeat;
}
.main__menu--link:before {
  background: url("/images/ic_link.png") no-repeat;
}
.main__menu--check:before {
  background: url("/images/ic_check_new.png") no-repeat;
}
.main__menu--list:before {
  background: url("/images/ic_list_b.png") no-repeat;
}
.main__menu--none:before {
  content: none;
}
.main__menu--hidden:before {
  background: url("/images/ic_hidden.png") no-repeat;
  width: 21px;
  height: 17px;
}
.main__menu--download {
  padding-top: 12px;
}
.main__menu--download:before {
  background: url("/images/ic_download2.png") no-repeat;
  width: 27px;
  height: 16px;
  position: absolute;
  top: 8px;
  left: 50%;
  margin: 0;
  margin-left: -13px;
}
.main__menu--export:before {
  background: url("/images/ic_export.png") no-repeat;
}
.main__menu--delete:hover:before {
  background: url("/images/ic_del_a.png") no-repeat;
}
.main__menu--month:hover:before {
  background: url("/images/ic_month_a.png") no-repeat;
}
.main__menu--hidden:hover:before {
  background: url("/images/ic_hidden_a.png") no-repeat;
}
.main__menu--hidden.active:before {
  background: url("/images/ic_hidden_a.png") no-repeat;
}
.main__menu--hidden.active:hover:before {
  background: url("/images/ic_hidden_a.png") no-repeat;
}
.main__menu--download:hover:before {
  background: url("/images/ic_download3.png") no-repeat;
}
.main__menu--download:active:before {
  background: url("/images/ic_download3.png") no-repeat;
}
.main__menu--import:hover:before {
  background: url("/images/ic_import_o.png") no-repeat;
}
.main__menu--export:hover:before {
  background: url("/images/ic_export_o.png") no-repeat;
}
.main__menu--filter:hover:before {
  background: url("/images/ic_filter_o.png") no-repeat;
}
.main__menu--reload:hover:before {
  background: url("/images/ic_reload_o.png") no-repeat;
}
.main__menu--edit:hover:before {
  background: url("/images/ic_pencil_o.png") no-repeat;
}
.main__menu--window:hover:before {
  background: url("/images/ic_window.png") no-repeat;
}
.main__menu--toggle:hover:before {
  background: url("/images/ic_toggle_o.png") no-repeat;
}
.main__menu--return:hover:before {
  background: url("/images/ic_return_o.png") no-repeat;
}
.main__menu--link:hover:before {
  background: url("/images/ic_link_o.png") no-repeat;
}
.main__menu--check:hover:before {
  background: url("/images/ic_check_new_o.png") no-repeat;
}
.main__menu--list:hover:before {
  background: url("/images/ic_list_o.png") no-repeat;
}
.main__menu--noflex {
  background: #fff;
  box-shadow: 0 1px 0 var(--color-border);
  display: flex;
  flex-wrap: nowrap;
}
.main__menu--noflex > * {
  margin-right: 20px;
}
.main__menu--alignLeft {
  position: relative;
  justify-content: flex-start;
}
.main__menu--alignLeft .main__menu__calendar {
  margin-left: 20px;
}
.main__menu--alignLeft .main__menu__switch {
  position: absolute;
  right: 20px;
  top: 0;
}
.main__menu--alignRight {
  border-left: 1px solid var(--color-border);
}
.main__menu--unlock:before {
  background: url("/images/ic_unlock.png") no-repeat;
}
.main__menu--unlock:hover:before {
  background: url("/images/ic_unlock_a.png") no-repeat;
}
.page {
  border-left: 1px solid var(--color-border);
}
.page--upper {
  padding: 20px 0;
}
.page--lower {
  padding: 43px 0 53px;
}
.page__content {
  border-bottom: 1px solid var(--color-border);
  border-top: 1px solid var(--color-border);
  margin: 0 26px;
  padding: 10px 13px 10px 280px;
  position: relative;
}
.page__content--left {
  left: 0;
  position: absolute;
  top: 10px;
}
.page__content--center {
  text-align: center;
}
.page--nomargin {
  border-left: 0;
}
.page--nomargin .page__content {
  margin: 0;
}
.page--pb0 {
  padding-bottom: 0;
}
.footer {
  text-align: center;
  padding: 20px 0;
}
.footer__copy {
  display: inline-block;
  font-size: 1rem;
  color: var(--color-font-gray-02);
}
/* ----------------------------------------
	modules
---------------------------------------- */
.login__parent {
  display: flex;
  width: 100%;
}
.login__left {
  width: 50%;
}
.login__right {
  text-align: center;
  flex-grow: 1;
  width: 50%;
  margin: 180px auto 30px;
}
.logo-images {
  z-index: -2;
  position: relative;
  cursor: pointer;
}
.logo-link-container:hover {
  box-shadow: inset 0 0 0 3px #f18103;
}
.partners {
  color: var(--color-font-default);
  font-size: 24px;
  font-weight: bold;
  margin-right: 50px;
  margin-bottom: 20px;
}
.logo__title {
  color: var(--color-font-default);
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}
.logo__sub__title {
  color: var(--color-font-default);
  font-size: 10px;
  font-weight: bold;
}
.sidebar {
  grid-column-gap: 20px;
  grid-row-gap: 12px;
  display: grid;
  grid-template-columns: 260px 260px;
  grid-template-rows: 65px 45px 65px 45px 65px 65px;
  color: var(--color-font-default);
  margin-right: 50px;
}
.login {
  padding-bottom: 20px;
}
.login__logo {
  margin: 130px auto 40px;
  text-align: center;
}
.login__image {
  max-width: 94px;
  max-height: 94px;
}
.login__container {
  display: flex;
  flex-wrap: wrap;
  width: 70%;
  margin: 0 auto 40px;
  padding: 80px 30px;
  background: #fff;
  box-shadow: 10px 10px 10px rgba(0,0,0,0.2);
}
.login__left__container {
  display: flex;
  flex-direction: column;
  width: 48%;
}
.login__left__container .items {
  margin-bottom: 20px;
}
.login__left__container .items ::placeholder {
  color: #aaa;
  padding-left: 15px;
}
.login__left__container .link--text {
  margin-top: 10px;
  font-size: 12px;
}
.login__left__container .link--sso--text {
  font-size: 20px;
  margin-top: 2.4rem;
  cursor: pointer;
}
.login__right__container {
  display: flex;
  flex-direction: column;
  width: 48%;
  position: relative;
}
.login__right__container .description {
  opacity: 0;
  white-space: pre-wrap;
  color: #686868;
  font-size: 11px;
  text-align: center;
  line-height: 20px;
  font-family: 'Noto Sans Japanese', serif;
  margin: -5px auto 20px;
  max-width: 560px;
  min-height: 60px;
}
.login__right__container .hr_cockpit__container {
  opacity: 0;
  max-width: 480px;
  min-width: 340px;
  margin: 0 auto;
  position: relative;
  background: #f4f4f4;
}
.login__right__container .hr_cockpit__container .hr_cockpit {
  width: 100%;
  height: 280px;
}
.login__right__container .hr_cockpit__container .slider__container {
  position: absolute;
  top: 70%;
  width: 100%;
  min-width: 340px;
  height: 200px;
}
.login__right__container .hr_cockpit__container .slider__container p {
  color: #e16c23;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 18px;
  letter-spacing: 2px;
  min-width: 340px;
}
.login__right__container .hr_cockpit__container .slider__container .slick-list {
  padding: 0;
  margin: 0 20px;
}
.login__right__container .hr_cockpit__container .slider__container .slider_img {
  height: 30px;
  padding: 0 5px;
}
.login__right__container .hr_cockpit__container .slider__container .arrow {
  position: absolute;
  display: inline-block;
  cursor: pointer;
  z-index: 1;
}
.login__right__container .hr_cockpit__container .slider__container .arrow::before {
  content: '';
  width: 15px;
  height: 15px;
  border: 0px;
  border-top: 3px solid #e16c23;
  border-right: 3px solid #e16c23;
  position: absolute;
}
.login__right__container .hr_cockpit__container .slider__container .arrow__prev {
  padding-top: 7.5px;
  padding-left: 5px;
  left: 0;
}
.login__right__container .hr_cockpit__container .slider__container .arrow__prev::before {
  -ms-transform: rotate(225deg);
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
}
.login__right__container .hr_cockpit__container .slider__container .arrow__next {
  padding-right: 15px;
  margin-top: -22.5px;
  right: 5px;
}
.login__right__container .hr_cockpit__container .slider__container .arrow__next::before {
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.login__text {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  color: #666;
}
.login__form {
  background: #fff;
  text-align: center;
}
.login__form--remind {
  background: #fff;
  box-shadow: 0 0 1px rgba(0,0,0,0.2);
  width: 562px;
  margin: 20px auto 50px;
  padding: 30px;
  text-align: center;
}
.login__form--table {
  text-align: left;
}
.login__form--th {
  width: 132px;
  font-size: 13px;
  font-family: 'Noto Sans Japanese', serif;
  text-align: left;
  color: #666;
  vertical-align: inherit;
  padding-top: 13px;
}
.login__form--td {
  width: 342px;
  height: 75px;
}
.login__form__rules {
  line-height: 1.5;
}
.login__form__rules--table {
  text-align: left;
  margin-bottom: 30px;
}
.login__form__rules--table--th {
  padding-right: 20px;
}
.password__reminder__form {
  box-shadow: 0 0 1px rgba(0,0,0,0.2);
  width: 562px;
  margin: 20px auto 30px;
  padding: 30px;
}
.auth_code_sender__text {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  color: #666;
}
.auth_code_sender__formHeading {
  text-align: left;
  color: #666;
  font-size: 16px;
  font-weight: bold;
}
.auth_code_sender__input {
  width: 280px;
  height: 40px;
  text-align: center;
  margin-top: 28px;
  border: 2px #ffa500 solid;
}
.auth_code_sender__anchor {
  font-size: 12px;
  cursor: pointer;
}
.auth_code_sender__buttons {
  width: 100%;
  overflow: hidden;
  padding-top: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.auth_code_sender__button {
  width: 120px;
  margin: 0 9px;
  border-radius: 2px;
  background-color: #f08200;
  padding: 12px 20px 10px;
  letter-spacing: normal;
  color: #fff;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
}
.auth_code_sender__button--disable {
  color: #dfdfdf;
  background-color: #f4f4f4;
  pointer-events: none;
}
.auth_code_sender__form {
  box-shadow: 0 0 1px rgba(0,0,0,0.2);
  width: 360px;
  margin: 20px auto 30px;
  padding: 30px;
  border-radius: 10px;
}
.auth_code_sender_app__heading {
  text-align: left;
  color: #666;
  font-size: 16px;
  font-weight: bold;
}
.auth_code_sender_app__note {
  text-align: left;
  color: #999;
  font-size: 14px;
  font-weight: normal;
}
.auth_code_sender_app__flex-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.auth_code_sender_app__anchor {
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
}
.auth_code_sender_app__input {
  width: 180px;
  height: 40px;
  text-align: center;
  border: 1px #000 solid;
}
.auth_code_sender_app__button {
  margin: 0 9px;
  border-radius: 2px;
  padding: 12px 20px 10px;
  letter-spacing: normal;
  background-color: #f08200;
  color: #fff;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
}
.keiei__container {
  opacity: 0;
  max-width: 520px;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  display: flex;
  padding: 15px;
  background: #f2f6f7;
  border-radius: 6px;
}
.keiei__left {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 50%;
  height: 100%;
  padding: 20% 3%;
}
.keiei__logo {
  object-fit: contain;
}
.keiei__button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  background: #1ea50a;
  padding: 8px 24px;
  color: #fff;
  transition: all ease 0.3s;
}
.keiei__button__image {
  margin-left: 6px;
}
.keiei__button:hover {
  background: #22bd0b;
}
.keiei__iframe {
  width: 50%;
  height: 100%;
}
.keiei__iframe__container {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 15px;
  background: #f2f6f7;
}
.keiei__description {
  font-size: 12px;
  line-height: 1.9;
}
.shogakukinbank__container {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.shogakukinbank__image {
  max-width: 100%;
  height: auto;
}
@media screen and (max-width: 1050px) {
  .login__logo {
    margin-top: 100px;
  }
  .login__container {
    width: 100vmin;
    padding: 2.5vmin 1.5vmin;
  }
  .login__form {
    background: #fff;
    box-shadow: 0 0 1px rgba(0,0,0,0.2);
    margin: 0 auto;
    padding: 30px 3vmin 40px;
    text-align: center;
    width: 100%;
    max-width: 402px;
  }
  .login__form--table {
    text-align: left;
    width: 100%;
  }
  .login__form--th {
    display: block;
    font-size: 13px;
    font-family: 'Noto Sans Japanese', serif;
    text-align: left;
    color: #666;
    vertical-align: top;
    padding: 13px 0;
    width: 100%;
  }
  .login__form--td {
    display: block;
    height: auto;
    width: 100%;
  }
  .login__form__rules {
    width: 100%;
    line-height: 1.5;
    font-size: 13px;
    font-family: 'Noto Sans Japanese', serif;
    color: #666;
    height: 4em;
  }
  .login__form__rules--th {
    display: inline-block;
    text-align: left;
  }
  .login__form__rules--td {
    display: block;
    text-align: left;
  }
}
.password__reminder__form .login__right {
  display: none;
}
.password__reminder__form .login__left {
  width: 100%;
}
.link--text {
  color: #49b0be;
  font-size: 13px;
  display: inline-block;
  margin-top: 30px;
}
.link--text:hover {
  text-decoration: underline;
}
.link--text:after {
  position: relative;
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  border-right: 2px solid #49b0be;
  border-top: 2px solid #49b0be;
  transform: rotate(45deg);
}
.link--text--line {
  color: #49b0be;
  font-size: 13px;
}
.link--text--line:hover {
  text-decoration: underline;
}
.link:hover {
  text-decoration: underline;
}
.link.is-active {
  font-weight: bold;
}
.link--iconArrow {
  font-size: 13px;
  line-height: 1.5;
}
.link--iconArrow:after {
  position: relative;
  content: '';
  display: inline-block;
  margin-left: 5px;
  width: 10px;
  height: 10px;
  border-right: 2px solid #49b0be;
  border-top: 2px solid #49b0be;
  transform: rotate(45deg);
}
.link--iconBlank {
  font-size: 13px;
  line-height: 1.5;
}
.link--iconBlank:after {
  content: '';
  display: inline-block;
  margin-left: 5px;
  width: 15px;
  height: 15px;
  background: url("/images/ic_blank.png");
  vertical-align: sub;
}
.link--iconPdf {
  font-size: 13px;
  line-height: 1.5;
}
.link--iconPdf:after {
  content: '';
  display: inline-block;
  margin-left: 5px;
  width: 38px;
  height: 17px;
  background: url("/images/ic_pdf.png");
  vertical-align: sub;
}
.modal__sso {
  background: #fff;
  border-radius: 5px;
  background: #fff;
  width: 390px;
  max-height: 500px;
  padding: 60px 25px;
  color: var(--color-font-default);
  font-size: 18px;
  text-align: center;
  position: absolute;
  left: 50%;
  overflow: auto;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;
}
.modal__sso--parent {
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  z-index: 40;
}
.modal__reminder {
  background: #fff;
  border-radius: 5px;
  background: #fff;
  width: 590px;
  max-height: 500px;
  padding: 60px;
  color: var(--color-font-default);
  font-size: 18px;
  text-align: center;
  position: absolute;
  left: 50%;
  overflow: auto;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;
}
.modal__reminder--parent {
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  z-index: 40;
}
.reminder_message {
  margin-bottom: 40px;
}
.modal__authapp {
  background: #fff;
  border-radius: 5px;
  background: #fff;
  width: 460px;
  max-height: 900px;
  padding: 60px 25px;
  color: var(--color-font-default);
  font-size: 18px;
  text-align: center;
  position: absolute;
  left: 50%;
  overflow: auto;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 20;
}
.modal__authapp--parent {
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  z-index: 40;
}
.modal {
  position: absolute;
  top: 0;
  left: -24px;
  width: calc(100% + 48px);
  height: calc(100% + 48px);
  opacity: 0;
  transition: 0.3s opacity;
}
.modal__bg {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #808080;
  opacity: 0.7;
  z-index: 90;
}
.modal__content {
  display: block;
  position: absolute;
  padding: 10px;
  width: 50%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background: #fff;
  z-index: 100;
}
.modal.slideIn {
  opacity: 1;
}
.modal.slideIn.slideOut {
  opacity: 0;
}
.modal--hide {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  transition: opacity 0.3s;
  opacity: 0;
}
.modal--appear {
  position: fixed;
  top: 0;
  left: 0;
  transition: opacity 0.3s;
  opacity: 1;
  z-index: 100;
  width: 100%;
}
.main__overlay {
  display: none;
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
}
.main__overlay--black {
  display: block;
  background-color: rgba(66,66,66,0.8);
}
.main__overlay--clear {
  background-color: transparent;
}
.modal__slideup {
  background: #fff;
  width: calc(100% - 354px);
  height: calc(100% - 100px);
  padding: 60px 60px 0;
  box-shadow: 0 0 5px 1px rgba(0,0,0,0.4);
  position: fixed;
  top: 100%;
  z-index: 20;
  margin-top: 100px;
  margin-left: 254px;
  overflow: scroll;
  transition: margin-left 0.5s ease-in-out;
}
.modal__slideup.def {
  margin-left: 0;
}
.modal__slideup--noshadow {
  box-shadow: none;
}
.modal__slideup .form {
  display: block;
  padding-bottom: 160px;
}
.modal__slideup--large {
  width: calc(100% - 250px);
  margin-left: 225px;
}
.modal__close {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 30;
}
.nav--close .modal__slideup {
  margin-left: 64px;
}
.modal__popup {
  background: #fff;
  border-radius: 5px;
  background: #fff;
  width: 590px;
  max-height: 500px;
  padding: 60px;
  color: var(--color-font-default);
  font-size: 18px;
  text-align: center;
  position: absolute;
  left: 50%;
  margin-left: -165px;
  overflow: auto;
  top: 20%;
  z-index: 20;
}
.ReactTabs__TabPanel .modal__popup {
  margin-left: -295px;
}
.modal__popup--parent {
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  z-index: 40;
}
.ReactTabs__TabPanel .modal__popup--parent {
  width: 100%;
  height: 100%;
}
.modal__popup--responsive {
  width: 80%;
  height: 80%;
  padding: 40px 60px;
  margin-left: -40%;
  top: 10%;
  text-align: left;
}
.modal__popup--responsive .scrollable {
  padding: 20px 0;
}
.modal__popup .scrollable {
  height: 100%;
  overflow: scroll;
}
.modal__popup .scrollable--auto {
  overflow: auto;
}
.modal__popup--employee-conditions {
  width: 900px;
  height: 80%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.modal__popup--employee-conditions .form {
  height: 100%;
}
.modal__popup--employee-conditions table {
  width: 100%;
}
.modal__popup--employee-conditions table th {
  height: 48px;
  text-align: left;
  padding: 8px 8px 8px 28px;
  font-size: 1.3rem;
  vertical-align: middle;
}
.modal__popup--employee-conditions table th.px-8 {
  padding-left: 8px;
  padding-right: 8px;
}
.modal__popup--employee-conditions table th.py-4 {
  padding-top: 4px;
  padding-bottom: 4px;
}
.modal__popup--employee-conditions table th.py-12 {
  padding-top: 12px;
  padding-bottom: 12px;
}
.modal__popup--employee-conditions table th.text-center {
  text-align: center;
}
.modal__popup--employee-conditions table th.height-auto {
  height: auto;
}
.modal__popup--employee-conditions table td {
  padding: 14px;
}
.modal__popup--employee-conditions table td:first-child {
  padding: 8px 14px;
  font-size: 1.3rem;
}
.modal__popup--employee-conditions table td:last-child {
  padding: 8px;
}
.modal__popup--employee-conditions .employeeSearchConditionTable .modTable__tr:nth-child(even) .modTable__th,
.modal__popup--employee-conditions .employeeSearchConditionTable .modTable__tr:nth-child(even) .modTable__td {
  background: #f9fcfd;
}
.modal__popup--analysis {
  overflow-y: scroll;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
}
.modal__popup--analysisXY {
  min-height: 370px;
  height: 40%;
}
.modal__popup--analysisValue {
  min-height: 480px;
  height: 50%;
}
.modal__popup--field-list-sort {
  top: 50%;
  width: 100%;
  max-width: 570px;
  height: 640px;
  max-height: 100%;
  margin-left: 0;
  transform: translate(-50%, -50%);
}
.modal__popup--w800 {
  width: 100%;
  max-width: 800px;
}
.modal__popup--w900 {
  width: 100%;
  max-width: 900px;
}
.modal__popup--w1100 {
  width: 100%;
  max-width: 1100px;
}
.modal__popup--square {
  width: 700px;
  height: 700px;
}
.modal__popup--center {
  margin-left: 0;
  transform: translateX(-50%);
}
.modal__popup--center.modal__popup--verticalCenter {
  top: 50%;
  transform: translate(-50%, -50%);
}
.modal__popup--scroll {
  overflow: scroll;
}
.modal__flex {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
}
.modal__flexItem {
  box-sizing: border-box;
  padding-right: 50px;
}
.modal__flexItem--2col {
  width: 50%;
}
.modal__flexItem--2col .formTable__cell {
  min-width: 120px;
}
.formBlock {
  border-top: solid 1px var(--color-border);
  padding: 40px 0;
}
.formBlock:first-child {
  border-top: 0;
  padding-top: 0;
}
.formBlock:last-child {
  padding-bottom: 0;
}
.modal__popup2 {
  border-radius: 5px;
  background: #fff;
  width: 518px;
  height: 80%;
  padding: 33px 8px 30px 36px;
  color: var(--color-font-default);
  font-size: 18px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 10;
}
.modal__popup2--parent {
  display: none;
  position: fixed;
  width: 100%;
  height: calc(100vh - 62px);
  top: 62px;
  left: 0;
  z-index: 1;
}
.modal__popup2 .main__overlay--black {
  display: block;
  background-color: rgba(0,0,0,0.4);
}
.modal__popup2 .modal__inner {
  overflow: auto;
  max-height: 100%;
  padding-right: 41px;
}
.modal__popup2 .formHeading {
  font-weight: normal;
}
.modal__popup2 .dataTable {
  width: 100%;
  border: 1px solid var(--color-border);
  font-size: 1.3rem;
}
.modal__popup2 .dataTable tr.currentData__row td:first-child {
  border-left: 7px solid #ef7a00;
}
.modal__popup2 .dataTable td {
  height: 54px;
}
.modal__popup-close {
  position: absolute;
  top: 10px;
  right: 10px;
}
.modalFlex {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  min-height: 754px;
}
.modalFlex__item {
  display: inline-block;
  width: 100%;
  overflow: scroll;
}
.modalFlex__item--profile {
  width: 250px;
  margin-right: 40px;
  background-color: #333;
  padding: 0 20px;
  color: #fff;
}
.modal--edit-form-history {
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 20%;
  left: 30%;
  width: 100vw;
  height: 100vh;
  z-index: 40;
}
.modal--edit-form-history__inner {
  background-color: #fff;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 64px 40px 80px;
  width: 620px;
  z-index: 20;
  position: relative;
}
.modal--edit-form-history__label {
  font-size: 16px;
  color: var(--color-font-default);
  line-height: 1;
}
.modal--edit-form-history__table {
  margin-top: 28px;
  overflow: auto;
  width: 100%;
  max-height: 270px;
  border-top: 1px solid var(--color-border);
  border-left: 1px solid var(--color-border);
}
.modal--edit-form-history__table td {
  position: relative;
}
@media screen and (max-width: 1050px) {
  .reminder_message {
    font-size: 5vmin;
  }
  .modal__reminder {
    background: #fff;
    border-radius: 5px;
    width: 90vmin;
    max-height: unset;
    padding: 60px 25px;
    color: #666;
    font-size: unset;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
  }
  .modal__sso {
    background: #fff;
    border-radius: 5px;
    width: 90vmin;
    max-width: 390px;
    max-height: unset;
    padding: 60px 25px;
    color: #666;
    font-size: unset;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
  }
}
.dialog {
  display: none;
  background: #fff;
  border-radius: 5px;
  left: 50%;
  padding: 60px;
  position: relative;
  text-align: center;
  top: 17vh;
  z-index: 10;
}
.dialog--calender {
  display: block;
  width: 550px;
  margin-left: -275px;
}
.dialog__container {
  display: none;
  left: 0;
  position: absolute;
  top: 0;
}
.dialog__container.modal--appear {
  opacity: 1;
}
.dialog .btns {
  letter-spacing: -0.4em;
}
.dialog .months_item {
  display: inline-block;
  letter-spacing: normal;
  margin: 0 9px;
}
.dialog .months_item span {
  background: #999;
  color: #fff;
  display: block;
  padding: 12px 0 10px;
  width: 150px;
}
.dialog .months_item--active span {
  background: #f08200;
}
.dialog--delete {
  margin-left: -295px;
  width: 590px;
}
.dialog--delete p {
  color: #999;
  font-size: 18px;
  margin-bottom: 40px;
}
.dialog .btns {
  letter-spacing: -0.4em;
}
.dialog .btn {
  display: inline-block;
  letter-spacing: normal;
  margin: 0 9px;
}
.dialog .btn a {
  background: #999;
  color: #fff;
  display: block;
  padding: 12px 0 10px;
  width: 150px;
}
.dialog .btn--active a {
  background: #f08200;
}
.dialog--delete {
  margin-left: -295px;
  width: 590px;
}
.dialog--delete p {
  color: #999;
  font-size: 18px;
  margin-bottom: 40px;
}
.years {
  margin: 0 15px 30px;
  justify-content: space-between;
  align-items: center;
  display: flex;
}
.years__item a,
.years__item span {
  display: block;
  color: #999;
  font-size: 24px;
}
.years__item--prev,
.years__item--next {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.years__item--prev a,
.years__item--next a,
.years__item--prev span,
.years__item--next span {
  color: #999;
  font-size: 14px;
}
.years__item--prev:before,
.years__item--next:before,
.years__item--prev:after,
.years__item--next:after {
  color: #f08200;
  font-size: 24px;
  margin-top: -2px;
}
.years__item--prev span {
  margin-left: 12px;
}
.years__item--prev:before {
  content: '<';
}
.years__item--next span {
  margin-right: 12px;
}
.years__item--next:after {
  content: '>';
}
.months__item {
  display: inline-block;
  margin: 0 10px 20px;
  width: 120px;
  cursor: pointer;
}
.months__item a,
.months__item span {
  background: #424242;
  border-radius: 2px;
  color: #fff;
  display: block;
  line-height: 1.5;
  padding: 9px 0 7px;
}
.months__item .num {
  font-size: 20px;
}
.months__item .en {
  font-size: 13px;
}
.months__item--selected span {
  background: #f08200;
}
.notice_wrap {
  display: none;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10000;
  margin: auto;
}
.notice {
  background: rgba(66,66,66,0.8);
  width: 100%;
  height: 80px;
  color: #fff;
  font-size: 18px;
  text-align: center;
  line-height: 80px;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -40px;
  z-index: 100;
}
@media screen and (max-width: 1050px) {
  .notice {
    font-size: 4vmin;
  }
}
.loading_wrap {
  display: none;
  background-color: rgba(255,255,255,0.3);
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10000;
  margin: auto;
}
.loading {
  color: #f08200;
  font-size: 90px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 72px auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 11000;
  margin: auto;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load6 1.7s infinite ease;
  animation: load6 1.7s infinite ease;
}
.loading__login {
  color: #f08200;
  border-radius: 50%;
  width: 10em;
  height: 10em;
  font-size: 10px;
  z-index: 9999;
  position: absolute;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(240,130,0,0.2);
  border-right: 1.1em solid rgba(240,130,0,0.2);
  border-bottom: 1.1em solid rgba(240,130,0,0.2);
  border-left: 1.1em solid #f08200;
  transform: translate3d(-50%, -50%, 0);
  top: 50%;
  left: 50%;
  animation: load8 1.1s infinite linear;
}
.loading__login::after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
@-webkit-keyframes load6 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%, 95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%, 59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@-moz-keyframes load6 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%, 95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%, 59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@-webkit-keyframes load6 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%, 95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%, 59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@-o-keyframes load6 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%, 95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%, 59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@keyframes load6 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%, 95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%, 59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@-moz-keyframes load8 {
  0% {
    transform-origin: 0% 0%;
    transform: rotate(0deg) translate3d(-50%, -50%, 0);
  }
  100% {
    transform-origin: 0% 0%;
    transform: rotate(360deg) translate3d(-50%, -50%, 0);
  }
}
@-webkit-keyframes load8 {
  0% {
    transform-origin: 0% 0%;
    transform: rotate(0deg) translate3d(-50%, -50%, 0);
  }
  100% {
    transform-origin: 0% 0%;
    transform: rotate(360deg) translate3d(-50%, -50%, 0);
  }
}
@-o-keyframes load8 {
  0% {
    transform-origin: 0% 0%;
    transform: rotate(0deg) translate3d(-50%, -50%, 0);
  }
  100% {
    transform-origin: 0% 0%;
    transform: rotate(360deg) translate3d(-50%, -50%, 0);
  }
}
@keyframes load8 {
  0% {
    transform-origin: 0% 0%;
    transform: rotate(0deg) translate3d(-50%, -50%, 0);
  }
  100% {
    transform-origin: 0% 0%;
    transform: rotate(360deg) translate3d(-50%, -50%, 0);
  }
}
.pager {
  display: inline-block;
}
.pager__item {
  display: inline-block;
  vertical-align: middle;
  width: 26px;
  height: 26px;
  line-height: 26px;
  color: var(--color-font-gray-02);
  text-align: center;
  cursor: pointer;
  margin: 0 3px;
}
.pager__item:hover {
  background: #f4f4f4;
}
.pager--disabled {
  opacity: 0.2;
  pointer-events: none;
}
.pager__current {
  background: #f08200;
  color: #fff;
  pointer-events: none;
}
.pager__current:hover {
  background: #f08200;
}
.pager__last {
  margin-right: 5px;
  background: url("/images/ic_first.png") no-repeat center;
}
.pager__last:hover {
  background: #f4f4f4 url("/images/ic_first.png") no-repeat center;
}
.pager__prev {
  margin-right: 5px;
  background: url("/images/ic_prev.png") no-repeat center;
}
.pager__prev:hover {
  background: #f4f4f4 url("/images/ic_prev.png") no-repeat center;
}
.pager__next {
  margin-left: 5px;
  background: url("/images/ic_next.png") no-repeat center;
}
.pager__next:hover {
  background: #f4f4f4 url("/images/ic_next.png") no-repeat center;
}
.pager__first {
  margin-left: 5px;
  background: url("/images/ic_last.png") no-repeat center;
}
.pager__first:hover {
  background: #f4f4f4 url("/images/ic_last.png") no-repeat center;
}
.page__current {
  display: inline-block;
  margin-right: 20px;
  vertical-align: middle;
  line-height: 27px;
  color: var(--color-font-default);
}
.page__view {
  display: inline-block;
}
.page__view__item {
  display: inline-block;
  vertical-align: middle;
}
.page__view__btn {
  display: inline-block;
  width: 20px;
  height: 26px;
  margin-left: 8px;
  opacity: 0.5;
  cursor: pointer;
}
.page__view__btn:hover {
  opacity: 1;
}
.page__view--current {
  opacity: 1;
}
.page__view--20,
.page__view--2 {
  background: url("/images/ic_view_20.png") no-repeat center;
}
.page__view--30,
.page__view--3 {
  background: url("/images/ic_view_30.png") no-repeat center;
}
.page__view--50,
.page__view--5 {
  background: url("/images/ic_view_50.png") no-repeat center;
}
.page__view--100,
.page__view--10 {
  background: url("/images/ic_view_100.png") no-repeat center;
}
.page__view--10e {
  background: url("/images/ic_view_10.png") no-repeat center;
}
.pagination {
  display: inline-block;
}
.pagination__item {
  display: inline-block;
  vertical-align: middle;
}
.pagination__btn {
  display: block;
  width: 26px;
  height: 26px;
  line-height: 26px;
  color: var(--color-font-gray-02);
}
.pagination__btn:hover {
  background: #f4f4f4;
}
.pagination--disabled {
  opacity: 0.2;
  pointer-events: none;
}
.pagination__current {
  background: #f08200;
  color: #fff;
}
.pagination__current:hover {
  background: #f08200;
}
.pagination__first {
  margin-right: 5px;
  background: url("/images/ic_first.png") no-repeat center;
}
.pagination__first:hover {
  background: #f4f4f4 url("/images/ic_first.png") no-repeat center;
}
.pagination__prev {
  margin-right: 5px;
  background: url("/images/ic_prev.png") no-repeat center;
}
.pagination__prev:hover {
  background: #f4f4f4 url("/images/ic_prev.png") no-repeat center;
}
.pagination__next {
  margin-left: 5px;
  background: url("/images/ic_next.png") no-repeat center;
}
.pagination__next:hover {
  background: #f4f4f4 url("/images/ic_next.png") no-repeat center;
}
.pagination__last {
  margin-left: 5px;
  background: url("/images/ic_last.png") no-repeat center;
}
.pagination__last:hover {
  background: #f4f4f4 url("/images/ic_last.png") no-repeat center;
}
.data {
  border-left: 1px solid var(--color-border);
  position: relative;
  border-top: 1px solid var(--color-border);
  overflow-x: auto;
  white-space: nowrap;
}
.data--noPager {
  border-top: 0;
}
.data--def {
  border-left: 1px solid var(--color-border);
}
.data--def .dataTable {
  border-top: 1px solid var(--color-border);
  background: #fff;
  margin-bottom: 10px;
}
.dataTable {
  font-size: 1.1rem;
  vertical-align: top;
}
.dataTable__row {
  border-bottom: 1px solid var(--color-border);
}
.dataTable__row:nth-child(even) {
  background: #f9fcfd;
}
.dataTable__row.disabled .dataTable__td {
  background: #f4f4f4;
}
.dataTable__row.selected .dataTable__td {
  background: #fff4ec;
}
.dataTable__row_orange {
  border-bottom: 1px solid var(--color-border);
}
.dataTable__row_orange:nth-child(even) {
  background: #fff9f2;
}
.dataTable__row_orange.disabled .dataTable__td {
  background: #f4f4f4;
}
.dataTable__row_orange.selected .dataTable__td {
  background: #fff4ec;
}
.dataTable__cell--check {
  width: 40px;
  padding: 0;
}
.dataTable__cell--control {
  width: 40px;
  padding: 0;
}
.dataTable__th {
  padding: 0 2px;
  height: 40px;
  vertical-align: middle;
  white-space: nowrap;
  position: relative;
}
.dataTable__th:last-child {
  border-right: 1px solid var(--color-border);
}
.dataTable__th--empty:before {
  content: "";
  display: block;
  width: 80px;
  height: 13px;
}
.dataTable__td {
  padding: 0 1px;
  vertical-align: middle;
  text-align: center;
  border-right: 1px solid var(--color-border);
  height: 45px;
}
.dataTable__td--link {
  cursor: pointer;
  color: #49b0be;
}
.dataTable__td--w500 {
  width: 500px;
}
.dataTable__td--px1 {
  padding-left: 1em;
  padding-right: 1em;
}
.dataTable--full {
  width: 100%;
}
.dataTable--full__th.last-child,
.dataTable--full__td.last-child {
  border-right: 0;
}
.dataTable__check-all-type-change-box {
  position: absolute;
  top: 13px;
  left: 32px;
  width: 260px;
  border: 1px solid var(--color-border);
  border-radius: 2px;
  background-color: #fff;
  padding: 11px 0;
  z-index: 40;
  display: none;
}
.dataTable__check-all-type-change-box--display {
  display: block;
}
.dataTable__check-all-type-change-box__row {
  height: 30px;
  width: 100%;
  padding: 4px 0 4px 10px;
  text-align: left;
}
.dataTable__check-all-type-change-box__row--selected {
  background-color: #f4f4f4;
}
.dataTable__check-all-type-change-box__row .form__radio__label {
  margin-right: 0;
}
.dataTable_fix {
  display: inline-block;
  vertical-align: top;
}
.dataTable_fix .dataTable {
  width: 627px;
  border-right: 1px solid var(--color-border);
}
.dataTable_fix .dataTable__employee {
  width: 627px;
  border-right: 1px solid var(--color-border);
  white-space: nowrap;
  text-overflow: ellipsis;
}
.dataTable_fix .dataTable__row.disabled .dataTable__td:nth-of-type(1) {
  border-left: solid 3px #999;
}
.dataTable_fix .dataTable__row.selected .dataTable__td:nth-of-type(1) {
  border-left: solid 3px #f08200;
}
.dataTable_fix .dataTable__td {
  padding: 0 2px;
}
.dataTable_fix--short {
  width: 150px;
}
.dataTable_fix--short .dataTable {
  width: 150px;
}
.dataTable_scroll {
  display: inline-block;
  max-width: 760px;
  margin-left: 1px;
  overflow-x: scroll;
  width: calc(100% - 628px);
  vertical-align: top;
}
.dataTable_scroll .dataTable {
  border-left: 0;
  border-right: 1px solid var(--color-border);
}
.dataTable_scroll .dataTable .dataTable__row {
  white-space: nowrap;
  padding: 0 12px !important;
}
.dataTable_scroll .dataTable .dataTable__row:last-child {
  border-right: 0;
}
.dataTable_scroll .dataTable .dataTable__td:last-child {
  border-right: 0;
}
.dataTable_scroll--short {
  width: calc(100% - 151px);
}
.dataTable_fix2 {
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
}
.dataTable_fix2 .modTable__th {
  white-space: nowrap;
  border-bottom: 1px solid var(--color-border);
  border-top: 0;
  border-right: 0;
}
.dataTable_fix2 .dataTable {
  border-right: 1px solid var(--color-border);
}
.dataTable_fix2 .dataTable__row.disabled .dataTable__td:nth-of-type(1) {
  border-left: solid 3px #999;
}
.dataTable_fix2 .dataTable__row.selected .dataTable__td:nth-of-type(1) {
  border-left: solid 3px #f08200;
}
.dataTable_fix2 .dataTable__td {
  padding: 0;
}
.dataTable--scroll {
  max-width: 100%;
  width: 100%;
}
.dataTable_scroll2 {
  display: inline-block;
  overflow-x: scroll;
  vertical-align: top;
  white-space: nowrap;
}
.dataTable_scroll2 .modTable__th {
  white-space: nowrap;
  border-bottom: 1px solid var(--color-border);
  border-top: 0;
}
.dataTable_scroll2 .modTable__td {
  border-top: 0;
  white-space: nowrap;
}
.dataTable_scroll2 .dataTable {
  border-left: 0;
}
.dataTable_scroll2 .dataTable .dataTable__row {
  white-space: nowrap;
  padding: 0 12px !important;
}
.data__overlay {
  position: absolute;
  top: 60px;
  left: 0;
  height: 40px;
  width: 100%;
  background: rgba(66,66,66,0.8);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 55px;
  opacity: 0;
  transition: opacity 0.1s ease-in-out, z-index 0.1s ease-in-out;
  z-index: -1;
}
.data__overlay__item {
  margin: 0 35px;
  color: #dfdfdf;
  font-size: 1.2rem;
  cursor: pointer;
}
.data__overlay__item:hover {
  text-decoration: underline;
}
.data__overlay__item01:before {
  content: '';
  background: url("/images/ic_switch.png") no-repeat;
  background-size: contain;
  width: 24px;
  height: 12px;
  display: inline-block;
  margin-right: 6px;
  vertical-align: middle;
  margin-bottom: 2px;
}
.data__overlay__item02:before {
  content: '';
  background: url("/images/ic_edit.png") no-repeat;
  background-size: contain;
  width: 15px;
  height: 15px;
  display: inline-block;
  margin-right: 8px;
  vertical-align: middle;
  margin-bottom: 2px;
}
.data__overlay__item03:before {
  content: '';
  background: url("/images/ic_tree_c.png") no-repeat;
  background-size: contain;
  width: 15px;
  height: 14px;
  display: inline-block;
  margin-right: 8px;
  vertical-align: middle;
  margin-bottom: 2px;
}
.data__overlay__item04:before {
  content: '';
  background: url("/images/ic_tree_info_c.png") no-repeat;
  background-size: contain;
  width: 15px;
  height: 14px;
  display: inline-block;
  margin-right: 8px;
  vertical-align: middle;
  margin-bottom: 2px;
}
.data__overlay__item05:before {
  content: '';
  background: url("/images/ic_history.png") no-repeat;
  background-size: contain;
  width: 15px;
  height: 14px;
  display: inline-block;
  margin-right: 8px;
  vertical-align: middle;
  margin-bottom: 2px;
}
.data__overlay__item06:before {
  content: '';
  background: url("/images/ic_detail.png") no-repeat;
  background-size: contain;
  width: 15px;
  height: 16px;
  display: inline-block;
  margin-right: 8px;
  vertical-align: middle;
  margin-bottom: 2px;
}
.data__overlay__item07:before {
  content: '';
  background: url("/images/ic_import_gear.png") no-repeat;
  background-size: contain;
  width: 15px;
  height: 16px;
  display: inline-block;
  margin-right: 8px;
  vertical-align: middle;
  margin-bottom: 2px;
}
.data__overlay__item08:before {
  content: '';
  background: url("/images/ic_output.png") no-repeat;
  background-size: contain;
  width: 15px;
  height: 16px;
  display: inline-block;
  margin-right: 8px;
  vertical-align: middle;
  margin-bottom: 2px;
}
.data__overlay--demo:nth-child(1) {
  opacity: 1;
  z-index: 1;
  top: 40px;
}
.data__overlay--demo:nth-child(2) {
  opacity: 1;
  z-index: 1;
  top: 80px;
}
.data__overlay--demo:nth-child(3) {
  opacity: 1;
  z-index: 1;
  top: 120px;
}
.data__overlay--demo:nth-child(4) {
  opacity: 1;
  z-index: 1;
  top: 160px;
}
.data__overlay--demo:nth-child(5) {
  opacity: 1;
  z-index: 1;
  top: 200px;
}
.data__overlay--demo:nth-child(6) {
  opacity: 1;
  z-index: 1;
  top: 240px;
}
.data__overlay--demo:nth-child(7) {
  opacity: 1;
  z-index: 1;
  top: 280px;
}
.data__overlay--demo:nth-child(8) {
  opacity: 1;
  z-index: 1;
  top: 320px;
}
.data__overlay--demo:nth-child(9) {
  opacity: 1;
  z-index: 1;
  top: 360px;
}
.data__overlay--demo:nth-child(10) {
  opacity: 1;
  z-index: 1;
  top: 400px;
}
input[type="checkbox"],
input[type="radio"] {
  display: none;
  cursor: pointer;
}
input[type="checkbox"]:focus,
input[type="radio"]:focus {
  outline: none;
}
.data__check {
  appearance: none;
  width: 14px;
  height: 14px;
  display: inline-block;
  margin: 0;
  padding: 0;
  background: url("/images/ic_checkbox.png") no-repeat;
}
.data__check:checked {
  background: url("/images/ic_checkbox_c.png") no-repeat;
}
.itemMasterSection .dataTable_fix .dataTable {
  width: 521px;
}
.itemMasterSection .dataTable_scroll {
  width: calc(100% - 522px);
}
.itemMasterStatus .dataTable_fix .dataTable {
  width: 337px;
}
.itemMasterStatus .dataTable_scroll {
  width: calc(100% - 338px);
}
.itemMasterPattern .dataTable_fix .dataTable {
  width: 312px;
}
.itemMasterPattern .dataTable_scroll {
  width: calc(100% - 313px);
}
.itemMasterLocation .dataTable_fix .dataTable {
  width: 546px;
}
.itemMasterLocation .dataTable_scroll {
  width: calc(100% - 547px);
}
.itemMasterPosition .dataTable_fix .dataTable {
  width: 407px;
}
.itemMasterPosition .dataTable_scroll {
  width: calc(100% - 408px);
}
.page_tree {
  height: 1000px;
  font-size: 12px;
}
.page_tree a {
  color: #222;
  text-decoration: underline;
}
.page_tree [draggable="true"] {
  -khtml-user-drag: element;
}
.page_tree [data-item="name"] {
  display: inline-block;
  margin: 5px 0;
}
.page_tree [data-item="is_super"],
.page_tree [data-item="is_approval"],
.page_tree [data-item="user_remove"] {
  display: inline-block;
  margin: 5px;
  border: 1px solid var(--color-border);
  padding: 2px 10px;
  cursor: pointer;
}
.page_tree [data-item="is_super"][data-is-super="true"],
.page_tree [data-item="is_approval"][data-is-approval="true"] {
  border: 1px solid #aaa;
  background-color: #aaa;
  color: #fff;
}
.page_tree [data-view="user-list-box"] {
  display: none;
}
.page_tree [data-view="user-setting-box"] {
  display: none;
}
.page_tree [data-view="section-section-sort-box"] {
  display: none;
}
.page_tree [data-view="section-user-sort-box"] {
  display: none;
}
.page_tree [data-item="section"] {
  padding: 10px 5px;
  cursor: pointer;
}
.page_tree [data-item="section"] [data-item="name"] {
  display: block;
  padding: 10px 5px;
  border: 1px solid var(--color-border);
}
.page_tree [data-item="section-sub"] [data-item="child-event"] {
  background-color: #ff0;
  height: 50px;
  opacity: 0;
  display: none;
}
.page_tree [data-item="section-sub"] [data-item="child-section"] {
  margin-left: 50px;
}
.page_tree [data-item="usercard"] {
  padding: 10px 5px;
  border: 1px solid var(--color-border);
  cursor: pointer;
}
.page_tree .style-tree-root {
  position: relative;
}
.page_tree .style-control-box {
  position: absolute;
  top: 20px;
  left: 10px;
  border: 1px solid var(--color-border);
}
.page_tree .style-tree-box {
  position: absolute;
  top: 70px;
  left: 10px;
  border: 1px solid var(--color-border);
  width: 60%;
  height: 500px;
  overflow: auto;
}
.page_tree .style-section-user-box {
  position: absolute;
  top: 70px;
  right: 10px;
  border: 1px solid var(--color-border);
  width: 30%;
  height: 700px;
  overflow: auto;
}
.page_tree .style-section-list {
  position: absolute;
  top: 600px;
  left: 10px;
  border: 1px solid var(--color-border);
  width: 60%;
  overflow: auto;
}
.page_tree .style-user-setting-box {
  background-color: #fff;
  position: fixed;
  width: 100%;
  height: 100%;
}
.page_tree .style-user-setting-inner {
  padding: 100px;
}
.page_tree .style-user-list-box {
  background-color: #fff;
  position: fixed;
  width: 100%;
  height: 100%;
}
.page_tree .style-user-list-inner {
  padding: 100px;
}
.page_tree .style-section-section-sort-box {
  background-color: #fff;
  position: fixed;
  width: 100%;
  height: 100%;
}
.page_tree .style-section-section-sort-inner {
  padding: 100px;
}
.page_tree .style-section-user-sort-box {
  background-color: #fff;
  position: fixed;
  width: 100%;
  height: 100%;
}
.page_tree .style-section-user-sort-inner {
  padding: 100px;
}
.page_tree [data-event="opener"] {
  font-size: 16px;
  text-decoration: none;
}
.page_tree .style-button {
  margin: 5px;
  border: 1px solid var(--color-border);
  padding: 2px 10px;
  cursor: pointer;
}
.page_tree .style-block-title {
  margin-bottom: 10px;
  font-weight: bold;
}
.page_tree .style-section-name {
  margin-top: 20px;
  margin-bottom: 10px;
}
.page_tree .style-sort-list li {
  padding: 5px 0;
}
.page_tree input[type="checkbox"] {
  appearance: checkbox;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}
.tree__diagram {
  background-color: #fff;
  height: 55vh;
  min-height: 290px;
  left: 0;
  min-width: 700px;
  padding: 23px 12px 25px 20px;
  position: absolute;
  top: 0;
  width: calc(100% - 545px);
}
.tree__diagram--extends {
  height: 100%;
}
.tree__diagram__actions {
  height: 38px;
}
.tree__wrapper--since-2019-04 .tree__diagram__actions {
  display: none;
}
.tree__diagram__actions--organization-show .tree__diagram__actions--left {
  width: 100%;
}
.tree__diagram__actions:after {
  clear: both;
  content: '';
  display: block;
}
.tree__diagram__actions--left,
.tree__diagram__actions--right {
  height: 100%;
}
.tree__diagram__actions--left > *,
.tree__diagram__actions--right > * {
  display: inline-block;
  vertical-align: top;
}
.tree__diagram__actions--left {
  float: left;
  text-align: left;
}
.tree__diagram__actions--left > * {
  margin: 0 5px;
}
.tree__diagram__actions--left > *:first-child {
  margin-left: 0;
}
.tree__diagram__actions--left div[style*='margin-top'],
.tree__diagram__actions--left div[style*='margin-top'] + *,
.tree__diagram__actions--left div[style*='margin-top'] + * + * {
  position: absolute;
  top: 23px;
}
.tree__diagram__actions--left div[style*='margin-top'] {
  right: 110px;
}
.tree__diagram__actions--left div[style*='margin-top'] + * {
  right: 30px;
}
.tree__diagram__actions--left div[style*='margin-top'] + * + * {
  top: 65px;
  right: 30px;
}
.tree__diagram__actions--right {
  float: right;
  text-align: right;
}
.tree__diagram__actions__button {
  background-color: #f08200;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  height: 38px;
  line-height: 38px;
  text-align: center;
  padding: 0 1em;
}
.tree__wrapper--since-2019-04 .tree__diagram__actions__button {
  border: solid 1px #666;
  border-radius: 2px;
  background-color: #fff;
  color: var(--color-font-default);
}
.tree__diagram__actions__button a {
  display: block;
  height: 100%;
  width: 100%;
  color: #fff;
}
.tree__diagram__actions__button a:link,
.tree__diagram__actions__button a:visited,
.tree__diagram__actions__button a:hover .tree__diagram__actions__button a:active {
  color: #fff;
}
.tree__diagram__actions__button--open-history {
  background-color: #999;
  float: right;
  margin: 10px 21px 0 6px;
}
.tree__diagram__actions__button--save-as-simulation {
  width: 190px;
}
.tree__diagram__actions__button--save-memo {
  height: 30px;
  line-height: 30px;
  margin: 0 auto;
  width: 120px;
}
.tree__diagram__actions__data--base-date {
  font-size: 12px;
  line-height: 38px;
  margin-right: 5px;
  display: flex;
  align-items: center;
}
.tree__diagram__actions__data--base-date input {
  position: relative;
  z-index: 9;
  margin-left: 5px;
  height: 25px;
}
.tree__diagram__actions__data--base-date button {
  margin-top: 5px;
}
.tree__diagram__actions__input--name,
.tree__diagram__actions__input--base-date {
  border: 1px solid var(--color-border-30);
  border-radius: 2px;
  color: #999;
  font-size: 12px;
  height: 38px;
  line-height: 1;
  padding: 12px 10px;
}
.tree__diagram__actions__input--name {
  margin-right: 10px;
  width: 180px;
}
.tree__diagram__actions__input--base-date {
  width: 140px !important;
}
.tree__diagram__actions__icon--memo {
  background-image: url("/images/ic_note.png");
  background-position: center;
  background-repeat: no-repeat;
  height: 32px;
  width: 28px;
  position: relative;
}
.tree__diagram__actions__trigger-toggle-memo {
  cursor: pointer;
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
}
.tree__diagram__actions__memo-box {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 3px 0px rgba(0,0,0,0.2);
  display: none;
  height: 122px;
  left: -246px;
  position: absolute;
  top: 40px;
  width: 298px;
  z-index: 8;
}
.tree__diagram__actions__memo-box.display {
  display: block;
}
.tree__diagram__actions__memo-textarea {
  height: 73px;
  padding: 18px;
  resize: none;
  width: 100%;
}
.tree__diagram__actions__button--close-memo-box {
  background-image: url("/images/ic_del3.png");
  cursor: pointer;
  height: 20px;
  position: absolute;
  right: -10px;
  top: -10px;
  width: 20px;
}
.tree__diagram .input-calendar {
  height: 100%;
  margin-right: 0px;
}
.tree__diagram .input-calendar-wrapper {
  left: 0px;
  top: 37px;
  z-index: 100;
}
.tree__diagram .input-calendar .calendar-icon {
  height: 100%;
}
.tree__diagram .input-calendar .calendar-icon svg {
  display: none;
}
.tree__diagram__toggle-buttons {
  height: 28px;
  margin-top: 12px;
  width: 266px;
}
.tree__wrapper--since-2019-04 .tree__diagram__toggle-buttons {
  position: relative;
  z-index: 10;
}
.tree__diagram__toggle-button--open,
.tree__diagram__toggle-button--close {
  background-color: #999;
  background-position: 14px center;
  background-repeat: no-repeat;
  border-radius: 2px;
  color: #fff;
  cursor: pointer;
  font-size: 12px;
  height: 100%;
  line-height: 1;
  padding: 8px 39px;
  padding-right: 0;
  width: 130px;
}
.tree__diagram__toggle-button--open {
  background-image: url("/images/ic_open_2.png");
  float: left;
}
.tree__diagram__toggle-button--close {
  background-image: url("/images/ic_close_2.png");
  float: right;
}
.tree__diagram__toggle-button--member {
  background-image: url("/images/member.png");
}
.tree__diagram__wrapper {
  height: calc(55vh - 160px);
  margin-top: 30px;
  user-select: none;
  padding-bottom: 50px;
}
.tree__wrapper--since-2019-04 .tree__diagram__wrapper {
  margin-top: -35px;
  height: 70vh;
}
.tree__wrapper--since-2019-04 .tree__diagram__wrapper .tree__diagram__section {
  overflow: hidden;
  max-height: calc(95vh - 250px);
}
.tree__wrapper--since-2019-04 .tree__diagram__wrapper .tree__diagram__section .tree__diagram__section {
  max-height: none;
}
.tree__wrapper--since-2019-04 .tree__diagram__wrapper .tree__diagram__section > .tree__diagram__section__children {
  max-height: calc(95vh - 250px - 40px);
  overflow: auto;
}
.tree__wrapper--since-2019-04 .tree__diagram__wrapper .tree__diagram__section > .tree__diagram__section__children .tree__diagram__section__children {
  overflow: hidden;
  max-height: none;
}
.tree__diagram__wrapper--new {
  position: relative;
}
.tree__diagram__company-name,
.tree__diagram__section {
  border-radius: 2px;
  color: var(--color-font-default);
  font-size: 12px;
  line-height: 1;
  position: relative;
  width: 215px;
}
.tree__diagram__company-name--new,
.tree__diagram__section--new {
  width: auto;
}
.tree__diagram__company-name__parent,
.tree__diagram__section__parent {
  background-color: #f4f4f4;
  cursor: pointer;
  margin-top: 0.3em;
  padding: 0.7em 0 0.7em 2.7em;
  position: relative;
}
.tree__diagram__company-name__parent--root,
.tree__diagram__section__parent--root {
  padding-top: 1em;
  cursor: default;
}
.tree__diagram__company-name__parent--root > .tree__diagram__section__handle,
.tree__diagram__section__parent--root > .tree__diagram__section__handle {
  cursor: default;
}
.tree__diagram__company-name__parent--root > .tree__diagram__section__toggle-button,
.tree__diagram__section__parent--root > .tree__diagram__section__toggle-button {
  cursor: pointer;
}
.tree__wrapper--since-2019-04 .tree__diagram__company-name__parent--root,
.tree__wrapper--since-2019-04 .tree__diagram__section__parent--root {
  background: transparent;
}
.tree__diagram__company-name__parent--active,
.tree__diagram__section__parent--active {
  background-color: #cdcdcd;
  cursor: default;
}
.tree__diagram__company-name__parent--active > .tree__diagram__section__handle,
.tree__diagram__section__parent--active > .tree__diagram__section__handle {
  cursor: default;
}
.tree__diagram__company-name__parent--active > .tree__diagram__section__toggle-button,
.tree__diagram__section__parent--active > .tree__diagram__section__toggle-button {
  cursor: pointer;
}
.tree__diagram__company-name__parent--new,
.tree__diagram__section__parent--new {
  width: 215px;
}
.tree__diagram__company-name__handle,
.tree__diagram__section__handle {
  background: url("/images/ic_draggable.png") center center/contain;
  cursor: all-scroll;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 2em;
}
.tree__diagram__company-name__children,
.tree__diagram__section__children {
  margin-left: 15px;
}
.tree__diagram__company-name__children .tree__diagram__company-name__children,
.tree__diagram__section__children .tree__diagram__section__children {
  margin-left: 27px;
}
.tree__wrapper--since-2019-04 .tree__diagram__company-name__children,
.tree__wrapper--since-2019-04 .tree__diagram__section__children {
  margin-left: 0px;
}
.tree__wrapper--since-2019-04 .tree__diagram__company-name__children .tree__wrapper--since-2019-04 .tree__diagram__company-name__children,
.tree__wrapper--since-2019-04 .tree__diagram__section__children .tree__wrapper--since-2019-04 .tree__diagram__section__children {
  margin-left: 0px;
}
.tree__diagram__company-name--dragging,
.tree__diagram__section--dragging {
  position: absolute;
  z-index: 500;
  margin: 0;
  pointer-events: none;
}
.tree__diagram__company-name--dragging .tree__diagram__section__parent,
.tree__diagram__section--dragging .tree__diagram__section__parent {
  margin-left: 12px !important;
}
.tree__diagram__company-name--dragging > .tree__diagram__section__parent,
.tree__diagram__section--dragging > .tree__diagram__section__parent {
  margin: 0;
  margin-left: 0 !important;
}
.tree__diagram__company-name--placeholder,
.tree__diagram__section--placeholder {
  background-color: #f7fdff;
  border: 1px dashed #c7daff;
  font-size: 12px;
  height: 29px;
  margin-top: 0.3em;
  text-align: center;
  width: 280px;
}
.tree__diagram__company-name__toggle-button,
.tree__diagram__section__toggle-button {
  display: none;
  height: 20px;
  left: 2.4em;
  position: absolute;
  bottom: 50%;
  transform: translateY(55%);
  width: 20px;
}
.tree__diagram__company-name--close > .tree__diagram__section__parent,
.tree__diagram__section--close > .tree__diagram__section__parent,
.tree__diagram__company-name--open > .tree__diagram__section__parent,
.tree__diagram__section--open > .tree__diagram__section__parent {
  padding-left: 4.3em;
}
.tree__diagram__company-name.tree__diagram__section--close .tree__diagram__company-name__toggle-button,
.tree__diagram__section.tree__diagram__section--close .tree__diagram__section__toggle-button,
.tree__diagram__company-name.tree__diagram__section--open .tree__diagram__company-name__toggle-button,
.tree__diagram__section.tree__diagram__section--open .tree__diagram__section__toggle-button {
  display: block;
}
.tree__diagram__company-name.tree__diagram__section--close > .tree__diagram__company-name__parent > .tree__diagram__company-name__toggle-button,
.tree__diagram__section.tree__diagram__section--close > .tree__diagram__section__parent > .tree__diagram__section__toggle-button {
  background: url("/images/ic_close_1.png") center center/1.3em 1.3em no-repeat;
}
.tree__diagram__company-name.tree__diagram__section--open > .tree__diagram__company-name__parent > .tree__diagram__company-name__toggle-button,
.tree__diagram__section.tree__diagram__section--open > .tree__diagram__section__parent > .tree__diagram__section__toggle-button {
  background: url("/images/ic_open_1.png") center center/1.3em 1.3em no-repeat;
}
.tree__diagram__company-name__user-move-label,
.tree__diagram__section__user-move-label {
  background-color: #fff8f3;
  border: 1px dashed #ffc7c7;
  font-size: 12px;
  height: 38px;
  line-height: 1;
  margin-top: 10px;
  padding: 13px 0 13px 10px;
  text-align: center;
}
.tree__diagram__company-name {
  padding: 0.7em;
  background-color: #f4f4f4;
}
.tree__wrapper--organization .tree__diagram__wrapper {
  height: 57.5vh;
}
.tree__section-list {
  background-color: #fff;
  height: 42vh;
  bottom: -45vh;
  left: 0;
  min-width: 700px;
  padding: 28px 12px 0 13px;
  position: absolute;
  width: calc(100% - 545px);
}
.tree__section-list--extends {
  top: 0;
  bottom: auto;
  width: 100%;
  min-width: none;
  height: 100%;
}
.tree__section-list__caption {
  color: var(--color-font-gray-02);
  font-size: 16px;
  line-height: 1;
  margin-left: 7px;
}
.tree__section-list__sections {
  height: calc(100% - 44px);
  margin-top: 23px;
  overflow: auto;
  display: grid;
  row-gap: 1rem;
  grid-auto-rows: 40px;
  grid-template-columns: 50% 50%;
}
.tree__section-list__sections .tree__section-list__section--col2 {
  width: calc(100% - 1em);
}
.tree__section-list__section {
  border-radius: 2px;
  position: relative;
  width: 225px;
}
.tree__section-list__section__parent {
  display: flex;
  height: 100%;
}
.tree__section-list__section__handle {
  background-image: url("/images/ic_draggable.png");
  background-repeat: no-repeat;
  background-size: 2em 100%;
  cursor: all-scroll;
  width: 2em;
  height: 100%;
}
.tree__section-list__section--dragging {
  position: absolute;
  z-index: 500;
  margin: 0;
  pointer-events: none;
}
.tree__section-list__section__name {
  width: calc(100% - 2em);
  height: 100%;
  padding: 0.8em 7px;
  background-color: #f4f4f4;
  color: var(--color-font-default);
  font-size: 12px;
}
.tree__section-list__section--col2 {
  width: 328px;
}
.tree__section-list__section--enter .tree__section-list__section__parent {
  z-index: 100;
  box-shadow: 0 1px 4px rgba(0,0,0,0.2);
}
.tree__employee-list {
  background-color: #fff;
  color: var(--color-font-default);
  height: 100vh;
  padding: 30px 20px;
  position: absolute;
  right: 10px;
  top: 0;
  width: 510px;
}
.tree__employee-list__section-name {
  font-size: 14px;
  width: 215px;
  height: 30px;
  line-height: 16px;
}
.tree__employee-list__section-name__deleteButton:hover {
  color: var(--color-font-default);
}
.tree__employee-list__button {
  height: 30px;
  width: 250px;
}
.tree__employee-list__button--add-user,
.tree__employee-list__button--delete-section {
  background-color: #f08200;
  border-radius: 2px;
  color: #fff;
  cursor: pointer;
  font-size: 12px;
  height: 30px;
  line-height: 1;
  padding: 9px 0;
  text-align: center;
  width: 120px;
}
.tree__employee-list__button--add-user {
  float: right;
}
.tree__employee-list__button--delete-section {
  float: left;
}
.tree__employee-list__button--delete-section.disabled {
  background-color: #f4f4f4;
  cursor: default;
  color: #d0d0d0;
}
.tree__employee-list__employees {
  font-size: 12px;
  height: calc(100% - 55px);
  overflow: auto;
}
.tree__employee-list__employee {
  background-color: #fff;
  border: 2px solid transparent;
  color: var(--color-font-default);
  height: 140px;
  list-style: none;
  margin-right: 15px;
  margin-bottom: 20px;
  position: relative;
  width: 220px;
  float: left;
}
.tree__employee-list__employee.is_primary {
  border: 2px solid #ffe0ca;
}
.tree__employee-list__employee.is_primary .tree__employee-list__employee__inner {
  border: 3px solid #ffe0ca;
}
.tree__employee-list__employee__inner {
  border: 3px solid #f4f4f4;
  height: 100%;
  padding: 10px;
  padding-bottom: 0;
  position: relative;
  width: 100%;
}
.tree__employee-list__employee__photo {
  background-color: #ddd;
  height: 36px;
  width: 36px;
}
.tree__employee-list__employee__relativePhoto {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}
.tree__employee-list__employee__relativePhoto__wrap {
  display: block;
  width: 36px;
  height: 36px;
}
.tree__employee-list__employee__button--remove {
  background-image: url("/images/ic_del3.png");
  cursor: pointer;
  height: 20px;
  position: absolute;
  right: -10px;
  top: -10px;
  width: 20px;
  z-index: 10;
}
.tree__employee-list__employee__data {
  font-size: 12px;
  left: 114px;
  line-height: 1.5;
  position: absolute;
  top: 10px;
}
.tree__employee-list__employee__data > * {
  margin-bottom: 6px;
}
.tree__employee-list__employee--dragging {
  opacity: 0.6;
  position: absolute;
  z-index: 500;
  margin: 0;
  pointer-events: none;
}
.tree__employee-list__employee__handle {
  cursor: all-scroll;
  height: 140px;
  left: -1px;
  position: absolute;
  top: -1px;
  width: 240px;
  z-index: 8;
}
.tree__employee-list__employee__handle--disabled {
  cursor: default;
}
.tree__employee-list__employee__power-badges {
  margin-top: 2px;
  text-align: center;
  width: 106px;
}
.tree__employee-list__employee__joined-section-count {
  background-color: #f08200;
  border-radius: 10px;
  bottom: 5px;
  color: #fff;
  font-size: 12px;
  height: 20px;
  line-height: 20px;
  position: absolute;
  right: 5px;
  text-align: center;
  width: 20px;
}
.tree__employee-list__employee__power-badge {
  background-color: #000;
  border-radius: 9px;
  color: #fff;
  display: inline-block;
  font-size: 12px;
  height: 18px;
  line-height: 1;
  margin-right: 2px;
  padding: 3px 5px;
}
#tabli {
  width: 50%;
  float: left;
  text-align: center;
  background: #ddd;
}
#tabli div {
  display: block;
  width: 100%;
  line-height: 36px;
  text-decoration: none;
  color: var(--color-font-default);
}
#tabcontent1,
#tabcontent2 {
  position: absolute;
  overflow: hidden;
  top: 36px;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
}
#tabcontent1,
#tabcontent2 {
  display: none;
  width: 100%;
  background: #fff;
  padding-left: 2em;
  padding-right: 2em;
}
.active {
  background: #fff;
}
.employeeListTable {
  position: relative;
  border-collapse: collapse;
  border: 1px solid var(--color-border);
  border-left: none;
  border-right: none;
  box-sizing: border-box;
  font-size: 1.1rem;
}
.employeeListTable th,
.employeeListTable td {
  padding: 1em;
  text-align: center;
  vertical-align: top;
  white-space: pre;
}
.employeeListTable th {
  font-weight: bold;
}
.employeeListTable td {
  border-top: 1px solid var(--color-border);
  vertical-align: middle;
}
.employeeListTable td:nth-child(n + 2) {
  background: linear-gradient(to right, #dfdfdf 1px, #fff 1px);
  background-size: 100% 70%;
  background-position: left center;
  background-repeat: no-repeat;
}
.wrapper {
  display: grid;
  column-gap: 1.5em;
  grid-template-columns: 36px 135px;
  background-color: #fff;
  color: #444;
}
.box {
  color: var(--color-font-default);
}
.a {
  grid-column: 2/3;
  grid-row: 1;
  text-align: left;
}
.b {
  grid-column: 1;
  grid-row: 1/3;
}
.d {
  grid-column: 2;
  grid-row: 2;
  margin-right: auto;
}
.sectionName {
  font-size: 120%;
}
.button-bar {
  width: 100%;
  height: 40px;
  display: grid;
  font-size: 12px;
  grid-template-columns: 140px 140px 100px 55px 105px;
  background-color: #fff;
  color: #444;
  margin-top: 17px;
  margin-bottom: -11px;
  border-top: 1px solid var(--color-border);
  border-bottom: 1px solid var(--color-border);
}
.button-bar .bar.aa,
.button-bar .bar.bb {
  color: #f08200;
}
.button-bar .bar.bb {
  border-left: 1px solid var(--color-border);
}
.button-bar .bar.bb.inactive {
  color: #f4f4f4;
  cursor: default !important;
}
.button-bar .bar.bb.inactive span {
  background: #f4f4f4;
}
.button-bar .bar.cc,
.button-bar .bar.dd {
  height: 22px;
  margin-top: 7px;
  font-size: 10px;
}
.button-bar .bar.dd {
  margin-left: 0.3em;
  margin-right: 0.3em;
  background: #f4f4f4;
  color: #fff;
  border-radius: 2px;
}
.button-bar .bar.ee {
  height: 22px;
  margin-top: 11px;
  margin-left: auto;
  margin-right: auto;
}
.button-bar.button-bar--independence {
  grid-template-columns: 100px 55px 105px;
}
.bar {
  background-color: #fff;
  color: var(--color-font-default);
}
.bar .ee {
  width: 100%;
  height: 28px;
  text-align: center;
  line-height: 28px;
  font-size: 12px;
}
.detail-wrapper {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 10% 90%;
  background-color: #fff;
  color: #444;
}
.detail-photo {
  background-color: #ddd;
  height: 96px;
  width: 96px;
}
.detail-box {
  background-color: #fff;
  color: var(--color-font-default);
}
.d-a {
  grid-column: 2/3;
  grid-row: 1;
}
.d-b {
  grid-column: 1;
  grid-row: 1/3;
  width: 36px;
}
.d-d {
  grid-column: 2;
  grid-row: 2;
}
.d-e {
  grid-column: 2;
  grid-row: 3;
}
.d-f {
  grid-column: 2;
  grid-row: 4;
}
.d-g {
  grid-column: 2;
  grid-row: 5;
}
.d-h {
  grid-column: 2;
  grid-row: 6;
}
.d-i {
  grid-column: 2;
  grid-row: 7;
}
.d-j {
  grid-column: 2;
  grid-row: 8;
}
.d-k {
  grid-column: 2;
  grid-row: 9;
}
.tree__modal {
  color: var(--color-font-default);
}
.tree__modal--save,
.tree__modal--user-add,
.tree__modal--user-edit,
.tree__modal--history,
.tree__modal--export {
  display: none;
}
.tree__modal--save__container,
.tree__modal--user-add__container,
.tree__modal--user-edit__container,
.tree__modal--history__container,
.tree__modal--export__container {
  background-color: #fff;
  border-radius: 5px;
  left: 50%;
  margin-left: -259px;
  position: absolute;
  width: 520px;
  z-index: 20;
}
.tree__modal--save__button--close,
.tree__modal--user-add__button--close,
.tree__modal--user-edit__button--close,
.tree__modal--history__button--close,
.tree__modal--export__button--close {
  position: absolute;
  right: 12px;
  top: 12px;
}
.tree__modal--save__validation-error,
.tree__modal--user-add__validation-error,
.tree__modal--user-edit__validation-error,
.tree__modal--history__validation-error,
.tree__modal--export__validation-error {
  color: #f00;
  font-size: 12px;
  line-height: 1.5;
  text-align: center;
}
.tree__modal--save__container {
  height: 90%;
  max-height: 640px;
  padding: 30px;
  top: 20%;
}
.tree__modal--save__inner {
  height: calc(100% - 20px);
  margin: 10px 0;
  overflow: auto;
}
.tree__modal--save__inner .form__text,
.tree__modal--save__inner .form__textarea,
.tree__modal--save__inner .form__date {
  width: 220px !important;
}
.tree__modal--save__inner .form__date {
  height: auto;
}
.tree__modal--save__inner .form__textarea {
  resize: none;
}
.tree__modal--save__inner .input-calendar-wrapper {
  left: 0px;
}
.tree__modal--save__inner .form__select {
  width: 220px;
}
.tree__modal--save__form-radio {
  white-space: normal !important;
  word-break: break-all;
  display: inline-block;
  margin-right: 5px;
  padding: 8px 0 8px 20px;
  background: url("/images/form_radio_off.png") no-repeat left center;
  cursor: pointer;
}
.tree__modal--save__form-radio--checked {
  background-image: url("/images/form_radio_on.png");
}
.tree__modal--save__user-place-text {
  padding-left: 10px;
  font-size: 12px;
  width: 100px;
}
.tree__modal--save .buttons {
  padding-top: 144px;
}
.tree__modal--user-add__container {
  color: var(--color-font-default);
  height: 90%;
  max-height: 616px;
  padding: 30px 10px 30px 36px;
  top: 20%;
}
.tree__modal--user-add__inner {
  height: 100%;
}
.tree__modal--user-add__header {
  height: 36px;
  width: 435px;
}
.tree__modal--user-add__heading {
  float: left;
  font-size: 16px;
  line-height: 36px;
}
.tree__modal--user-add__input--search-name {
  background-image: url("/images/ic_search.png");
  background-position: 11px center;
  background-repeat: no-repeat;
  border: 1px solid var(--color-border);
  border-radius: 2px;
  float: right;
  font-size: 12px;
  height: 36px;
  line-height: 1;
  padding: 11px;
  padding-left: 40px;
  width: 220px;
}
.tree__modal--user-add__button--initial-switch-wrapper {
  height: 34px;
  margin-top: 20px;
  position: relative;
  width: 435px;
}
.tree__modal--user-add__button--initial-switch {
  background-color: #f4f4f4;
  cursor: pointer;
  font-size: 14px;
  height: 34px;
  line-height: 1;
  padding: 10px 0;
  position: absolute;
  text-align: center;
  top: 0px;
  width: 34px;
}
.tree__modal--user-add__button--initial-switch.selected {
  background-color: #f08200;
  color: #fff;
  cursor: default;
}
.tree__modal--user-add__button--initial-switch:nth-child(1) {
  left: 0px;
}
.tree__modal--user-add__button--initial-switch:nth-child(2) {
  left: 40px;
}
.tree__modal--user-add__button--initial-switch:nth-child(3) {
  left: 80px;
}
.tree__modal--user-add__button--initial-switch:nth-child(4) {
  left: 120px;
}
.tree__modal--user-add__button--initial-switch:nth-child(5) {
  left: 160px;
}
.tree__modal--user-add__button--initial-switch:nth-child(6) {
  left: 200px;
}
.tree__modal--user-add__button--initial-switch:nth-child(7) {
  left: 240px;
}
.tree__modal--user-add__button--initial-switch:nth-child(8) {
  left: 280px;
}
.tree__modal--user-add__button--initial-switch:nth-child(9) {
  left: 320px;
}
.tree__modal--user-add__button--initial-switch:nth-child(10) {
  left: 360px;
}
.tree__modal--user-add__button--initial-switch:nth-child(11) {
  left: 400px;
}
.tree__modal--user-add__user-container {
  border-top: 1px solid var(--color-border);
  height: calc(100% - 160px);
  margin-top: 12px;
  margin-bottom: 20px;
  max-height: 396px;
  overflow: auto;
  width: 448px;
}
.tree__modal--user-add__user {
  border: 1px solid var(--color-border);
  height: 54px;
  position: relative;
  width: 100%;
}
.tree__modal--user-add__user:nth-child(1) {
  border-top-width: 0px;
}
.tree__modal--user-add__user:nth-child(odd) {
  background-color: #fff;
}
.tree__modal--user-add__user:nth-child(even) {
  background-color: #f9fcfd;
}
.tree__modal--user-add__user__checkbox {
  left: 20px;
  position: absolute;
  top: 20px;
}
.tree__modal--user-add__user__name {
  font-size: 13px;
  left: 65px;
  line-height: 1;
  position: absolute;
  top: 20px;
}
.tree__modal--user-add__user__cover {
  cursor: pointer;
  height: 52px;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 432px;
}
.tree__modal--user-add__button--select {
  background-color: #f08200;
  color: #fff;
  cursor: pointer;
  font-size: 12px;
  height: 38px;
  margin: 20px auto 0;
  padding: 13px 0;
  text-align: center;
  width: 150px;
}
.tree__modal--user-edit__container {
  font-size: 12px;
  padding: 50px 120px;
  top: 27%;
  width: 520px;
}
.tree__modal--user-edit__inner {
  width: 100%;
}
.tree__modal--user-edit__user-data {
  line-height: 2;
  overflow: hidden;
  zoom: 1;
}
.tree__modal--user-edit__user-data__image {
  float: left;
  height: 96px;
  width: 96px;
  background-color: #ddd;
}
.tree__modal--user-edit__user-data__relativeImage {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}
.tree__modal--user-edit__user-data__relativeImage__wrap {
  display: block;
  width: 96px;
  height: 96px;
  float: left;
}
.tree__modal--user-edit__user-data__text {
  float: right;
  width: 151px;
}
.tree__modal--user-edit__input-user-power {
  margin-top: 25px;
}
.tree__modal--user-edit__input-user-power__input--is-super {
  margin-right: 48px !important;
}
.tree__modal--user-edit__input-user-power__input--is-approval {
  margin-right: 0 !important;
}
.tree__modal--user-edit__input-position {
  margin-top: 30px;
}
.tree__modal--user-edit__input-position:after {
  clear: both;
  content: '';
  display: block;
}
.tree__modal--user-edit__input-position__label {
  float: left;
  line-height: 36px;
}
.tree__modal--user-edit__input-position__select {
  float: right;
  height: 36px;
  width: 230px !important;
}
.tree__modal--user-edit__input-primary-section__label {
  display: block !important;
  padding: 10px 0 10px 20px !important;
}
.tree__modal--user-select__user {
  cursor: pointer;
}
.tree__modal--history__container {
  color: var(--color-font-default);
  max-height: max-content;
  padding: 40px 34px 30px;
  top: 135px;
}
.tree__modal--history__inner {
  height: 100%;
}
.tree__modal--history__heading {
  font-size: 16px;
}
.tree__modal--history__list-wrapper {
  height: calc(100% - 50px);
  margin-top: 25px;
  overflow: auto;
}
.tree__modal--history__list {
  width: 436px;
}
.tree__modal--history__list-header,
.tree__modal--history__list-item {
  border: 1px solid var(--color-border);
  font-size: 12px;
  overflow: hidden;
  text-align: center;
  width: 100%;
}
.tree__modal--history__list-header__base-date,
.tree__modal--history__list-item__base-date,
.tree__modal--history__list-header__name,
.tree__modal--history__list-item__name,
.tree__modal--history__list-header__created_at,
.tree__modal--history__list-item__created_at,
.tree__modal--history__list-header__delete,
.tree__modal--history__list-item__delete {
  float: left;
  line-height: 1.5;
  padding: 11px 5px;
}
.tree__modal--history__list-header__base-date,
.tree__modal--history__list-item__base-date,
.tree__modal--history__list-header__created_at,
.tree__modal--history__list-item__created_at {
  letter-spacing: -0.3px;
  width: 90px;
}
.tree__modal--history__list-header__name,
.tree__modal--history__list-item__name {
  width: 148px;
}
.tree__modal--history__list-header__name--link,
.tree__modal--history__list-item__name--link {
  color: #49b0be;
}
.tree__modal--history__list-header__name--link:hover,
.tree__modal--history__list-item__name--link:hover {
  text-decoration: underline;
  cursor: pointer;
}
.tree__modal--history__list-header__delete,
.tree__modal--history__list-item__delete {
  width: 106px;
}
.tree__modal--history__list-header__button--delete,
.tree__modal--history__list-item__button--delete {
  background-color: #999;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  height: 30px;
  line-height: 30px;
  padding: 0;
  text-align: center;
  width: 80px;
}
.tree__modal--history__list-header__button--delete a,
.tree__modal--history__list-item__button--delete a {
  color: #fff;
  display: block;
  height: 100%;
  width: 100%;
}
.tree__modal--history__list-header__button--delete a:link,
.tree__modal--history__list-item__button--delete a:link,
.tree__modal--history__list-header__button--delete a:visited,
.tree__modal--history__list-item__button--delete a:visited,
.tree__modal--history__list-header__button--delete a:hover,
.tree__modal--history__list-item__button--delete a:hover,
.tree__modal--history__list-header__button--delete a:active,
.tree__modal--history__list-item__button--delete a:active {
  color: #fff;
}
.tree__modal--history__list-header {
  font-weight: bold;
}
.tree__modal--history__list-header > * {
  height: 50px;
  line-height: 28px;
}
.tree__modal--history__list-item {
  border-top-width: 0px;
}
.tree__modal--history__list-item > * {
  height: 54px;
}
.tree__modal--history__list-item:nth-child(odd) {
  background-color: #fff;
}
.tree__modal--history__list-item:nth-child(even) {
  background-color: #f9fcfd;
}
.tree__modal--history__list-item__base-date,
.tree__modal--history__list-item__name,
.tree__modal--history__list-item__created_at {
  border-right: 1px solid var(--color-border);
}
.tree__modal--history__list-item.is_current .tree__modal--history__list-item__base-date {
  border-left: 4px solid #f08200;
  padding-left: 1px;
}
.tree__modal--export__container {
  color: var(--color-font-default);
  max-height: max-content;
  padding: 40px 34px 30px;
  top: 135px;
}
.tree__modal--export__inner {
  height: 100%;
}
.tree__modal--export__heading {
  font-size: 16px;
}
.tree__modal--export__body {
  height: calc(100% - 50px);
  margin-top: 25px;
  overflow: auto;
}
.tree__modal--export__body__output-type {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 1rem 0 0 0;
}
.tree__modal--export__body__output-type__ttl {
  margin: 0 10% 0 0;
}
.tree__modal--export__body__output-type__csv-remark {
  width: 100%;
  line-height: 1;
  text-align: right;
  padding: 0 71px 0 0;
  margin: 2px 0 0 0;
  font-size: 1.2rem;
}
.tree__modal--import {
  display: none;
}
.tree__modal--import__container {
  background-color: #fff;
  border-radius: 5px;
  left: 50%;
  margin-left: -365px;
  position: absolute;
  z-index: 20;
  width: 730px;
  height: 90%;
  max-height: 660px;
  padding: 30px;
  top: 20%;
}
.tree__modal--import__inner {
  height: calc(100% - 20px);
  margin: 10px 0;
  overflow: auto;
}
.tree__modal--import__inner .form__text,
.tree__modal--import__inner .form__textarea,
.tree__modal--import__inner .form__date {
  width: 220px !important;
}
.tree__modal--import__inner .form__date {
  height: auto;
}
.tree__modal--import__inner .form__textarea {
  resize: none;
}
.tree__modal--import__inner .input-calendar-wrapper {
  left: 0px;
}
.tree__modal--import__inner .form__select {
  width: 220px;
}
.tree__modal--user-add__user .form__checkbox__label {
  font-size: 13px;
  left: 40px;
  line-height: 1;
  position: absolute;
  top: 19px;
}
.tree__wrapper {
  height: 55vh;
  position: relative;
  min-width: 1230px;
  width: 1240px;
  min-height: 353px;
}
.tree__wrapper--organization {
  height: 80vh;
  margin-bottom: -3vh;
}
.tree__wrapper--organization .tree__diagram {
  height: 100vh;
}
.tree__wrapper--since-2019-04 {
  width: 100%;
  height: 87.5vh;
  margin-bottom: -180px;
  display: flex;
}
.tree__wrapper--since-2019-04 > * {
  position: relative !important;
  flex: 1;
  box-sizing: border-box;
  height: 100% !important;
}
.tree__wrapper--since-2019-04 > *:first-child {
  padding-top: 13px;
}
.tree__wrapper--since-2019-04 > *:nth-child(2) {
  margin-left: 2em;
}
.tree__wrapper--since-2019-04 > * > * {
  width: auto;
  box-sizing: border-box;
  min-width: auto;
  width: 100%;
  height: 100%;
}
.tree__wrapper--since-2019-04 .tree__employee-list__employees {
  height: auto;
}
.tree__wrapper--since-2019-04 .tree__diagram {
  border: 2px solid transparent;
}
.tree__wrapper--since-2019-04 .tree__diagram--editing {
  border-color: #f08302;
}
.tabs {
  overflow: hidden;
  position: relative;
  z-index: 10;
}
.tabs--item {
  float: left;
  border: 1px solid;
  background: #dfdfdf;
  padding: 14px 30px 14px;
  font-size: 16px;
}
.tabs--item input[type="text"] {
  background: #dfdfdf;
  border: none;
  border-radius: 3px;
}
.tabs--item input[type="text"]:focus {
  border: solid 1px #666;
  outline: none;
}
.tabs--item.is-active {
  background: #fff;
}
.tabs--item.is-active input[type="text"] {
  background: #fff;
  border: none;
}
.tab__item {
  display: inline-block;
  background: #dfdfdf;
  border: 1px solid var(--color-border);
  font-size: 16px;
  height: 40px;
  line-height: 40px;
  padding: 0 10px 0 25px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  margin-right: 4px;
  margin-top: 4px;
  cursor: pointer;
}
.tab__item span {
  display: inline-block;
  vertical-align: middle;
}
.tab__item.is-active {
  background: #fff;
  border-bottom: 1px solid #fff;
}
.tab__item--add {
  width: 42px;
  text-align: center;
  padding: 0;
  background: #f08200;
  border: solid 1px #f08200;
}
.tab__txt {
  margin-right: 20px;
  color: var(--color-font-default);
}
.tab__panel {
  padding: 0 20px 30px;
  border: 1px solid var(--color-border);
  position: relative;
  z-index: 0;
  top: -1px;
}
input,
button,
textarea,
select {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.formHead {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  color: var(--color-font-default);
}
.formHead--mt30 {
  margin-top: 30px;
}
.formHead--mb0 {
  margin-bottom: 0;
}
.formHead--justify {
  justify-content: space-between;
}
.formHeading {
  margin-right: 20px;
  color: var(--color-font-default);
  font-size: 16px;
  font-weight: bold;
}
.sso-formHeading {
  text-align: left;
  color: var(--color-font-default);
  font-size: 16px;
  font-weight: bold;
}
.formNote {
  color: var(--color-font-default);
  font-size: 10px;
  line-height: 1.5;
}
.formError {
  color: #f00;
  font-size: 10px;
  line-height: 1.5;
}
.formError--sections {
  color: #f00;
  font-size: 14px;
  line-height: 1.5;
}
.formError--large {
  color: #f00;
  font-size: 14px;
  line-height: 1.5;
}
.formError--middle {
  color: #f00;
  font-size: 11px;
  line-height: 1.5;
}
.formWarn {
  color: var(--color-orange-01);
  font-size: 10px;
  line-height: 1.5;
}
.formTable {
  color: var(--color-font-default);
  font-size: 14px;
}
.formTable--large {
  min-width: 500px;
}
.formTable__row--blank {
  height: 65px;
}
.formTable__row--h30 {
  height: 30px;
}
.formTable__cell {
  padding: 10px;
  vertical-align: top;
  text-align: left;
}
.formTable__cell--middle {
  vertical-align: middle;
}
.formTable__cell__box {
  padding: 5px 0;
}
.formTable__cell__item {
  display: inline-block;
}
.formTable__cell__input__disabled input {
  background-color: #ddd;
}
.formTable__th {
  min-width: 200px;
  vertical-align: top;
}
.formTable__th--label {
  vertical-align: middle;
  padding: 10px;
}
.formTable__th--auto {
  min-width: initial;
  vertical-align: middle;
}
.formTable__th--top {
  padding-top: 20px;
  vertical-align: top;
}
.formTable__th--vmiddle {
  vertical-align: middle;
}
.formTable--center {
  margin: auto;
}
.formTable--center02 {
  margin: 20px auto 0;
}
.formTable__block {
  display: block;
  margin-bottom: 10px;
}
.login__form--middle,
.sso-input--middle {
  width: 280px;
  height: 35px;
  font-size: 16px;
}
.login__form--middle::placeholder,
.sso-input--middle::placeholder {
  padding-left: 0;
  font-size: 16px;
}
.login__form--submit,
.sso-input--submit {
  display: block;
  width: 264px;
  margin: 35px auto 0;
  border: 0;
  background: #f08200;
  border: solid 1px #f08200;
  border-radius: 0;
  font-size: 16px;
  color: #fff;
  text-align: center;
  line-height: 1.5;
  letter-spacing: 3px;
  cursor: pointer;
  border-radius: 6px;
  transition: all ease 0.3s;
}
.login__form--submit:hover,
.sso-input--submit:hover {
  background: rgba(253,148,25,0.91);
}
input {
  font-size: 14px;
  background: #fff;
  color: var(--color-font-default);
  border: solid 1px var(--color-border);
  padding: 9px 14px;
  overflow: hidden;
  border-radius: 3px;
  outline: none;
}
input:focus {
  border: solid 1px #f08200;
}
input::placeholder {
  color: var(--color-font-gray-02);
}
input[type="date"] {
  padding: 4px 8px;
}
.error {
  display: none;
}
.js-error input {
  border: solid 1px #d80c0c;
  background: #fff7f7;
}
.js-error .error {
  display: block;
}
.input--middle {
  width: 342px;
  height: 46px;
  font-size: 16px;
}
.input--middle::placeholder {
  padding-left: 0;
  font-size: 16px;
}
.input--label {
  display: inline-block;
  margin: 15px auto 0;
}
.input--label span {
  padding-left: 12px;
  font-size: 13px;
  color: var(--color-font-default);
}
.input--label--co {
  position: relative;
  padding-left: 2em;
}
.input--label--co--login {
  display: none;
}
.input--label--co--login:before {
  content: url("/images/ic_company_01.png");
  position: relative;
  left: 0;
  top: 0;
  z-index: 1;
  vertical-align: middle;
}
.input--label--co:before {
  content: url("/images/ic_company_01.png");
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}
.input--label--id {
  position: relative;
  padding-left: 2em;
}
.input--label--id--login {
  display: none;
}
.input--label--id--login:before {
  content: url("/images/ic_user_01.png");
  position: relative;
  left: 0;
  top: 0;
  z-index: 1;
  vertical-align: middle;
}
.input--label--id:before {
  content: url("/images/ic_user_01.png");
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}
.input--label--pass {
  position: relative;
  padding-left: 2em;
}
.input--label--pass--login {
  display: none;
}
.input--label--pass--login:before {
  content: url("/images/ic_pw_01.png");
  position: relative;
  left: 0;
  top: 0;
  z-index: 1;
  vertical-align: middle;
}
.input--label--pass:before {
  content: url("/images/ic_pw_01.png");
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}
.input--checkbox {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("/images/ic_checkbox.png") no-repeat;
  display: inline-block;
  height: 14px;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  width: 14px;
}
.input--checkbox:checked {
  background: url("/images/ic_checkbox_c.png") no-repeat;
}
.input--submit {
  display: block;
  width: 342px;
  margin: 35px auto 0;
  border: 0;
  background: #f08200;
  border: solid 1px #f08200;
  border-radius: 0;
  font-size: 16px;
  color: #fff;
  text-align: center;
  line-height: 1.5;
  letter-spacing: 3px;
  cursor: pointer;
  border-radius: 6px;
  transition: all ease 0.3s;
}
.input--submit:hover {
  background: rgba(253,148,25,0.91);
}
.inputflatpickr__mutlcol {
  float: left;
  width: 11em;
}
.react-input-flatpickr-Box {
  width: 140px;
  position: relative;
}
.main__filter__item .react-input-flatpickr-Box {
  float: left;
}
.inputflatpickr__mutlcol > .react-input-flatpickr-Box {
  width: 100% !important;
}
.member-edit.member-edit .react-input-flatpickr-Box {
  float: left;
  width: 180px !important;
}
.member-edit__preview .main__filter__item .react-input-flatpickr-Box {
  width: 8em !important;
}
.layouter .react-input-flatpickr-Box {
  width: 100% !important;
}
.tree__modal--save__container .react-input-flatpickr-Box {
  width: 220px !important;
}
.tree__modal--save__userplace .react-input-flatpickr-Box {
  width: 110px !important;
}
.react-input-flatpickr-Input {
  border: solid 1px var(--color-border);
  background-color: #fff;
  padding: 6px 5px 5px;
  width: calc(100% - 25px);
  margin-right: -20px;
  color: var(--color-font-default);
  font-size: 13px;
}
.react-input-flatpickr-Input:focus {
  border-color: #f08200;
}
.react-input-flatpickr-Input--w60 {
  width: 60px;
}
.react-input-flatpickr-Input--w100 {
  width: 100px;
  height: 2.5em;
}
.react-input-flatpickr-Input--w100-ml10 {
  width: 100px;
  height: 2.5em;
  margin-left: 10px;
}
.react-input-flatpickr-Input--w400 {
  width: 400px;
}
.react-input-flatpickr-Input--wide {
  width: 100%;
}
.react-input-flatpickr-Input:disabled {
  background-color: #ddd;
}
.react-input-flatpickr-Input:not([value=""])::-webkit-input-placeholder {
  color: transparent;
}
.react-input-flatpickr-Button {
  position: absolute;
  right: 0;
  top: 0;
  border: solid 1px var(--color-border);
  font-size: 13px;
  margin-left: -1px;
  background: #eee;
  transition: 0.2s;
  cursor: pointer;
}
.react-input-flatpickr-Button:hover {
  background: #ccc;
}
.react-input-flatpickr-Button {
  display: inline-block;
  font-size: 1em;
  padding: 4.5px;
}
@media all and (-ms-high-contrast: none) {
  .flatpickr-day {
    flex-basis: 13.28% !important;
  }
}
.form .react-input-flatpickr-Box {
  width: 270px;
}
.form__text {
  border: solid 1px var(--color-border);
  background-color: #fff;
  padding: 6px 5px 5px;
  width: 270px;
  color: var(--color-font-default);
  font-size: 13px;
}
.form__text:focus {
  border-color: #f08200;
}
.form__text--w60 {
  width: 60px;
}
.form__text--w100 {
  width: 100px;
  height: 2.5em;
}
.form__text--w100-ml10 {
  width: 100px;
  height: 2.5em;
  margin-left: 10px;
}
.form__text--w400 {
  width: 400px;
}
.form__text--wide {
  width: 100%;
}
.form__text:disabled {
  background-color: #ddd;
}
.form__text:not([value=""])::-webkit-input-placeholder {
  color: transparent;
}
.form__text::placeholder {
  color: var(--color-font-default);
  opacity: 0.5;
}
.form__text--fz14 {
  font-size: 14px;
}
.form__textarea {
  border: solid 1px var(--color-border);
  background-color: #fff;
  padding: 6px 5px 5px;
  width: 540px;
  min-height: 140px;
  color: var(--color-font-default);
  font-size: 13px;
}
.form__textarea:focus {
  border-color: #f08200;
}
.form__textarea--w100 {
  width: 100px;
}
.form__textarea--w270 {
  width: 270px;
}
.form__textarea--w400 {
  width: 400px;
}
.form__textarea--w430 {
  width: 430px;
}
.form__textarea:disabled {
  background-color: #ddd;
}
.form__radio__label {
  white-space: normal !important;
  word-break: break-all;
  display: inline-block;
  margin-right: 20px;
  padding: 5px 0 5px 20px;
  background: url("/images/form_radio_off.png") no-repeat left center;
  cursor: pointer;
}
.form__radio__label--vertical {
  display: block;
}
.form__radio__label--vertical2 {
  width: 145px;
  margin-right: 0;
}
.form__radio__label--notext {
  display: inline-block;
  width: 14px;
  height: 14px;
  padding: 0;
  margin: 0;
}
.form__radio__label.data__edit {
  background-image: url("/images/ic_controll.png");
  width: 16px;
  height: 16px;
}
.form__radio__label--checked {
  background-image: url("/images/form_radio_on.png");
}
.form__radio__label--checked.data__edit {
  background-image: url("/images/ic_controll.png");
}
.form__radio__label--disabled {
  cursor: default;
}
.form__radio {
  display: none;
}
.form__checkbox__label {
  white-space: normal !important;
  word-break: break-all;
  display: inline-block;
  margin-right: 20px;
  padding: 5px 0 5px 20px;
  background: url("/images/form_check_off.png") no-repeat left center;
  cursor: pointer;
}
.form__checkbox__label--vertical {
  display: block;
}
.form__checkbox__label--vertical2 {
  width: 10em;
  margin: 10px 0;
}
.form__checkbox__label--vertical3 {
  width: 180px;
  margin-right: 0;
}
.form__checkbox__label--notext {
  display: inline-block;
  width: 14px;
  height: 14px;
  padding: 0;
  margin: 0;
}
.form__checkbox__label--checked {
  background-image: url("/images/form_check_on.png");
}
.form__checkbox__label--disabled {
  cursor: default;
}
.form__checkbox {
  display: none;
}
.form__select {
  border: solid 1px var(--color-border);
  background: #fff url("/images/form_select.png") no-repeat 95% center;
  padding: 6px 5px 5px;
  width: 270px;
  color: var(--color-font-default);
  font-size: 13px;
}
.form__select--multi {
  background-image: none;
}
.form__select:focus {
  border-color: #f08200;
}
.form__select::-ms-expand {
  display: none;
}
.form__select--short {
  width: 100px;
  margin: 0 10px;
}
.form__select--w80 {
  width: 80px;
}
.form__select--w120 {
  width: 120px;
}
.form__select--w190 {
  width: 190px;
}
.form__select--w240 {
  width: 240px;
}
.form__select--w320 {
  width: 320px;
}
.form__select--w100p {
  width: 100%;
}
.form__select--auto {
  width: auto;
  height: 2.5em;
  padding-right: 1.5em;
  margin-left: 10px;
}
.form__select--withpull {
  color: var(--color-font-default);
  background: #fff url("/images/form_select.png") no-repeat 98% center;
}
.form__select option:checked {
  color: var(--color-font-default);
}
.form__select option:focus {
  color: var(--color-font-default);
}
.form__select + .form__select {
  margin-left: 10px;
}
.form__select--h30 {
  line-height: 16px;
}
.form__file {
  display: none;
}
.form__file__btn {
  display: inline-block;
  cursor: pointer;
  vertical-align: bottom;
}
.form__file__btn--disabled {
  cursor: default !important;
}
.form__file__btn--disabled:hover {
  opacity: 1 !important;
}
.form__file__image {
  vertical-align: bottom;
  width: calc(100% - 100px);
  margin-right: 10px;
}
.form__file__name {
  display: inline-block;
  padding-left: 10px;
}
.form__file__name--import {
  width: 280px;
  margin-top: 5px;
  color: #a9a9a9;
  line-height: 1.3;
}
.form__file__name__link {
  text-decoration: underline;
}
.form__file__name__link:hover {
  text-decoration: none;
}
.form__file__preview {
  display: inline-block;
  position: relative;
  z-index: 1;
}
.form__file__preview img {
  max-width: 150px;
  width: auto;
  height: auto;
}
.form__file__preview .deselect__btn {
  display: block;
  width: 32px;
  height: 32px;
  background: url("/images/ic_del2.png") no-repeat center center;
  background-size: contain;
  position: absolute;
  top: -12px;
  right: -12px;
  cursor: pointer;
  z-index: 10;
}
.form__file__preview.deletable:after {
  content: "";
  display: block;
  width: 32px;
  height: 32px;
  background: url("/images/ic_del2.png") no-repeat center center;
  background-size: contain;
  position: absolute;
  top: -12px;
  right: -12px;
  cursor: pointer;
  z-index: 10;
}
.form__file__output__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-left: -5px;
}
.form__file__output__list li {
  width: 110px;
  padding: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.form__file__output__list li img {
  width: 100%;
  height: auto;
}
.form__button {
  border: 0;
  background: none;
  padding: 0;
}
.form__button--cancel {
  position: absolute;
  top: 50px;
  right: 51px;
}
.form__toggle--note {
  display: inline-block;
  color: #84bafd;
  cursor: pointer;
  font-size: 13px;
  line-height: 1.4;
}
.form__toggle--note::after {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border-top: 2px solid #999;
  border-right: 2px solid #999;
  transform: translateY(-2px) rotate(135deg);
  margin-left: 6px;
}
.form__toggle--open::after {
  transform: translateY(2px) rotate(-45deg);
}
.form--note {
  font-size: 13px;
  line-height: 1.4;
  color: #999;
  padding: 5px 0;
}
@media screen and (max-width: 1050px) {
  input[data-login].input--middle {
    width: 85%;
    height: initial;
  }
  input[data-sso-login].sso-input--middle {
    width: 85%;
    height: initial;
  }
  .login__form input {
    padding: 9px calc(7px + 1vmin);
  }
  .login__form .error {
    display: none;
  }
  .login__form .js-error input {
    border: solid 1px #d80c0c;
    background: #fff7f7;
  }
  .login__form .js-error .error {
    display: block;
  }
  .login__form .input--middle {
    width: 100%;
  }
  .login__form .input--middle::placeholder {
    padding-left: 0;
  }
  .login__form .input--label {
    display: inline-block;
    margin: 15px auto;
  }
  .login__form .input--label span {
    padding-left: 1vmin;
    font-size: 13px;
    color: var(--color-font-default);
  }
  .login__form .input--label--co {
    position: relative;
    padding-left: 2em;
  }
  .login__form .input--label--co--login {
    display: unset;
  }
  .login__form .input--label--co:before {
    content: url("/images/ic_company_01.png");
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
  }
  .login__form .input--label--id {
    position: relative;
    padding-left: 2em;
  }
  .login__form .input--label--id--login {
    display: unset;
  }
  .login__form .input--label--id:before {
    content: url("/images/ic_user_01.png");
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
  }
  .login__form .input--label--pass {
    position: relative;
    padding-left: 2em;
  }
  .login__form .input--label--pass--login {
    display: unset;
  }
  .login__form .input--label--pass:before {
    content: url("/images/ic_pw_01.png");
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
  }
  .login__form .input--checkbox {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url("/images/ic_checkbox.png") no-repeat;
    display: inline-block;
    height: 14px;
    margin: 0;
    padding: 0;
    vertical-align: middle;
    width: 14px;
  }
  .login__form .input--checkbox:checked {
    background: url("/images/ic_checkbox_c.png") no-repeat;
  }
  .login__form .input--submit {
    display: block;
    width: 78%;
    margin: 15px auto 0;
    border: 0;
    background: #f08200;
    border-radius: 3px;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    line-height: 2;
  }
  .login__form .sso-input--middle {
    width: 100%;
  }
  .login__form .sso-input--middle::placeholder {
    padding-left: 0;
  }
  .login__form .sso-input--submit {
    display: block;
    width: 80%;
    border: 0;
    background: #f08200;
    border-radius: 3px;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
    text-align: center;
    line-height: 2;
  }
}
.member-edit__preview .main__filter__item {
  padding: 0 !important;
}
.member-edit__preview .input-calendar {
  width: 75%;
  float: left;
}
.form__date {
  border: solid 1px var(--color-border);
  background-color: #fff;
  padding: 6px 5px 5px;
  width: 270px;
  color: var(--color-font-default);
  font-size: 13px;
}
.form__date--w190 {
  width: 190px;
}
.form__date:focus {
  border-color: #f08200;
}
.input-calendar {
  display: inline-block;
  position: relative;
}
.input-calendar-wrapper {
  white-space: normal !important;
  position: absolute;
  padding: 5px 12px;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  box-shadow: 0 0 9px 5px rgba(0,0,0,0.05);
  background-color: #fff;
  text-align: center;
  left: 140px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 20;
}
.input-calendar-wrapper--calendaronly .input-calendar {
  display: inline-block !important;
  position: relative;
}
.input-calendar .input-calendar-field {
  width: 120px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: 'Pontano Sans', sans-serif;
  font-size: 16px;
  padding: 2px 8px;
}
.input-calendar .input-calendar-field:focus {
  outline: none;
  border: 1px solid #f08200;
}
.input-calendar .calendar-icon {
  cursor: pointer;
  width: 36px;
  height: 0;
  overflow: hidden;
  padding-top: 29px;
  background: var(--color-border) url("/images/ic_calendar.png") no-repeat center center;
  color: #fff;
  position: absolute;
  right: 0;
  top: 0;
}
.input-calendar .days,
.input-calendar .months,
.input-calendar .years {
  width: 189px;
  padding-bottom: 2px;
}
.input-calendar .cell {
  display: inline-block;
  text-align: center;
  cursor: pointer;
  border: 1px solid #fff;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.input-calendar .cell:hover {
  color: #f08200;
  border: 1px solid #f08200;
  border-radius: 4px;
}
.input-calendar .cell.current {
  background: #f08200;
  color: #fff;
  border-radius: 4px;
  opacity: 0.8;
}
.input-calendar .day {
  width: 25px;
  height: 25px;
  line-height: 25px;
}
.input-calendar .day.prev,
.input-calendar .day.next {
  opacity: 0.4;
}
.input-calendar .month {
  width: 58px;
  height: 38px;
  line-height: 38px;
}
.input-calendar .year {
  width: 58px;
  height: 38px;
  line-height: 38px;
}
.input-calendar .days-title .cell {
  height: 25px;
  line-height: 28px;
  opacity: 0.5;
  cursor: default;
}
.input-calendar .days-title .cell:hover {
  color: var(--color-font-default);
  border: 1px solid #fff;
}
.input-calendar .navigation-title {
  width: 100px;
  display: inline-block;
  cursor: pointer;
}
.input-calendar .years-view .navigation-title {
  cursor: default;
}
.input-calendar .years-view .navigation-title:hover {
  color: var(--color-font-default);
}
.input-calendar .navigation-title:hover {
  color: #f08200;
}
.input-calendar .icon-wrapper {
  cursor: pointer;
}
.input-calendar .icon:hover,
.input-calendar .today-btn:hover,
.input-calendar .icon-wrapper:hover {
  color: #f08200;
}
.input-calendar .icon {
  cursor: pointer;
  width: 20px;
}
.input-calendar .today-btn {
  cursor: pointer;
}
.input-calendar .cell.day.today {
  position: relative;
}
.input-calendar .cell.day.today:after {
  content: '.';
  position: absolute;
  bottom: 15px;
  font-size: 20px;
  color: #f08200;
}
.fromToCalendar .input-calendar {
  width: 124px;
}
.fromToCalendar .input-calendar .form__date {
  width: 100%;
}
.fromToCalendar .input-calendar-wrapper {
  left: 50px;
}
.fromToCalendar--wide .input-calendar {
  width: 200px;
  margin: 0 10px;
}
.main__filter {
  margin-top: 10px;
  background: #fff;
  box-shadow: 0 1px 0 #dfdfdf;
}
.main__filter__item {
  display: inline-block;
  padding: 10px 15px;
}
.main__filter__item:first-child {
  padding-left: 20px;
}
.main__filter__item .form__date {
  width: auto;
}
.main__filter__item .input-calendar-wrapper {
  z-index: 10;
}
.main__filter__button {
  display: inline-block;
  font-size: 13px;
}
.main__filter__button a {
  cursor: pointer;
}
.main__filter__button a:hover {
  text-decoration: underline;
}
.main__filter__save {
  display: inline-block;
}
.clearSearch {
  margin-top: 20px;
  font-size: 13px;
  text-align: center;
}
.buttons {
  width: 100%;
  overflow: hidden;
  padding-top: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.buttons--words {
  justify-content: flex-start;
  padding-top: 0;
  margin-bottom: 20px;
  margin-left: -3px;
}
.buttons--pl270 {
  justify-content: flex-start;
  padding-left: 270px;
  margin-top: 30px;
}
.buttons--pl270 .form__checkbox__label {
  display: block;
  margin-bottom: 13px;
  margin-left: 10px;
}
.buttons--pt0 {
  padding-top: 0;
}
.button {
  margin: 0 9px;
  border-radius: 2px;
  background-color: #f08200;
  padding: 12px 20px 10px;
  letter-spacing: normal;
  color: #fff;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
}
.button--wre {
  width: 150px;
}
.button--w120 {
  width: 120px;
}
.button--w150 {
  width: 150px;
}
.button--w200 {
  width: 200px;
}
.button--cancel,
.button--gray {
  background-color: #999;
}
.button--lightgray {
  background-color: #dfdfdf;
  color: #666;
}
.button--disable {
  background-color: #f4f4f4;
  color: #dfdfdf;
  pointer-events: none;
}
.button--file {
  margin: 0;
  width: 90px;
  background-color: #dfdfdf;
  padding: 8px 0 6px;
  color: #666;
}
.button--word {
  margin: 0 3px;
  width: 34px;
  padding: 12px 0 10px;
  border-radius: 0;
  color: #999;
  background-color: #f4f4f4;
}
.button--word:hover,
.button--word.active {
  background-color: #f08200;
  color: #fff;
}
.button--small {
  padding: 5px 12px;
  font-size: 12px;
}
.button--heightlarge {
  padding-top: 21px;
  padding-bottom: 19px;
}
.button--organization {
  padding: 9px 20px 8px;
}
.button--mx-0 {
  margin-left: 0;
  margin-right: 0;
}
.button--noBorder {
  border: none;
}
.button__info {
  background: #999;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 10px;
  width: 40px;
  height: 40px;
  border-radius: 2px;
  cursor: pointer;
}
.button__info.is-active {
  width: 100%;
  height: auto;
}
.button__info.is-active .button__info__txt {
  display: inline;
}
.button__info__txt {
  margin-left: 20px;
  display: none;
  line-height: 1.4;
}
.button__info__ic {
  display: inline-block;
  width: 18px;
  height: 18px;
  background-image: url("/images/ic_info.png");
}
.button:hover {
  opacity: 0.7;
  transition: 0.5s;
}
.button--calendar {
  display: inline-block;
  margin: 0;
  overflow: hidden;
  margin-bottom: 10px;
  margin-left: 2px;
  padding: 5px 4px 4px;
}
.button__subtitle {
  font-size: 12px;
  display: block;
}
.buttonIcon {
  display: inline-block;
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  cursor: pointer;
}
.formTable .buttonIcon {
  position: relative;
  top: 6px;
  left: 5px;
  display: inline-block;
  width: 25px;
  height: 25px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  cursor: pointer;
}
.formTable .buttonIcon--add + .buttonIcon {
  margin-left: 3px;
}
.formTable .buttonIcon--resetPosition {
  top: 0;
  left: 0;
}
.buttonIcon--small {
  width: 20px;
  height: 20px;
}
.buttonIcon--add {
  background-image: url("/images/ic_add2.png");
}
.buttonIcon--add + .buttonIcon {
  margin-left: 3px;
}
.buttonIcon__tab--add {
  width: 12px;
  height: 12px;
  background-image: url("/images/ic_add3.png");
}
.buttonIcon--del {
  background-image: url("/images/ic_del2.png");
}
.buttonIcon--del--light {
  background-image: url("/images/ic_del2.png");
}
.buttonIcon--del--popup,
.buttonIcon--del--calender {
  background-image: url("/images/ic_del2.png");
  position: absolute;
  top: 16px;
  right: 16px;
  left: auto;
}
.btn__sort {
  display: inline-block;
  width: 15px;
  height: 12px;
  margin: 0 3px;
  vertical-align: bottom;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}
.btn__sort--desc {
  background-image: url("/images/ic_arrow_down.png");
}
.btn__sort--desc-current {
  background-image: url("/images/ic_arrow_down_current.png");
}
.btn__sort--asc {
  background-image: url("/images/ic_arrow_up.png");
}
.btn__sort--asc-current {
  background-image: url("/images/ic_arrow_up_current.png");
}
@media screen and (max-width: 1050px) {
  .buttons {
    padding-top: 0;
  }
  .button {
    font-size: 5vmin;
    margin: 0 5vmin;
  }
  .button--wre {
    width: auto;
    padding: 3vmin 8vmin;
  }
}
.modTable {
  white-space: nowrap;
}
.modTable--relative {
  position: relative;
  width: 90%;
  overflow: scroll;
}
.modTable__thead--fixed {
  position: absolute;
  top: 0;
  left: 0;
}
.modTable__tbody--fixed {
  display: block;
}
.modTable--align-center {
  margin-left: auto;
  margin-right: auto;
}
.modTable--full {
  width: 100%;
}
.modTable--w500 {
  width: 500px;
}
.modTable__th,
.modTable__td {
  background-color: #fff;
  padding: 14px 20px;
  color: var(--color-font-default);
  font-size: 11px;
}
.modTable__th {
  border-top: solid 1px var(--color-border);
}
.modTable__th:first-child {
  border-left: solid 1px var(--color-border);
}
.modTable__th:last-child {
  border-right: solid 1px var(--color-border);
}
.modTable__td {
  border: solid 1px var(--color-border);
  text-align: center;
}
.modTable__td--link {
  color: #49b0be;
  cursor: pointer;
}
.modTable--th-fixed,
.modTable--3col {
  border: solid 1px var(--color-border);
  border-bottom: 0;
}
.modTable--th-fixed .modTable__tr:nth-child(even) .modTable__th,
.modTable--3col .modTable__tr:nth-child(even) .modTable__th,
.modTable--th-fixed .modTable__tr:nth-child(even) .modTable__td,
.modTable--3col .modTable__tr:nth-child(even) .modTable__td {
  background: #f9fcfd;
}
.modTable--th-fixed .modTable__th,
.modTable--3col .modTable__th {
  width: 89px;
  border: 0;
}
.modTable--th-fixed .modTable__th--w150,
.modTable--3col .modTable__th--w150 {
  width: 150px;
}
.modTable--th-fixed .modTable__tr:last-child .modTable__th,
.modTable--3col .modTable__tr:last-child .modTable__th {
  border-bottom: solid 1px var(--color-border);
}
.modTable--th-fixed .modTable__td,
.modTable--3col .modTable__td {
  border: 0;
  border-bottom: solid 1px var(--color-border);
  border-left: solid 1px var(--color-border);
  text-align: left;
  padding: 20px;
  line-height: 1.6;
}
.modTable--3col .modTable__td {
  padding: 10px 20px;
  border-left: 0;
}
.modTable--timeline .modTable__th,
.modTable--timeline .modTable__td {
  padding: 14px 30px;
}
.modTable--timeline .modTable__tr:nth-child(even) .modTable__th,
.modTable--timeline .modTable__tr:nth-child(even) .modTable__td {
  background: #f9fcfd;
}
.modTable__list__item {
  padding: 3px 0;
}
.modTable__list--square__item {
  list-style-type: square;
  margin-left: 1.2em;
}
.modTable--2tone .modTable__tr:nth-child(even) .modTable__th,
.modTable--2tone .modTable__tr:nth-child(even) .modTable__td {
  background: #f9fcfd;
}
.modTable--borderless .modTable__th,
.modTable--borderless .modTable__td {
  border: 0;
}
.modList__item {
  padding: 8px 0;
}
.modList__item--company {
  color: #49b0be;
  text-decoration: underline;
  cursor: pointer;
  display: inline-block;
}
.modList__item--company:hover {
  text-decoration: none;
}
.modList--square__item {
  padding: 15px 0;
  list-style-type: square;
  margin-left: 1.2em;
}
.modList__option {
  margin-left: 3.6em;
}
.modBoxBorder {
  border: solid 1px var(--color-border);
  padding: 20px;
}
.modBoxBorder--vertical:not(:first-child) {
  border-top: none;
}
.toggle {
  display: none;
}
.toggle + .toggle__btn {
  outline: 0;
  display: block;
  height: 38px;
  position: relative;
  cursor: pointer;
  user-select: none;
}
.toggle + .toggle__btn:after,
.toggle + .toggle__btn:before {
  position: relative;
  display: block;
  content: "";
  height: 100%;
  background: #fff;
}
.toggle + .toggle__btn:after {
  right: 0;
}
.toggle + .toggle__btn:before {
  display: none;
}
.toggle--check01 + .toggle__btn {
  background: #999;
  border-radius: 2em;
  border: 1px solid #999;
  padding: 2px;
  transition: all 0.4s ease;
  width: 70px;
  display: inline-block;
  vertical-align: middle;
  margin: 0 5px;
}
.toggle--check01 + .toggle__btn:after {
  width: 50%;
  border-radius: 50%;
  background: #fff;
  transition: all 0.2s ease;
  left: 0;
}
.toggle--check01:checked + .toggle__btn:after {
  left: 50%;
}
.toggle--name__before,
.toggle--name__after {
  display: inline-block;
  vertical-align: middle;
}
.toggle--name__before--list,
.toggle--name__after--list,
.toggle--name__before--block,
.toggle--name__after--block {
  position: relative;
  display: inline-block;
  top: 8px;
  width: 28px;
  height: 28px;
}
.toggle--name__before--list,
.toggle--name__after--list {
  background: url("/images/ic_list.png") no-repeat;
}
.toggle--name__before--block,
.toggle--name__after--block {
  background: url("/images/ic_block.png") no-repeat;
}
.toggle--name__before--self,
.toggle--name__after--self,
.toggle--name__before--others,
.toggle--name__after--others {
  position: relative;
  display: inline-block;
  top: 14px;
  width: 22px;
  height: 40px;
}
.toggle--name__before--self,
.toggle--name__after--self {
  background: url("/images/ic_self.png") no-repeat center bottom;
}
.toggle--name__before--others,
.toggle--name__after--others {
  background: url("/images/ic_others.png") no-repeat center bottom;
}
.toggle--check02 + .toggle__btn {
  backface-visibility: hidden;
  font-family: sans-serif;
  border: 1px solid #dfdfdf;
  border-radius: 1px;
  width: 160px;
}
.toggle--check02 + .toggle__btn:after,
.toggle--check02 + .toggle__btn:before {
  display: inline-block;
  width: 80px;
  text-align: center;
  position: absolute;
  top: 0;
  line-height: 38px;
}
.toggle--check02 + .toggle__btn:before {
  left: 0;
  content: attr(data-tg-off);
  color: #fff;
  background: #999;
  border-top-left-radius: 1px;
  border-bottom-left-radius: 1px;
  z-index: 10;
  -webkit-font-smoothing: subpixel-antialiased;
}
.toggle--check02 + .toggle__btn:after {
  right: 0;
  color: var(--color-font-default);
  content: attr(data-tg-on);
  background: #fff;
  border-top-right-radius: 1px;
  border-bottom-right-radius: 1px;
  z-index: 0;
  -webkit-font-smoothing: antialiased;
}
.toggle--check02:checked + .toggle__btn:before {
  color: var(--color-font-default);
  background: #fff;
  z-index: 0;
  -webkit-font-smoothing: antialiased;
}
.toggle--check02:checked + .toggle__btn:after {
  color: #fff;
  background: #999;
  z-index: 10;
  -webkit-font-smoothing: subpixel-antialiased;
}
.toggle--check02 + .toggle__btn--reverse:before {
  left: auto;
  right: 0;
}
.toggle--check02 + .toggle__btn--reverse:after {
  left: 0;
  right: auto;
}
.toggle__item {
  display: inline-block;
  vertical-align: middle;
}
.toggle__item + .toggle__item {
  margin-left: 15px;
}
.toggle--check03 + .toggle__btn {
  background: #fff;
  border-radius: 2em;
  border: 3px solid #999;
  padding: 2px;
  transition: all 0.4s ease;
  width: 70px;
  display: inline-block;
  vertical-align: middle;
  margin: 0 5px;
  height: 36px;
}
.toggle--check03 + .toggle__btn:after {
  width: 46%;
  border-radius: 50%;
  background: #999;
  transition: all 0.2s ease;
  left: 0;
}
.toggle--check03:checked + .toggle__btn:after {
  left: 50%;
}
.toggle--check04 + .toggle__btn {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  background-color: #ccc;
  border-radius: 100vh;
  transition: all 0.4s;
}
.toggle--check04 + .toggle__btn:before {
  display: block;
  position: absolute;
  content: '';
  width: 43.33%;
  height: 0;
  top: 0;
  bottom: 0;
  left: 6.66%;
  padding-top: 43.33%;
  box-sizing: content-box;
  border-radius: 50%;
  margin: auto;
  transition: all 0.4s;
}
.toggle--check04 + .toggle__btn:after {
  display: block;
  position: absolute;
  content: 'OFF';
  color: #fff;
  font-size: 0.7em;
  text-align: center;
  line-height: 1em;
  width: 50%;
  height: 1em;
  top: 0;
  bottom: 0;
  right: 0;
  background: none;
  margin: auto;
}
.toggle--check04:checked + .toggle__btn {
  background-color: #f08200;
}
.toggle--check04:checked + .toggle__btn:before {
  left: 50.01%;
}
.toggle--check04:checked + .toggle__btn:after {
  content: 'ON';
  right: 50%;
}
.toggle--check05 + .toggle__btn {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
  background-color: #ccc;
  border-radius: 100vh;
  transition: all 0.4s;
  margin-left: 10px;
  margin-right: 10px;
}
.toggle--check05 + .toggle__btn:before {
  display: block;
  position: absolute;
  width: 43.33%;
  height: 0;
  top: 0;
  bottom: 0;
  left: 6.66%;
  padding-top: 43.33%;
  box-sizing: content-box;
  border-radius: 50%;
  margin: auto;
  transition: all 0.4s;
}
.toggle--check05 + .toggle__btn:after {
  display: block;
  position: absolute;
  color: #fff;
  font-size: 0.7em;
  text-align: center;
  line-height: 1em;
  width: 50%;
  height: 1em;
  top: 0;
  bottom: 0;
  right: 0;
  background: none;
  margin: auto;
}
.toggle--check05:checked + .toggle__btn {
  background-color: #f08200;
}
.toggle--check05:checked + .toggle__btn:before {
  left: 50.01%;
}
.toggle--check05:checked + .toggle__btn:after {
  right: 50%;
}
.listBox {
  width: 700px;
  display: flex;
}
.listBox__box {
  width: 270px;
}
.listBox__box--w400 {
  width: 400px;
}
.listBox__controller {
  padding: 0 30px;
}
.listBox__label {
  display: block;
  text-align: center;
  padding-bottom: 0.3em;
}
.listBox__select {
  width: 100%;
  height: 100%;
  border: 1px solid var(--color-border);
  border-radius: 3px;
  padding: 5px 0;
  overflow-y: scroll;
}
.listBox__select option {
  color: var(--color-font-default);
}
.listBox__option {
  font-size: 13px;
  padding: 2px 10px;
  color: var(--color-font-default);
  text-shadow: none;
}
.listBox__option[disabled] {
  color: var(--color-font-gray-02);
}
.listBox__button {
  display: block;
  cursor: pointer;
  margin-top: 5px;
}
.listBox__button:first-child {
  margin-top: 0;
}
.listBox__button[name="up"] {
  margin-top: 30px;
}
.listBox__row {
  display: flex;
  flex-wrap: wrap;
}
.listBox__row--alignCenter {
  align-items: center;
}
.layouter .form__text,
.layouter .form__textarea,
.layouter .form__select,
.layouter .form__date {
  width: 100%;
}
.layouter .form__text:disabled,
.layouter .form__textarea:disabled,
.layouter .form__select:disabled,
.layouter .form__date:disabled {
  background-color: #fff;
}
.layouter .form__text--number {
  text-align: right;
}
.layouter .form__text--currency {
  width: 80%;
  text-align: right;
}
.layouter .val__currency {
  width: 20%;
  padding: 0 1.5%;
  display: inline-block;
  text-align: center;
}
.layouter .text__currency {
  width: 80%;
  display: inline-block;
}
.layouter .form__textarea {
  height: auto;
  min-height: auto;
  resize: vertical;
}
.layouter .input-calendar {
  display: block;
}
.layouter .input-calendar-wrapper {
  z-index: 11;
}
.layouter .buttons {
  padding-top: 20px;
}
.layouter :disabled {
  cursor: not-allowed;
}
.main__contents--layouter {
  background-color: #f4f4f4;
  border: none;
}
.layouter__clear-fix:before,
.layouter__clearfix:before,
.layouter__toolbox__group:before,
.layouter .ReactTabs__TabList:before,
.layouter__popover__content:before,
.layouter__clear-fix:after,
.layouter__clearfix:after,
.layouter__toolbox__group:after,
.layouter .ReactTabs__TabList:after,
.layouter__popover__content:after {
  content: " ";
  display: table;
}
.layouter__clear-fix:after,
.layouter__clearfix:after,
.layouter__toolbox__group:after,
.layouter .ReactTabs__TabList:after,
.layouter__popover__content:after {
  clear: both;
}
.layouter__text--left {
  text-align: left;
}
.layouter__text--center {
  text-align: center;
}
.layouter__text--right {
  text-align: right;
}
.layouter__text--small {
  font-size: 10px;
}
.layouter__text--medium {
  font-size: 13px;
}
.layouter__text--large {
  font-size: 16px;
}
.layouter__text--bold {
  font-weight: bold;
}
.layouter__icon {
  display: block;
  background-repeat: no-repeat;
  background-position: center;
}
.layouter__icon--trash {
  background-image: url("/images/ic_layouter_trash.png");
  width: 15px;
  height: 15px;
}
.layouter__icon--ban {
  background-image: url("/images/ic_layouter_trash_unremovable.png");
  width: 15px;
  height: 15px;
}
.layouter__icon--times-circle {
  background-image: url("/images/ic_del3.png");
  width: 20px;
  height: 20px;
}
.layouter__icon--cog {
  background-image: url("/images/ic_tools.png");
  width: 22px;
  height: 22px;
}
.layouter__icon--history {
  background: url("/images/ic_layouter_history.png");
  width: 52px;
  height: 32px;
}
.layouter__toolbox {
  position: absolute;
  width: 202px;
  background-color: #fff;
  border: 1px solid #afafaf;
  border-radius: 5px;
  z-index: 9;
}
.layouter__toolbox__header {
  height: 30px;
  padding: 4px;
  background-color: #ff8d02;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  cursor: move;
}
.layouter__toolbox__collapse {
  float: right;
  cursor: pointer;
}
.layouter__toolbox__body {
  margin: -1px;
}
.layouter__toolbox__group {
  padding-left: 10px;
  padding-top: 10px;
  border-top: 1px solid var(--color-border);
}
.layouter__toolbox__group:first-child {
  border-top: none;
}
.layouter__toolbox__item {
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
}
.layouter__toolbox--collapsed {
  width: 32px;
}
.layouter__toolbox--collapsed .layouter__toolbox__header {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.layouter__toolbox--collapsed .layouter__toolbox__body {
  display: none;
}
.layouter__toolbox__item {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 2px;
  width: 86px;
  height: 36px;
  line-height: 36px;
  cursor: pointer;
}
.layouter__toolbox__item:active {
  box-shadow: 1px 1px 2px 0 #999;
}
.layouter__toolbox__item--number {
  background-image: url("/images/ic_layouter_number.png");
}
.layouter__toolbox__item--link {
  background-image: url("/images/ic_layouter_link.png");
}
.layouter__toolbox__item--date {
  background-image: url("/images/ic_layouter_date.png");
}
.layouter__toolbox__item--birthday {
  background-image: url("/images/ic_layouter_birthday.png");
}
.layouter__toolbox__item--text {
  background-image: url("/images/ic_layouter_text.png");
}
.layouter__toolbox__item--textarea {
  background-image: url("/images/ic_layouter_textarea.png");
}
.layouter__toolbox__item--select {
  background-image: url("/images/ic_layouter_select.png");
}
.layouter__toolbox__item--upload {
  background-image: url("/images/ic_layouter_upload.png");
}
.layouter__toolbox__item--currency {
  background-image: url("/images/ic_layouter_currency.png");
}
.layouter__toolbox__item--mail {
  background-image: url("/images/ic_layouter_mail.png");
}
.layouter__toolbox__item--tel {
  background-image: url("/images/ic_layouter_tel.png");
}
.layouter__toolbox__item--autodate {
  background-image: url("/images/ic_layouter_autodate.png");
}
.layouter__toolbox__item--calc {
  background-image: url("/images/ic_layouter_calc.png");
}
.layouter__toolbox__item--from_to {
  background-image: url("/images/ic_layouter_fromto.png");
}
.layouter__toolbox__item--display_text {
  background-image: url("/images/ic_layouter_display_text.png");
}
.layouter__toolbox__item--display_file {
  background-image: url("/images/ic_layouter_display_file.png");
}
.layouter__toolbox__item--display_link {
  background-image: url("/images/ic_layouter_display_link.png");
}
.layouter__toolbox__item--label {
  background-image: url("/images/ic_layouter_label.png");
}
.layouter__toolbox__item--separator {
  background-image: url("/images/ic_layouter_separator.png");
}
.layouter__toolbox__item--height {
  background-image: url("/images/ic_layouter_height.png");
}
.layouter__toolbox__item__overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  background-color: rgba(0,0,0,0.4);
  font-size: 12px;
  text-align: center;
}
.layouter__toolbox__item__overlay,
.layouter__toolbox__item:active .layouter__toolbox__item__overlay {
  display: none;
}
.layouter__toolbox__item:hover .layouter__toolbox__item__overlay {
  display: block;
}
.layouter__row,
.layouter__field {
  display: flex;
  width: 100%;
}
.layouter__col,
.layouter__label,
.layouter__input {
  display: table-cell;
  vertical-align: middle;
}
.layouter__col--12,
.layouter__label--12,
.layouter__input--12 {
  width: 100%;
}
.layouter__col--11,
.layouter__label--11,
.layouter__input--11 {
  width: 91.66666667%;
}
.layouter__col--10,
.layouter__label--10,
.layouter__input--10 {
  width: 83.33333333%;
}
.layouter__col--9,
.layouter__label--9,
.layouter__input--9 {
  width: 75%;
}
.layouter__col--8,
.layouter__label--8,
.layouter__input--8 {
  width: 66.66666667%;
}
.layouter__col--7,
.layouter__label--7,
.layouter__input--7 {
  width: 58.33333333%;
}
.layouter__col--6,
.layouter__label--6,
.layouter__input--6 {
  width: 50%;
}
.layouter__col--5,
.layouter__label--5,
.layouter__input--5 {
  width: 41.66666667%;
}
.layouter__col--4,
.layouter__label--4,
.layouter__input--4 {
  width: 33.33333333%;
}
.layouter__col--3,
.layouter__label--3,
.layouter__input--3 {
  width: 25%;
}
.layouter__col--2,
.layouter__label--2,
.layouter__input--2 {
  width: 16.66666667%;
}
.layouter__col--1,
.layouter__label--1,
.layouter__input--1 {
  width: 8.33333333%;
}
.layouter .ReactTabs__Tab {
  position: relative;
  bottom: -1px;
  margin-top: 4px;
  margin-right: 4px;
  float: left;
}
.layouter .ReactTabs__TabPanel {
  padding: 20px;
  background: #fff;
  border: 1px solid var(--color-border);
}
.layouter .ReactTabs__TabPanel__borderless {
  padding: 0px;
  background: #fff;
}
.layouter .member-form__panel-group {
  background: #fff;
  border: 1px solid var(--color-border);
  width: 80%;
}
.layouter--wide.member-form .ReactTabs {
  width: 100%;
}
.layouter--wide .ReactTabs {
  width: 100%;
}
.layouter__tab {
  position: relative;
  background: #dfdfdf;
  border: 1px solid var(--color-border);
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  padding-right: 25px;
}
.layouter__tab__label,
.layouter__tab__input {
  font-family: inherit;
  font-size: 14px;
  height: 38px;
  line-height: 38px;
  padding-left: 20px;
  padding-right: 10px;
}
.layouter__tab__label {
  opacity: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.layouter__tab__input {
  position: absolute;
  top: -9999px;
  left: -9999px;
  opacity: 0;
  border: none;
}
.layouter__tab--focus .layouter__tab__label {
  position: absolute;
  top: -9999px;
  left: -9999px;
  opacity: 0;
}
.layouter__tab--focus .layouter__tab__input {
  position: static;
  top: 0;
  left: 0;
  opacity: 1;
}
.layouter__tab__add {
  background: #f08200;
  width: 40px;
  height: 39px;
  line-height: 40px;
  text-align: center;
}
.layouter__tab__remove {
  position: absolute;
  top: 9px;
  right: 9px;
}
.layouter .ReactTabs__Tab--selected .layouter__tab {
  background: #fff;
  border-bottom-color: #fff;
}
.layouter__tab--locked {
  padding-right: 0;
}
.layouter__tab--locked .layouter__tab__label,
.layouter__tab--locked .layouter__tab__input {
  padding-right: 20px;
}
.layouter__panel {
  border-top: 1px solid var(--color-border);
  padding: 19px;
}
.layouter__panel:first-child {
  border-top: none;
}
.layouter__panel__header {
  font-size: 16px;
  margin-bottom: 20px;
}
.layouter__row {
  position: relative;
  border: 1px solid var(--color-border);
  border-top-width: 0;
}
.layouter__row:first-child {
  border-top-width: 1px;
}
.layouter__row__insert-before,
.layouter__row__insert-after {
  left: -19px;
  color: transparent;
  background: url("/images/ic_layouter_add.png") no-repeat;
  width: 53px;
  height: 37px;
  z-index: 12;
}
.layouter__row__insert-before,
.layouter__row__insert-after,
.layouter__row__remove,
.layouter__row__history {
  display: none;
  position: absolute;
  cursor: pointer;
}
.layouter__row__insert-before:hover,
.layouter__row__insert-after:hover,
.layouter__row__remove:hover,
.layouter__row__history:hover {
  display: block;
}
.layouter__row__insert-before {
  top: -18px;
}
.layouter__row__insert-after {
  bottom: -20px;
}
.layouter__row__remove {
  top: 50%;
  left: 50%;
  width: 42px;
  height: 42px;
  margin-top: -21px;
  margin-left: -21px;
  padding: 12px;
  background: #fff;
  border: 1px dashed #c7c7c7;
}
.layouter__tab-panel--empty .layouter__row,
.layouter__panel--empty .layouter__row {
  border-color: transparent;
}
.layouter__row:hover .layouter__row__insert-before,
.layouter__row:hover .layouter__row__insert-after,
.layouter__row:hover .layouter__row__remove,
.layouter__row--popover .layouter__row__insert-before,
.layouter__row--popover .layouter__row__insert-after,
.layouter__tab-panel--empty .layouter__row__insert-before,
.layouter__panel--empty .layouter__row__insert-before {
  display: block;
}
.layouter__col {
  padding: 9px;
}
.layouter__field {
  position: relative;
  border: 1px dashed transparent;
  padding: 9px;
  padding-right: 25px;
  min-height: 55px;
}
.layouter__field--empty {
  background-color: #fbfbfb;
  border-color: #c7c7c7;
}
.layouter__field--empty:hover .layouter__field__history {
  display: none;
}
.layouter__field--empty {
  height: 55px;
}
.layouter__field--error {
  border-left: 4px solid #f00;
  border-right: 4px solid #f00;
}
.layouter__field--error.first {
  border-top: 4px solid #f00;
}
.layouter__field--error.last {
  border-bottom: 4px solid #f00;
}
.layouter__col--locked .layouter__field--empty {
  background-color: transparent;
  border-color: transparent;
}
.layouter__field__remove {
  display: none;
  width: 16px;
  height: 16px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 4px;
  margin-top: -8px;
}
.layouter__field:hover .layouter__field__remove {
  display: block;
}
.layouter__field__history {
  display: none;
  width: 52px;
  height: 32px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: -38px;
  margin-top: -16px;
  z-index: 20;
}
.layouter__field:not(.layouter__field--workflow):hover .layouter__field__history {
  display: block;
}
.layouter__field--check {
  background: #f4f4f4 !important;
}
.layouter__field--required .layouter__label:after {
  content: " (必)";
}
.layouter__label {
  color: var(--color-font-gray-02);
  font-size: 13px;
  word-wrap: break-word;
  margin-right: 0.5rem;
}
.layouter__input {
  width: 100%;
}
.layouter__input--LAYOUTER_SEPARATOR_FIELD hr {
  height: 2px;
  background-color: #808080;
}
.layouter__input option:checked {
  color: var(--color-font-default);
}
.layouter__popover {
  display: none;
  position: absolute;
  top: 0;
  left: 20px;
  background: #fff;
  border: 1px solid var(--color-border-30);
  box-shadow: 0 0 3px rgba(0,0,0,0.2);
  z-index: 11;
  margin-top: -17px;
}
.layouter__popover--bottom {
  top: 100%;
}
.layouter__popover--in {
  display: block;
}
.layouter__popover__title {
  margin: 0;
  padding: 0 14px;
  color: #a1a1a1;
  font-size: 13px;
  line-height: 30px;
  background-color: #f4f4f4;
}
.layouter__popover__content {
  padding: 9px 14px;
}
.layouter__cols {
  width: 200px;
  height: 40px;
  padding: 3px;
  border: 1px solid #c7c7c7;
  margin-top: 8px;
}
.layouter__cols:first-child {
  margin-top: 0;
}
.layouter__cols:hover {
  border-color: #ff8228;
}
.layouter__cols__1,
.layouter__cols__2 {
  display: block;
  position: relative;
  border: 1px dashed #c7c7c7;
  height: 100%;
  background: #f4f4f4;
}
.layouter__cols__1:after,
.layouter__cols__2:after {
  content: "";
  display: block;
  width: 30%;
  height: 2px;
  background-color: #a3a3a3;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -15%;
  margin-top: -1px;
}
.layouter__cols__1 {
  width: 100%;
}
.layouter__cols__2 {
  float: left;
  margin-left: 4px;
  width: 94px;
}
.layouter__cols__2:first-child {
  margin-left: 0;
}
.layouter__modal {
  display: none;
}
.layouter__modal--open {
  display: block;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  overflow: auto;
}
.layouter__modal__dialog {
  position: absolute;
  left: 50%;
  top: 150px;
  width: 740px;
  margin-left: -370px;
  margin-bottom: 100px;
  padding: 40px;
  border-radius: 5px;
  background-color: #fff;
  z-index: 20;
}
.layouter__modal__close {
  position: absolute;
  top: 16px;
  right: 16px;
}
.member-form .ReactTabs {
  width: 80%;
}
.member-form .ReactTabs__TabPanel {
  min-height: 600px;
}
.program-form {
  width: 80%;
  background: #fff;
  border: 1px solid var(--color-border);
}
.member-edit .layouter__field,
.program-edit .layouter__field {
  padding: 9px;
  min-height: auto;
}
.member-edit .layouter__field:hover,
.program-edit .layouter__field:hover,
.member-edit .layouter__field--empty,
.program-edit .layouter__field--empty {
  background-color: transparent;
  border-color: transparent;
}
.member-edit .layouter__tab {
  padding-right: 0;
}
.member-edit .layouter__tab__label {
  padding-right: 20px;
  cursor: pointer;
}
.member-edit__form {
  padding: 20px;
  background-color: #fff;
}
.member-edit--preview {
  display: table;
  width: 100%;
}
.member-edit--preview .member-edit__tabs,
.member-edit--preview .member-edit__preview {
  display: table-cell;
}
.member-edit--preview .member-edit__preview {
  width: 200px;
}
.member-edit--preview .member-edit__tabs {
  padding-left: 20px;
}
.member-edit--preview .ReactTabs {
  margin-top: -5px;
}
.member-edit__preview {
  background: #999;
  min-height: 600px;
  padding: 30px 20px;
}
.member-edit__preview__image {
  display: block;
  margin: 0 auto 10px;
}
.member-edit__preview__name {
  color: #fff;
  font-size: 16px;
  text-align: center;
  margin-bottom: 30px;
}
.member-edit__preview__fields {
  color: #fff;
  font-size: 12px;
}
.member-edit__preview__field {
  padding: 13px 0;
  border-bottom: solid 1px #aaa;
}
.member-edit__preview__field__key {
  border-left: solid 2px #eee;
  padding-left: 6px;
  color: #eee;
  font-size: 11px;
  line-height: 1.2;
}
.member-edit__preview__field__value {
  margin-top: 5px;
  line-height: 1.7;
}
.program-edit {
  background: #fff;
  border: 1px solid var(--color-border);
}
#programHistoryModal .layouter__panel-group {
  padding-bottom: 60px;
}
#programHistoryModal .layouter__field:hover {
  background-color: transparent;
  border-color: transparent;
}
.member-form__utils .input-calendar-wrapper {
  display: none;
}
.workflow__apply__form__layouter {
  width: 590px;
}
.workflow__apply__form__layouter .tabs {
  bottom: -1px;
}
.faceCard {
  background-color: #fff;
  color: var(--color-font-default);
  height: 100%;
  padding: 30px 12px 0 20px;
  position: absolute;
  right: 0;
  top: 0;
  width: 580px;
}
.faceCard__section-name {
  font-size: 16px;
  line-height: 1;
}
.faceCard__button {
  height: 30px;
  position: absolute;
  right: 20px;
  top: 20px;
  width: 250px;
}
.faceCard__button--add-user,
.faceCard__button--delete-section {
  background-color: #f08200;
  border-radius: 2px;
  color: #fff;
  cursor: pointer;
  font-size: 12px;
  height: 30px;
  line-height: 1;
  padding: 9px 0;
  text-align: center;
  width: 120px;
}
.faceCard__button--add-user {
  float: right;
}
.faceCard__button--delete-section {
  float: left;
}
.faceCard__button--delete-section.disabled {
  background-color: #f4f4f4;
  cursor: default;
  color: #d0d0d0;
}
.faceCard__employees {
  font-size: 12px;
  height: calc(100% - 45px);
  overflow: auto;
  padding: 0 20px;
  border-left: 1px solid var(--color-border);
}
.faceCard__employee {
  background-color: #fff;
  border: 2px solid transparent;
  color: var(--color-font-default);
  height: 140px;
  list-style: none;
  margin-bottom: 20px;
  position: relative;
  width: 240px;
  float: left;
  margin: 0 1px 20px;
}
.faceCard__employee.is_primary {
  border: 2px solid #ffe0ca;
}
.faceCard__employee.is_primary .tree__employee-list__employee__inner {
  border: 3px solid #ffe0ca;
}
.faceCard__employee__inner {
  display: block;
  color: var(--color-font-default);
  border: 3px solid #f4f4f4;
  height: 100%;
  padding: 10px;
  padding-bottom: 0;
  position: relative;
  width: 100%;
}
.faceCard__employee__photo {
  background-color: #ddd;
  height: 96px;
  width: 96px;
}
.faceCard__employee__button--remove {
  background-image: url("/images/ic_del3.png");
  cursor: pointer;
  height: 20px;
  position: absolute;
  right: -10px;
  top: -10px;
  width: 20px;
}
.faceCard__employee__data {
  font-size: 12px;
  left: 114px;
  line-height: 1.5;
  position: absolute;
  top: 10px;
}
.faceCard__employee__data > * {
  margin-bottom: 6px;
}
.faceCard__employee--dragging {
  opacity: 0.6;
  position: absolute;
  z-index: 500;
  margin: 0;
  pointer-events: none;
}
.faceCard__employee__handle {
  height: 140px;
  left: -1px;
  position: absolute;
  top: -1px;
  width: 240px;
  z-index: 50;
}
.faceCard__employee__power-badges {
  margin-top: 2px;
  text-align: center;
  width: 106px;
}
.faceCard__employee__joined-section-count {
  background-color: #f08200;
  border-radius: 10px;
  bottom: 5px;
  color: #fff;
  font-size: 12px;
  height: 20px;
  line-height: 20px;
  position: absolute;
  right: 5px;
  text-align: center;
  width: 20px;
}
.faceCard__employee__power-badge {
  background-color: #f2f2f2;
  border-radius: 9px;
  display: inline-block;
  font-size: 12px;
  height: 18px;
  line-height: 1;
  margin-right: 2px;
  padding: 3px 10px;
}
.faceCard__employee__relativePhoto {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}
.faceCard__employee__relativePhoto__wrap {
  display: block;
  width: 100%;
  height: 100%;
  max-width: 96px;
  max-height: 96px;
}
.analy-table__cell {
  padding: 10px;
  text-align: center;
  vertical-align: middle;
}
.analy-table__cell__wrap {
  display: block;
}
.analy-table__cell--th {
  white-space: nowrap;
  border: 1px solid var(--color-border);
}
.analy-table__cell--ylabel {
  width: 20px;
  vertical-align: top;
  background: #dfdfdf;
  padding: 20px 12px;
}
.analy-table__cell--xlabel {
  text-align: left;
  background: #dfdfdf;
  padding: 12px 20px;
}
.analy-table__cell--result {
  border-right: 1px solid var(--color-border);
  border-bottom: 1px solid var(--color-border);
  padding: 0;
  text-align: left;
  display: block;
  position: relative;
}
.analy-table__cell--result__data-wrapper {
  width: 100%;
  height: 100%;
}
.analy-table__cell--result .analy-table {
  border: 1px solid var(--color-border);
}
.analy-table__cell--result .analy-table tr:nth-child(odd):not(:first-child) {
  background: #f9fcfd;
}
.analy-table__cell--result .analy-v-header,
.analy-table__cell--result .analy-h-header {
  position: relative;
  z-index: 10;
}
.analy-table__cell--result .analy-v-header tr,
.analy-table__cell--result .analy-h-header tr {
  background-color: #fff;
}
.analy-table__cell--result .analy-v-header {
  left: 0;
}
.analy-table__cell--result .analy-h-header {
  top: 0;
}
.analy-table__cell--result .analy-h-header td:first-child {
  border-left: none;
}
.analy-table__cell--result__inner {
  overflow: hidden;
  display: flex;
  width: 100%;
}
.analy-table__cell--result__col--vscroll,
.analy-table__cell--result__col--hscroll {
  display: inline-block;
  position: relative;
  vertical-align: top;
  float: left;
}
.analy-table__cell--result__col--vscroll {
  background-color: #fff;
  padding-top: 34px;
  flex: 1;
}
.analy-table__cell--result__col--vscroll:before {
  content: '';
  display: block;
  width: 100%;
  height: 36px;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  border: 1px solid var(--color-border);
}
.analy-table__cell--pointer {
  cursor: pointer;
}
.analy-table__cell--yaxis {
  width: 20px;
  border-bottom: solid 2px #666;
  border-right: solid 2px #666;
  background: #999;
  color: #fff;
}
.analy-table__cell--xaxis {
  border-bottom: solid 2px #666;
  border-right: solid 2px #666;
  background: #999;
  color: #fff;
}
.analy-table__cell--value {
  width: 200px;
  height: 150px;
  border-top: solid 2px #fff;
  border-left: solid 2px #fff;
  background: #f4f4f4;
  box-shadow: 2px 2px 2px #999;
  color: var(--color-font-default);
}
.analy > * {
  display: inline-block;
  vertical-align: top;
}
.analy-labels {
  overflow: hidden;
  margin-top: 7px;
}
.analy-labels__btn {
  float: left;
  margin-left: 10px;
  padding: 10px 20px;
  border-radius: 40px;
  background: #f4f4f4;
  color: var(--color-font-default);
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}
.analy-table__wrap {
  display: block;
  max-height: 70vh;
  overflow: auto;
}
.analy-table--result {
  border: 1px solid #999;
}
.ProgramMemberDataTable .dataTable_fix .dataTable {
  width: 100%;
}
.ProgramMemberDataTable .dataTable_scroll {
  max-width: none;
  width: auto;
}
.programMemberHistory__member {
  overflow: hidden;
}
.programMemberHistory__image {
  float: right;
  width: 96px;
  text-align: center;
}
.programMemberHistory__image a {
  color: #999;
}
.programMemberHistory__relativeImage {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}
.programMemberHistory__relativeImage__wrap {
  display: block;
  width: 96px;
  height: 96px;
}
.programMemberHistory__name {
  display: block;
  margin-top: 8px;
}
.programMemberHistory .dataTable_fix {
  width: 280px;
}
.programMemberHistory .dataTable_fix .dataTable {
  width: 100%;
}
.programMemberHistory .main__base {
  box-shadow: none;
  border: 1px solid var(--color-border);
}
.programMemberHistory .tab__item.is-active {
  border-color: #999;
  background-color: #999;
}
.programMemberHistory .tab__item.is-active .tab__txt {
  color: #fff;
}
.historyData {
  position: relative;
  overflow: hidden;
}
.historyDataTable {
  font-size: 1.1rem;
  vertical-align: top;
}
.historyDataTable__row:nth-child(even) {
  background: #f9fcfd;
}
.historyDataTable__row.selected .dataTable__td {
  background: #fff4ec;
}
.historyDataTable__cell--check {
  width: 40px;
  padding: 0;
}
.historyDataTable__cell--control {
  width: 40px;
  padding: 0;
}
.historyDataTable__th,
.historyDataTable__td {
  white-space: nowrap;
}
.historyDataTable__th {
  padding: 0 20px;
  height: 48px;
  vertical-align: middle;
  font-size: 1.3rem;
}
.historyDataTable__th:last-child {
  border-right: 1px solid var(--color-border);
}
.historyDataTable__td {
  padding: 10px 20px;
  vertical-align: middle;
  text-align: center;
  border: 1px solid var(--color-border);
  height: 48px;
}
.historyDataTable__td--link {
  cursor: pointer;
  color: #49b0be;
}
.historyDataTable--full {
  width: 100%;
}
.historyDataTable--full__th.last-child,
.historyDataTable--full__td.last-child {
  border-right: 0;
}
.historyData .dataTable_fix .historyDataTable__th:first-child,
.historyData .dataTable_fix .historyDataTable__th:nth-child(2) {
  border: 0;
  padding: 0;
}
.historyData .dataTable_fix .historyDataTable__th:first-child {
  width: 16px;
}
.historyData .dataTable_fix .historyDataTable__th:nth-child(2) {
  width: 36px;
}
.historyData .dataTable_fix .historyDataTable__th:nth-child(3) {
  border-left: 1px solid var(--color-border);
}
.historyData .dataTable_fix .historyDataTable__th:nth-child(3),
.historyData .dataTable_fix .historyDataTable__th:nth-child(4) {
  border-top: 1px solid var(--color-border);
}
.historyData .dataTable_fix .historyDataTable__th:last-child {
  border-right: 0;
}
.historyData .dataTable_fix .historyDataTable__td:first-child,
.historyData .dataTable_fix .historyDataTable__td:nth-child(2) {
  border: 0;
  padding: 0 3px;
  background-color: #fff;
}
.historyData .dataTable_scroll {
  width: calc(100% - 281px);
}
.historyData .dataTable_scroll .historyDataTable__row:nth-child(1) {
  border-top: 1px solid var(--color-border);
}
.historyData .dataTable_scroll .historyDataTable__th:first-child,
.historyData .dataTable_scroll .historyDataTable__td:first-child {
  border-left: 0;
}
.historyData .dataTable_scroll .historyDataTable__th_label {
  display: block;
  min-width: 5em;
}
.historyData .data__overlay {
  height: 48px;
}
.historyTitle {
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  color: var(--color-font-default);
}
.historyTitle__text {
  margin-right: 80px;
}
.flow {
  margin-top: 40px;
}
.flow__row {
  border-top: 1px solid var(--color-border-30);
  border-bottom: 1px solid var(--color-border-30);
  background: #f7fdff;
  padding: 14px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.flow__row--arrow {
  text-align: center;
  padding: 14px 0;
}
.flow__row--arrow:before {
  content: "";
  background: url("/images/ic_arrow_down_03.png") no-repeat;
  background-size: contain;
  width: 12px;
  height: 14px;
  display: inline-block;
}
.flow__column--left,
.flow__column--right {
  width: 30%;
  text-align: left;
  font-size: 1.3rem;
  line-height: 1.5;
}
.flow__column--center {
  width: 40%;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.flow__column--left {
  padding-left: 20px;
}
.flow__column--right.checked {
  background: url("/images/ic_check.png") no-repeat right 20px center;
  background-size: 16px 13px;
}
.flow__check-user {
  display: block;
  margin: 0 3px;
  position: relative;
  text-align: center;
}
.flow__check-user__icon {
  display: block;
  width: 14px;
  height: 19px;
  background: url("/images/ic_flowuser_off.png") no-repeat;
  background-size: contain;
}
.flow__check-user__icon.checked {
  background: url("/images/ic_flowuser.png") no-repeat;
}
.flow__check-user__icon:hover + .flow__check-user__name {
  opacity: 1;
  visibility: visible;
}
.flow__check-user__name {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  top: -35px;
  left: 0;
  right: 0;
  margin: 0 -60px;
  background: #fff;
  border: 1px solid var(--color-border-30);
  padding: 5px 0;
  border-radius: 2px;
  box-shadow: 0 1px 1px rgba(100,100,100,0.15);
}
.flow__check-user__name:after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  right: 0;
  margin: auto;
  width: 5px;
  height: 5px;
  display: block;
  background: #fff;
  border-top: 1px solid var(--color-border-30);
  border-left: 1px solid var(--color-border-30);
  transform: rotate(-135deg);
}
.flow__table {
  color: #666;
  font-size: 14px;
}
.flow__table__cell {
  padding: 10px 10px 10px 0;
  vertical-align: top;
  text-align: left;
}
.flow__table__cell--verticalMiddle {
  vertical-align: middle;
}
.flow__table__arrow {
  height: 14px;
  background: url("/images/ic_arrow_down_03.png") no-repeat left 20px center;
  background-size: contain;
}
.flow__table__btns {
  padding: 10px 0;
}
.flow__table__btns> * {
  margin: 0 3px;
}
.payrollEditor {
  display: flex;
  min-width: 1194px;
  padding: 34px;
  background-color: #fff;
}
.payrollEditor__wrapper {
  position: fixed;
  left: 402px;
  overflow: auto;
  width: calc(100vw - 402px);
  max-width: 930px;
  height: calc(100vh - 90px);
}
.payrollEditor__column {
  box-shadow: 0 1px 0 #e9e9e9, 0 0 5px rgba(66,66,66,0.2);
  background: #fff;
}
.payrollEditor__heading {
  font-size: 1.3rem;
  line-height: 1.25;
}
.payrollEditor__heading .icon {
  margin-left: 13px;
}
.payrollEditor .icon,
.payrollEditor__icon {
  display: inline-block;
}
.payrollEditor .icon:before,
.payrollEditor__icon:before {
  content: '';
  display: inline-block;
  vertical-align: middle;
}
.payrollEditor .icon--plus,
.payrollEditor__icon--plus {
  cursor: pointer;
}
.payrollEditor .icon--plus:before,
.payrollEditor__icon--plus:before {
  width: 16px;
  height: 16px;
  background: url("/images/ic_add.png") no-repeat;
}
.payrollEditor .icon--plus:hover:before,
.payrollEditor__icon--plus:hover:before {
  background: url("/images/ic_add_a.png") no-repeat;
}
.payrollEditor .icon--draggable:before,
.payrollEditor__icon--draggable:before {
  width: 11px;
  height: 10px;
  background: url("/images/ic_draggable_02.png") no-repeat;
}
.payrollEditor .icon--delete,
.payrollEditor__icon--delete {
  cursor: pointer;
}
.payrollEditor .icon--delete:before,
.payrollEditor__icon--delete:before {
  width: 16px;
  height: 16px;
  background: url("/images/ic_del4.png") no-repeat;
}
.payrollEditor__menu {
  width: 176px;
  margin-right: 10px;
  padding: 16px 11px 20px 13px;
}
.payrollEditor__menu .payrollEditor__heading {
  margin-bottom: 13px;
}
.payrollEditor__fields {
  width: 930px;
  font-size: 0.9em;
  padding: 20px 20px 30px;
}
.payrollEditor__fields .payrollEditor__heading {
  margin-bottom: 10px;
}
.payrollEditor__fields .buttons {
  padding-top: 0;
}
.payrollEditor__items {
  height: 100%;
  overflow-y: auto;
  padding-right: 12px;
}
.payrollEditor__item {
  position: relative;
  margin: 10px 0;
  padding: 0 6px 0 8px;
  border-radius: 2px;
  background-color: #f4f4f4;
  cursor: move;
}
.payrollEditor__item:first-child {
  margin-top: 0;
}
.payrollEditor__item:last-child {
  margin-bottom: 0;
}
.payrollEditor__item--edit .payrollEditor__item-clickable {
  display: none;
}
.payrollEditor__item--empty {
  background-color: #fff;
  border: 1px solid #e4e4e4;
  cursor: default;
}
.payrollEditor__item--empty .payrollEditor__item-icon {
  opacity: 0;
}
.payrollEditor__item--empty .payrollEditor__item-label input {
  color: rgba(102,102,102,0.5);
  user-select: none;
}
.payrollEditor__item--empty .payrollEditor__item-restore {
  display: block;
}
.payrollEditor__item--dragging {
  opacity: 0;
}
.payrollEditor__item--selected {
  background-color: #cdcdcd;
}
.payrollEditor__item-inner {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  height: 36px;
}
.payrollEditor__item-icon {
  width: 19px;
  padding-right: 8px;
}
.payrollEditor__item-label {
  position: relative;
  max-width: 90px;
  padding-right: 5px;
  font-size: 1.2rem;
}
.payrollEditor__item-label input {
  width: 100%;
  color: var(--color-font-default);
  font-size: inherit;
  border: 1px solid transparent;
}
.payrollEditor__item-label input:focus {
  background-color: #fff;
  border-color: #e4e4e4;
}
.payrollEditor__item-clickable {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: text;
}
.payrollEditor__item-delete {
  width: 16px;
  margin-left: auto;
}
.payrollEditor__item-delete .icon {
  display: none;
}
.payrollEditor__item-restore {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
}
.payrollEditor__item:hover .payrollEditor__item-delete .icon {
  display: inline-block;
}
.payrollEditor__item--empty:hover .payrollEditor__item-delete .icon {
  display: none;
}
.payrollEditor__drop-area {
  position: relative;
  height: 100%;
  border: 1px dashed #c7c7c7;
  background-color: #fbfbfb;
  text-align: center;
  opacity: 0;
  transition: opacity 0.2s ease;
}
.payrollEditor__drop-area:before {
  content: attr(data-text);
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1em;
  margin: auto;
  font-size: 1.2rem;
}
.payrollEditor__drop-area.can-drop {
  opacity: 1;
}
.payrollEditor__drag-preview {
  width: 140px;
}
.payrollEditor__drag-preview .payrollEditor__item {
  box-shadow: 0 1px 3px rgba(0,0,0,0.2);
}
.payrollEditor__top-view {
  margin-bottom: 40px;
  font-size: 1.2rem;
}
.payrollEditor__top-view::after {
  content: "";
  display: block;
  clear: both;
}
.payrollEditor__basic-info {
  float: left;
  width: 434px;
  margin-right: 31px;
  margin-top: 10px;
}
.payrollEditor__basic-info table {
  width: 100%;
}
.payrollEditor__basic-info table th,
.payrollEditor__basic-info table td {
  border: 1px solid #eee;
  text-align: left;
  vertical-align: top;
}
.payrollEditor__basic-info table th {
  width: 86px;
  padding: 13px 8px 13px 14px;
  border-top: none;
  border-bottom: none;
}
.payrollEditor__basic-info table td {
  position: relative;
  padding: 0;
  z-index: -1;
}
.payrollEditor__basic-info table tr:nth-child(odd) th,
.payrollEditor__basic-info table tr:nth-child(odd) td {
  background-color: #fff;
}
.payrollEditor__basic-info table tr:nth-child(even) th,
.payrollEditor__basic-info table tr:nth-child(even) td {
  background-color: #f9fcfd;
}
.payrollEditor__basic-info table tr:first-child th {
  border-top: 1px solid #eee;
}
.payrollEditor__basic-info table tr:last-child th {
  border-bottom: 1px solid #eee;
}
.payrollEditor__basic-info table .payrollEditor__table-cell {
  position: relative;
  z-index: 5;
  min-height: 41px;
}
.payrollEditor__basic-info table .payrollEditor__drop-area {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}
.payrollEditor__table-cell {
  font-size: 1.2rem;
  padding: 13px 18px;
  border: 1px solid transparent;
  cursor: move;
}
.payrollEditor__table-cell:hover {
  border-color: #c7c7c7;
}
.payrollEditor__table-cell--empty {
  cursor: default;
}
.payrollEditor__table-cell--empty:hover {
  border-color: transparent;
}
.payrollEditor__table-cell--error {
  border-color: #d80c0c !important;
  color: #d80c0c;
}
.payrollEditor__table-cell--drag-preview {
  width: 346px;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0,0,0,0.2);
}
.payrollEditor__table-cell--static {
  cursor: default;
}
.payrollEditor__table-cell--static:hover {
  border-color: transparent;
}
.payrollEditor__remarks {
  float: left;
  width: 403px;
  margin-top: 10px;
}
.payrollEditor__remarks-area {
  position: relative;
  width: 100%;
  height: 200px;
}
.payrollEditor__remarks-area .payrollEditor__table-cell {
  position: relative;
  z-index: 5;
  width: 100%;
  height: 100%;
  padding: 13px 18px;
  border: 1px solid #eee;
}
.payrollEditor__remarks-area .payrollEditor__drop-area {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}
.payrollEditor__bottom-view {
  display: flex;
  justify-content: space-between;
  font-size: 1.2rem;
}
.payrollEditor__col4 {
  width: 217px;
}
.payrollEditorDetail {
  margin-bottom: 30px;
}
.payrollEditorDetail__header {
  height: 32px;
  background-color: #dfdfdf;
  text-align: center;
  line-height: 32px;
}
.payrollEditorDetail__caption {
  display: table;
  position: relative;
  width: 100%;
  height: 37px;
  border: 1px solid #eee;
  box-shadow: 0 1px 0 #e9e9e9, 0 0 4px rgba(66,66,66,0.09);
}
.payrollEditorDetail__left {
  display: table-cell;
  width: 129px;
  padding: 0 10px;
  vertical-align: middle;
}
.payrollEditorDetail__right {
  display: table-cell;
  width: 86px;
  vertical-align: middle;
}
.payrollEditorDetail__inputs {
  display: flex;
  align-items: center;
}
.payrollEditorDetail__caption-text {
  position: relative;
  text-align: center;
  width: 33.3333%;
  height: 37px;
}
.payrollEditorDetail__caption-text span {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1em;
  transform: translate(-50%, -50%);
}
.payrollEditorDetail__inner {
  position: relative;
}
.payrollEditorDetail__inner .payrollEditor__drop-area {
  min-height: 38px;
}
.payrollEditorDetail__items {
  border: 1px solid #eee;
}
.payrollEditorDetail__items.is-active {
  border-color: #c7c7c7;
}
.payrollEditorDetail__items.is-empty {
  border: none;
}
.payrollEditorDetail__item {
  position: relative;
  display: table;
  border-top: 1px solid #eee;
  font-size: 1.2rem;
}
.payrollEditorDetail__item:first-child {
  margin-top: -1px !important;
}
.payrollEditorDetail__item:nth-child(odd) {
  background-color: #fff;
}
.payrollEditorDetail__item:nth-child(even) {
  background-color: #f9fcfd;
}
.payrollEditorDetail__item:hover {
  z-index: 10;
  margin: 0 -1px -1px;
  border: 1px solid #c7c7c7;
}
.payrollEditorDetail__item.is-move {
  border: 1px solid #c7c7c7;
}
.payrollEditorDetail__item .payrollEditorDetail__check {
  border-left: 1px solid #eee;
}
.payrollEditorDetail__item .payrollEditorDetail__left {
  cursor: move;
  width: 60%;
  font-size: 0.9em;
}
.payrollEditorDetail__item--drag-preview {
  width: 215px;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0,0,0,0.2);
}
.payrollEditorDetail__item--static:hover {
  margin: 0;
  border: none;
  border-top: 1px solid #eee;
}
.payrollEditorDetail__item--static .payrollEditorDetail__left {
  height: 37px;
  cursor: default;
}
.payrollEditorDetail__item--static .payrollEditorDetail__right {
  height: 37px;
  padding: 0 10px;
  text-align: right;
}
.payrollEditorDetail__check {
  position: relative;
  width: 33.3333%;
  height: 37px;
  text-align: center;
}
.payrollEditorDetail__check .form__checkbox__label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.paymentPager {
  margin-bottom: 15px;
}
.paymentPager .button:first-child {
  display: inline-block;
  margin-left: 0;
}
.paymentSelect {
  margin-bottom: 15px;
}
.modInfoList {
  border-top: solid 1px var(--color-border);
}
.modInfoList__item {
  border-bottom: solid 1px var(--color-border);
  font-size: 13px;
}
.modInfoList__item a {
  display: block;
  padding: 18px 0;
  color: var(--color-font-default);
  position: relative;
}
.modInfoList__item a:after {
  border-right: 2px solid var(--color-border);
  border-top: 2px solid var(--color-border);
  content: "";
  display: block;
  height: 10px;
  position: absolute;
  right: 12px;
  top: 50%;
  margin-top: -5px;
  transform: rotate(45deg);
  transition: transform 0.1s;
  width: 10px;
}
.modInfoList__item a:hover {
  background-color: #f4f4f4;
}
.modInfoList__item a:hover:after {
  border-right-color: #f08200;
  border-top-color: #f08200;
}
.modInfoList__item span {
  display: block;
  padding: 18px 0;
  color: var(--color-font-default);
}
.listItem {
  display: inline-block;
  margin-left: 35px;
  font-size: 13px;
}
.listItem--date {
  margin-left: 0;
}
.toArchive {
  margin-top: 15px;
  text-align: right;
}
.toArchive a {
  display: inline-block;
  padding: 5px 35px 5px 0;
  font-size: 13px;
  text-align: left;
  position: relative;
}
.toArchive a:hover {
  text-decoration: underline;
}
.toArchive a:after {
  border-right: 2px solid #49b0be;
  border-top: 2px solid #49b0be;
  content: "";
  display: block;
  height: 10px;
  position: absolute;
  right: 12px;
  top: 50%;
  margin-top: -6px;
  transform: rotate(45deg);
  transition: transform 0.1s;
  width: 10px;
}
.modArticle {
  line-height: 1.5;
}
.modArticle__head {
  padding: 25px 0;
}
.modArticle__body {
  padding: 0 0 25px;
}
.articleDate {
  display: inline-block;
  margin-right: 20px;
}
.articleHeading {
  display: inline-block;
  font-size: 15px;
  font-weight: bold;
}
.detailToArchive {
  margin-top: 15px;
}
.detailToArchive a {
  display: inline-block;
  padding: 5px 0 5px 35px;
  font-size: 13px;
  text-align: left;
  position: relative;
}
.detailToArchive a:hover {
  text-decoration: underline;
}
.detailToArchive a:after {
  border-left: 2px solid #49b0be;
  border-top: 2px solid #49b0be;
  content: "";
  display: block;
  height: 10px;
  position: absolute;
  left: 12px;
  top: 50%;
  margin-top: -6px;
  transform: rotate(-45deg);
  transition: transform 0.1s;
  width: 10px;
}
.alertBlock {
  margin-bottom: 60px;
}
.alertBlock .formHeading {
  color: #f08200;
}
.alertBlock .modInfoList__item span {
  color: #f08200;
}
.logo_image {
  width: 125px;
}
.logo_image img {
  max-width: 100%;
  width: auto;
  height: auto;
}
.main__menu__item--selectbox {
  margin-left: 0;
  padding-left: 20px;
  vertical-align: middle;
}
.main__menu__calendar {
  padding-top: 9px;
}
.main__menu__calendar .form__date {
  width: auto;
}
.main__menu__calendar .input-calendar-wrapper {
  z-index: 10;
}
.masterHomeLower {
  margin-top: 40px;
  display: flex;
  -webkit-align-items: flex-start;
  align-items: flex-start;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.masterHomeLower__links {
  width: 45%;
}
.masterHomeLower__corps {
  width: 45%;
}
.memberHomeLower__block {
  clear: both;
}
.memberHomeLower__block::after {
  content: "";
  display: block;
  clear: both;
}
.memberHomeLower__corps {
  width: 30%;
  margin-right: 5%;
  float: left;
}
.memberHomeLower__workflowRequestCount {
  width: 32.5%;
  float: left;
}
.resultHead {
  position: relative;
}
.resultHead .toggle__item {
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.permission__name {
  color: #f08200;
  font-weight: bold;
}
#popupEmployeeSearch .formTable__cell {
  padding: 3px;
}
#programMemberSearchModal .formTable__cell {
  padding: 3px;
}
.modal--center {
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.main--enterprise {
  min-width: 1050px;
}
.main--workflowApply {
  min-width: 700px;
}
.main--workflowAgree {
  min-width: 950px;
}
.main.payrollEditor {
  transform: none;
  background: transparent;
  padding: 1em;
}
.main.payrollEditor .payrollEditor__menu {
  height: 700px;
  height: calc(100vh - 90px);
  overflow: auto;
  position: fixed;
  left: 215px;
}
.main.payrollEditor .payrollEditor__items {
  overflow: visible;
  padding-right: 0;
}
.main-supplyDetail {
  min-width: 700px;
}
.overflowText {
  width: 95px;
  margin: 0 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.mt0 {
  margin-top: 0px;
}
.pt0 {
  padding-top: 0px;
}
.pl0 {
  padding-left: 0px;
}
.pr0 {
  padding-right: 0px;
}
.pb0 {
  padding-bottom: 0px;
}
.px0 {
  padding-left: 0px;
  padding-right: 0px;
}
.py0 {
  padding-top: 0px;
  padding-bottom: 0px;
}
.ml0 {
  margin-left: 0px;
}
.mr0 {
  margin-right: 0px;
}
.mb0 {
  margin-bottom: 0px;
}
.mt5 {
  margin-top: 5px;
}
.pt5 {
  padding-top: 5px;
}
.pl5 {
  padding-left: 5px;
}
.pr5 {
  padding-right: 5px;
}
.pb5 {
  padding-bottom: 5px;
}
.px5 {
  padding-left: 5px;
  padding-right: 5px;
}
.py5 {
  padding-top: 5px;
  padding-bottom: 5px;
}
.ml5 {
  margin-left: 5px;
}
.mr5 {
  margin-right: 5px;
}
.mb5 {
  margin-bottom: 5px;
}
.mt10 {
  margin-top: 10px;
}
.pt10 {
  padding-top: 10px;
}
.pl10 {
  padding-left: 10px;
}
.pr10 {
  padding-right: 10px;
}
.pb10 {
  padding-bottom: 10px;
}
.px10 {
  padding-left: 10px;
  padding-right: 10px;
}
.py10 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.ml10 {
  margin-left: 10px;
}
.mr10 {
  margin-right: 10px;
}
.mb10 {
  margin-bottom: 10px;
}
.mt15 {
  margin-top: 15px;
}
.pt15 {
  padding-top: 15px;
}
.pl15 {
  padding-left: 15px;
}
.pr15 {
  padding-right: 15px;
}
.pb15 {
  padding-bottom: 15px;
}
.px15 {
  padding-left: 15px;
  padding-right: 15px;
}
.py15 {
  padding-top: 15px;
  padding-bottom: 15px;
}
.ml15 {
  margin-left: 15px;
}
.mr15 {
  margin-right: 15px;
}
.mb15 {
  margin-bottom: 15px;
}
.mt20 {
  margin-top: 20px;
}
.pt20 {
  padding-top: 20px;
}
.pl20 {
  padding-left: 20px;
}
.pr20 {
  padding-right: 20px;
}
.pb20 {
  padding-bottom: 20px;
}
.px20 {
  padding-left: 20px;
  padding-right: 20px;
}
.py20 {
  padding-top: 20px;
  padding-bottom: 20px;
}
.ml20 {
  margin-left: 20px;
}
.mr20 {
  margin-right: 20px;
}
.mb20 {
  margin-bottom: 20px;
}
.mt25 {
  margin-top: 25px;
}
.pt25 {
  padding-top: 25px;
}
.pl25 {
  padding-left: 25px;
}
.pr25 {
  padding-right: 25px;
}
.pb25 {
  padding-bottom: 25px;
}
.px25 {
  padding-left: 25px;
  padding-right: 25px;
}
.py25 {
  padding-top: 25px;
  padding-bottom: 25px;
}
.ml25 {
  margin-left: 25px;
}
.mr25 {
  margin-right: 25px;
}
.mb25 {
  margin-bottom: 25px;
}
.mt30 {
  margin-top: 30px;
}
.pt30 {
  padding-top: 30px;
}
.pl30 {
  padding-left: 30px;
}
.pr30 {
  padding-right: 30px;
}
.pb30 {
  padding-bottom: 30px;
}
.px30 {
  padding-left: 30px;
  padding-right: 30px;
}
.py30 {
  padding-top: 30px;
  padding-bottom: 30px;
}
.ml30 {
  margin-left: 30px;
}
.mr30 {
  margin-right: 30px;
}
.mb30 {
  margin-bottom: 30px;
}
.mt35 {
  margin-top: 35px;
}
.pt35 {
  padding-top: 35px;
}
.pl35 {
  padding-left: 35px;
}
.pr35 {
  padding-right: 35px;
}
.pb35 {
  padding-bottom: 35px;
}
.px35 {
  padding-left: 35px;
  padding-right: 35px;
}
.py35 {
  padding-top: 35px;
  padding-bottom: 35px;
}
.ml35 {
  margin-left: 35px;
}
.mr35 {
  margin-right: 35px;
}
.mb35 {
  margin-bottom: 35px;
}
.mt40 {
  margin-top: 40px;
}
.pt40 {
  padding-top: 40px;
}
.pl40 {
  padding-left: 40px;
}
.pr40 {
  padding-right: 40px;
}
.pb40 {
  padding-bottom: 40px;
}
.px40 {
  padding-left: 40px;
  padding-right: 40px;
}
.py40 {
  padding-top: 40px;
  padding-bottom: 40px;
}
.ml40 {
  margin-left: 40px;
}
.mr40 {
  margin-right: 40px;
}
.mb40 {
  margin-bottom: 40px;
}
.mt45 {
  margin-top: 45px;
}
.pt45 {
  padding-top: 45px;
}
.pl45 {
  padding-left: 45px;
}
.pr45 {
  padding-right: 45px;
}
.pb45 {
  padding-bottom: 45px;
}
.px45 {
  padding-left: 45px;
  padding-right: 45px;
}
.py45 {
  padding-top: 45px;
  padding-bottom: 45px;
}
.ml45 {
  margin-left: 45px;
}
.mr45 {
  margin-right: 45px;
}
.mb45 {
  margin-bottom: 45px;
}
.mt50 {
  margin-top: 50px;
}
.pt50 {
  padding-top: 50px;
}
.pl50 {
  padding-left: 50px;
}
.pr50 {
  padding-right: 50px;
}
.pb50 {
  padding-bottom: 50px;
}
.px50 {
  padding-left: 50px;
  padding-right: 50px;
}
.py50 {
  padding-top: 50px;
  padding-bottom: 50px;
}
.ml50 {
  margin-left: 50px;
}
.mr50 {
  margin-right: 50px;
}
.mb50 {
  margin-bottom: 50px;
}
.mt55 {
  margin-top: 55px;
}
.pt55 {
  padding-top: 55px;
}
.pl55 {
  padding-left: 55px;
}
.pr55 {
  padding-right: 55px;
}
.pb55 {
  padding-bottom: 55px;
}
.px55 {
  padding-left: 55px;
  padding-right: 55px;
}
.py55 {
  padding-top: 55px;
  padding-bottom: 55px;
}
.ml55 {
  margin-left: 55px;
}
.mr55 {
  margin-right: 55px;
}
.mb55 {
  margin-bottom: 55px;
}
.mt60 {
  margin-top: 60px;
}
.pt60 {
  padding-top: 60px;
}
.pl60 {
  padding-left: 60px;
}
.pr60 {
  padding-right: 60px;
}
.pb60 {
  padding-bottom: 60px;
}
.px60 {
  padding-left: 60px;
  padding-right: 60px;
}
.py60 {
  padding-top: 60px;
  padding-bottom: 60px;
}
.ml60 {
  margin-left: 60px;
}
.mr60 {
  margin-right: 60px;
}
.mb60 {
  margin-bottom: 60px;
}
.mt65 {
  margin-top: 65px;
}
.pt65 {
  padding-top: 65px;
}
.pl65 {
  padding-left: 65px;
}
.pr65 {
  padding-right: 65px;
}
.pb65 {
  padding-bottom: 65px;
}
.px65 {
  padding-left: 65px;
  padding-right: 65px;
}
.py65 {
  padding-top: 65px;
  padding-bottom: 65px;
}
.ml65 {
  margin-left: 65px;
}
.mr65 {
  margin-right: 65px;
}
.mb65 {
  margin-bottom: 65px;
}
.mt70 {
  margin-top: 70px;
}
.pt70 {
  padding-top: 70px;
}
.pl70 {
  padding-left: 70px;
}
.pr70 {
  padding-right: 70px;
}
.pb70 {
  padding-bottom: 70px;
}
.px70 {
  padding-left: 70px;
  padding-right: 70px;
}
.py70 {
  padding-top: 70px;
  padding-bottom: 70px;
}
.ml70 {
  margin-left: 70px;
}
.mr70 {
  margin-right: 70px;
}
.mb70 {
  margin-bottom: 70px;
}
.mt75 {
  margin-top: 75px;
}
.pt75 {
  padding-top: 75px;
}
.pl75 {
  padding-left: 75px;
}
.pr75 {
  padding-right: 75px;
}
.pb75 {
  padding-bottom: 75px;
}
.px75 {
  padding-left: 75px;
  padding-right: 75px;
}
.py75 {
  padding-top: 75px;
  padding-bottom: 75px;
}
.ml75 {
  margin-left: 75px;
}
.mr75 {
  margin-right: 75px;
}
.mb75 {
  margin-bottom: 75px;
}
.mt80 {
  margin-top: 80px;
}
.pt80 {
  padding-top: 80px;
}
.pl80 {
  padding-left: 80px;
}
.pr80 {
  padding-right: 80px;
}
.pb80 {
  padding-bottom: 80px;
}
.px80 {
  padding-left: 80px;
  padding-right: 80px;
}
.py80 {
  padding-top: 80px;
  padding-bottom: 80px;
}
.ml80 {
  margin-left: 80px;
}
.mr80 {
  margin-right: 80px;
}
.mb80 {
  margin-bottom: 80px;
}
.mt85 {
  margin-top: 85px;
}
.pt85 {
  padding-top: 85px;
}
.pl85 {
  padding-left: 85px;
}
.pr85 {
  padding-right: 85px;
}
.pb85 {
  padding-bottom: 85px;
}
.px85 {
  padding-left: 85px;
  padding-right: 85px;
}
.py85 {
  padding-top: 85px;
  padding-bottom: 85px;
}
.ml85 {
  margin-left: 85px;
}
.mr85 {
  margin-right: 85px;
}
.mb85 {
  margin-bottom: 85px;
}
.mt90 {
  margin-top: 90px;
}
.pt90 {
  padding-top: 90px;
}
.pl90 {
  padding-left: 90px;
}
.pr90 {
  padding-right: 90px;
}
.pb90 {
  padding-bottom: 90px;
}
.px90 {
  padding-left: 90px;
  padding-right: 90px;
}
.py90 {
  padding-top: 90px;
  padding-bottom: 90px;
}
.ml90 {
  margin-left: 90px;
}
.mr90 {
  margin-right: 90px;
}
.mb90 {
  margin-bottom: 90px;
}
.mt95 {
  margin-top: 95px;
}
.pt95 {
  padding-top: 95px;
}
.pl95 {
  padding-left: 95px;
}
.pr95 {
  padding-right: 95px;
}
.pb95 {
  padding-bottom: 95px;
}
.px95 {
  padding-left: 95px;
  padding-right: 95px;
}
.py95 {
  padding-top: 95px;
  padding-bottom: 95px;
}
.ml95 {
  margin-left: 95px;
}
.mr95 {
  margin-right: 95px;
}
.mb95 {
  margin-bottom: 95px;
}
.mt100 {
  margin-top: 100px;
}
.pt100 {
  padding-top: 100px;
}
.pl100 {
  padding-left: 100px;
}
.pr100 {
  padding-right: 100px;
}
.pb100 {
  padding-bottom: 100px;
}
.px100 {
  padding-left: 100px;
  padding-right: 100px;
}
.py100 {
  padding-top: 100px;
  padding-bottom: 100px;
}
.ml100 {
  margin-left: 100px;
}
.mr100 {
  margin-right: 100px;
}
.mb100 {
  margin-bottom: 100px;
}
hr.page_separator {
  margin: 30px 0;
}
.w100 {
  width: 100%;
}
.text__align--right {
  text-align: right;
}
.text__align--center {
  text-align: right;
}
.text--note {
  padding-left: 30px;
  padding-bottom: 10px;
  margin-top: -10px;
  font-size: 11px;
  color: #49b0be;
  border-left: 1px solid var(--color-border);
}
.text--indent {
  text-indent: -1em;
  padding-left: 1em;
}
.text--lh125 {
  line-height: 1.25;
}
.text--lh15 {
  line-height: 1.5;
}
.text--red {
  color: #f00;
}
.text--fz12 {
  font-size: 12px;
}
.text--fz13 {
  font-size: 13px;
}
.text--fz14 {
  font-size: 14px;
}
.text--fz16 {
  font-size: 16px;
}
.text--fz18 {
  font-size: 18px;
}
.text--alignLeft {
  text-align: left;
}
.text--alignRight {
  text-align: right;
}
.text--alignCenter {
  text-align: center;
}
.text--bold {
  font-weight: bold;
}
.text--whiteSpaceNorap {
  white-space: nowrap;
}
.text--verticalAlignTop {
  vertical-align: top;
}
.text--verticalAlignMiddle {
  vertical-align: middle;
}
.text--check {
  position: relative;
  padding-left: 1.25em;
}
.text--check:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 1em;
  height: 1em;
  background: url("/images/ic_check_glay.png") no-repeat;
  background-size: contain;
}
.import-member--template .listBox,
.export-member--template .listBox,
.export-program-member--template .listBox,
.program-history-import--template .listBox,
.import-program--template .listBox,
.item-master-import--template .listBox,
.import-member--fields .listBox,
.export-member--fields .listBox,
.export-program-member--fields .listBox,
.program-history-import--fields .listBox,
.import-program--fields .listBox,
.item-master-import--fields .listBox {
  width: auto;
}
.import-member--template .listBox__option--left:not([disabled]),
.export-member--template .listBox__option--left:not([disabled]),
.export-program-member--template .listBox__option--left:not([disabled]),
.program-history-import--template .listBox__option--left:not([disabled]),
.import-program--template .listBox__option--left:not([disabled]),
.item-master-import--template .listBox__option--left:not([disabled]),
.import-member--fields .listBox__option--left:not([disabled]),
.export-member--fields .listBox__option--left:not([disabled]),
.export-program-member--fields .listBox__option--left:not([disabled]),
.program-history-import--fields .listBox__option--left:not([disabled]),
.import-program--fields .listBox__option--left:not([disabled]),
.item-master-import--fields .listBox__option--left:not([disabled]) {
  padding-left: 22px;
}
.import-member--template__heading,
.export-member--template__heading,
.export-program-member--template__heading,
.program-history-import--template__heading,
.import-program--template__heading,
.item-master-import--template__heading,
.import-member--fields__heading,
.export-member--fields__heading,
.export-program-member--fields__heading,
.program-history-import--fields__heading,
.import-program--fields__heading,
.item-master-import--fields__heading {
  line-height: 29px;
  display: inline-block;
}
.import-member--template__template-box,
.export-member--template__template-box,
.export-program-member--template__template-box,
.program-history-import--template__template-box,
.import-program--template__template-box,
.item-master-import--template__template-box,
.import-member--fields__template-box,
.export-member--fields__template-box,
.export-program-member--fields__template-box,
.program-history-import--fields__template-box,
.import-program--fields__template-box,
.item-master-import--fields__template-box {
  display: inline-block;
  vertical-align: top;
  margin-left: 30px;
}
.import-member--template__template-box__save,
.export-member--template__template-box__save,
.export-program-member--template__template-box__save,
.program-history-import--template__template-box__save,
.import-program--template__template-box__save,
.item-master-import--template__template-box__save,
.import-member--fields__template-box__save,
.export-member--fields__template-box__save,
.export-program-member--fields__template-box__save,
.program-history-import--fields__template-box__save,
.import-program--fields__template-box__save,
.item-master-import--fields__template-box__save,
.import-member--template__template-box__select,
.export-member--template__template-box__select,
.export-program-member--template__template-box__select,
.program-history-import--template__template-box__select,
.import-program--template__template-box__select,
.item-master-import--template__template-box__select,
.import-member--fields__template-box__select,
.export-member--fields__template-box__select,
.export-program-member--fields__template-box__select,
.program-history-import--fields__template-box__select,
.import-program--fields__template-box__select,
.item-master-import--fields__template-box__select {
  display: inline-block;
}
.import-member--template__template-box__save,
.export-member--template__template-box__save,
.export-program-member--template__template-box__save,
.program-history-import--template__template-box__save,
.import-program--template__template-box__save,
.item-master-import--template__template-box__save,
.import-member--fields__template-box__save,
.export-member--fields__template-box__save,
.export-program-member--fields__template-box__save,
.program-history-import--fields__template-box__save,
.import-program--fields__template-box__save,
.item-master-import--fields__template-box__save {
  margin-left: 30px;
}
.import-member--template__template-box__save__input-name,
.export-member--template__template-box__save__input-name,
.export-program-member--template__template-box__save__input-name,
.program-history-import--template__template-box__save__input-name,
.import-program--template__template-box__save__input-name,
.item-master-import--template__template-box__save__input-name,
.import-member--fields__template-box__save__input-name,
.export-member--fields__template-box__save__input-name,
.export-program-member--fields__template-box__save__input-name,
.program-history-import--fields__template-box__save__input-name,
.import-program--fields__template-box__save__input-name,
.item-master-import--fields__template-box__save__input-name,
.import-member--template__template-box__save__save-button,
.export-member--template__template-box__save__save-button,
.export-program-member--template__template-box__save__save-button,
.program-history-import--template__template-box__save__save-button,
.import-program--template__template-box__save__save-button,
.item-master-import--template__template-box__save__save-button,
.import-member--fields__template-box__save__save-button,
.export-member--fields__template-box__save__save-button,
.export-program-member--fields__template-box__save__save-button,
.program-history-import--fields__template-box__save__save-button,
.import-program--fields__template-box__save__save-button,
.item-master-import--fields__template-box__save__save-button {
  display: inline-block;
  vertical-align: top;
}
.import-member--template__template-box__save__input-name,
.export-member--template__template-box__save__input-name,
.export-program-member--template__template-box__save__input-name,
.program-history-import--template__template-box__save__input-name,
.import-program--template__template-box__save__input-name,
.item-master-import--template__template-box__save__input-name,
.import-member--fields__template-box__save__input-name,
.export-member--fields__template-box__save__input-name,
.export-program-member--fields__template-box__save__input-name,
.program-history-import--fields__template-box__save__input-name,
.import-program--fields__template-box__save__input-name,
.item-master-import--fields__template-box__save__input-name {
  width: 206px;
}
.import-member--template__template-box__save__save-button,
.export-member--template__template-box__save__save-button,
.export-program-member--template__template-box__save__save-button,
.program-history-import--template__template-box__save__save-button,
.import-program--template__template-box__save__save-button,
.item-master-import--template__template-box__save__save-button,
.import-member--fields__template-box__save__save-button,
.export-member--fields__template-box__save__save-button,
.export-program-member--fields__template-box__save__save-button,
.program-history-import--fields__template-box__save__save-button,
.import-program--fields__template-box__save__save-button,
.item-master-import--fields__template-box__save__save-button {
  margin: 0 0 0 4px;
  height: 29px;
  font-size: 14px;
  padding: 0;
  line-height: 29px;
  width: 60px;
}
.import-member--template__template-box__select__select,
.export-member--template__template-box__select__select,
.export-program-member--template__template-box__select__select,
.program-history-import--template__template-box__select__select,
.import-program--template__template-box__select__select,
.item-master-import--template__template-box__select__select,
.import-member--fields__template-box__select__select,
.export-member--fields__template-box__select__select,
.export-program-member--fields__template-box__select__select,
.program-history-import--fields__template-box__select__select,
.import-program--fields__template-box__select__select,
.item-master-import--fields__template-box__select__select,
.import-member--template__template-box__select__delete-button,
.export-member--template__template-box__select__delete-button,
.export-program-member--template__template-box__select__delete-button,
.program-history-import--template__template-box__select__delete-button,
.import-program--template__template-box__select__delete-button,
.item-master-import--template__template-box__select__delete-button,
.import-member--fields__template-box__select__delete-button,
.export-member--fields__template-box__select__delete-button,
.export-program-member--fields__template-box__select__delete-button,
.program-history-import--fields__template-box__select__delete-button,
.import-program--fields__template-box__select__delete-button,
.item-master-import--fields__template-box__select__delete-button {
  display: inline-block;
  vertical-align: top;
}
.import-member--template__template-box__select__select,
.export-member--template__template-box__select__select,
.export-program-member--template__template-box__select__select,
.program-history-import--template__template-box__select__select,
.import-program--template__template-box__select__select,
.item-master-import--template__template-box__select__select,
.import-member--fields__template-box__select__select,
.export-member--fields__template-box__select__select,
.export-program-member--fields__template-box__select__select,
.program-history-import--fields__template-box__select__select,
.import-program--fields__template-box__select__select,
.item-master-import--fields__template-box__select__select {
  height: 29px;
  width: 206px;
}
.import-member--template__template-box__select__delete-button,
.export-member--template__template-box__select__delete-button,
.export-program-member--template__template-box__select__delete-button,
.program-history-import--template__template-box__select__delete-button,
.import-program--template__template-box__select__delete-button,
.item-master-import--template__template-box__select__delete-button,
.import-member--fields__template-box__select__delete-button,
.export-member--fields__template-box__select__delete-button,
.export-program-member--fields__template-box__select__delete-button,
.program-history-import--fields__template-box__select__delete-button,
.import-program--fields__template-box__select__delete-button,
.item-master-import--fields__template-box__select__delete-button {
  margin: 0 0 0 4px;
  height: 29px;
  font-size: 14px;
  padding: 0;
  line-height: 29px;
  width: 60px;
}
.import-member--listbox,
.export-member--listbox,
.export-program-member--listbox,
.program-history-import--listbox,
.import-program--listbox,
.item-master-import--listbox {
  width: 660px;
  text-align: right;
  margin-top: 20px;
}
.import-member--listbox__caption,
.export-member--listbox__caption,
.export-program-member--listbox__caption,
.program-history-import--listbox__caption,
.import-program--listbox__caption,
.item-master-import--listbox__caption {
  font-size: 13px;
  line-height: 32px;
  width: 270px;
  text-align: center;
  display: inline-block;
}
.import-member--listbox .listBox,
.export-member--listbox .listBox,
.export-program-member--listbox .listBox,
.program-history-import--listbox .listBox,
.import-program--listbox .listBox,
.item-master-import--listbox .listBox {
  width: 100%;
}
.import-member--listbox__button--download-template,
.export-member--listbox__button--download-template,
.export-program-member--listbox__button--download-template,
.program-history-import--listbox__button--download-template,
.import-program--listbox__button--download-template,
.item-master-import--listbox__button--download-template {
  background-image: url("/images/ic_download.png");
  height: 38px;
  margin: 0;
  margin-top: 10px;
  display: inline-block;
  width: 70px;
}
.import-member--listbox__button--download-template.disabled,
.export-member--listbox__button--download-template.disabled,
.export-program-member--listbox__button--download-template.disabled,
.program-history-import--listbox__button--download-template.disabled,
.import-program--listbox__button--download-template.disabled,
.item-master-import--listbox__button--download-template.disabled {
  cursor: default;
  opacity: 0.3;
}
.import-member--listbox-rightonly,
.export-member--listbox-rightonly,
.export-program-member--listbox-rightonly,
.program-history-import--listbox-rightonly,
.import-program--listbox-rightonly,
.item-master-import--listbox-rightonly {
  width: 270px;
}
.import-member--file,
.export-member--file,
.export-program-member--file,
.program-history-import--file,
.import-program--file,
.item-master-import--file {
  margin-top: 30px;
}
.import-member--file__caption,
.export-member--file__caption,
.export-program-member--file__caption,
.program-history-import--file__caption,
.import-program--file__caption,
.item-master-import--file__caption,
.import-member--file__upload,
.export-member--file__upload,
.export-program-member--file__upload,
.program-history-import--file__upload,
.import-program--file__upload,
.item-master-import--file__upload {
  display: inline-block;
  vertical-align: top;
}
.import-member--file__caption,
.export-member--file__caption,
.export-program-member--file__caption,
.program-history-import--file__caption,
.import-program--file__caption,
.item-master-import--file__caption {
  line-height: 35px;
}
.import-member--file__upload,
.export-member--file__upload,
.export-program-member--file__upload,
.program-history-import--file__upload,
.import-program--file__upload,
.item-master-import--file__upload {
  margin-left: 24px;
}
.import-member .formTable__th,
.export-member .formTable__th,
.export-program-member .formTable__th,
.program-history-import .formTable__th,
.import-program .formTable__th,
.item-master-import .formTable__th {
  padding-top: 17px;
}
.import-member--base-date,
.export-member--base-date,
.export-program-member--base-date,
.program-history-import--base-date,
.import-program--base-date,
.item-master-import--base-date {
  margin-bottom: 20px;
}
.import-member--radio,
.export-member--radio,
.export-program-member--radio,
.program-history-import--radio,
.import-program--radio,
.item-master-import--radio {
  margin-top: 30px;
}
.import-member .main__base,
.export-member .main__base,
.export-program-member .main__base,
.program-history-import .main__base,
.import-program .main__base,
.item-master-import .main__base {
  overflow: auto;
}
.error__header {
  transform: initial;
}
.error__body {
  position: relative;
  width: calc(100vw - 5em);
  height: calc(100vh - 5em - 63px);
  box-sizing: border-box;
  margin: calc(2.5em + 63px) 2.5em 2.5em;
  background: #fff;
}
.error__innerbody {
  position: absolute;
  right: 50%;
  bottom: 50%;
  transform: translate(50%, 50%);
  text-align: center;
  line-height: 2.3;
  font-size: 1.2em;
}
.error__headline {
  color: #f08200;
  font-size: 1.5em;
  font-weight: bold;
}
.error__logo {
  width: 25em;
  height: 12.5em;
  background: url("/images/logo.png") no-repeat center center/contain;
  margin: 0 auto;
}
.flex-start {
  display: flex;
  justify-content: flex-start;
}
.flex-center-wrap {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.flex-row-center {
  display: flex;
  align-items: center;
}
.slideup__overlay {
  display: block;
}
.slideup__box {
  transition: 0.5s;
  top: 0%;
}
.slideup-enter .slideup__box {
  transition: none;
}
.slideup-enter .slideup__box,
.slideup-leave-active .slideup__box {
  will-change: top;
  top: 100%;
}
.slideup__overlay {
  display: block;
}
.slideup-on-tab__box,
.slideup-on-tab-enter-active .slideup-on-tab-enter-active {
  transition: 0.5s;
  top: 0%;
  margin-left: 10%;
}
.slideup-on-tab-enter .slideup__box {
  transition: none;
}
.slideup-on-tab-enter .slideup-on-tab__box,
.slideup-on-tab-leave-active .slideup-on-tab__box {
  transition: none;
  will-change: top;
  top: 260%;
}
.modal__popup--parent.popup__box {
  display: block;
}
.modal__popup.popup__box {
  transition: 0.4s;
}
.popup-enter-active,
.popup-leave-active,
.popup-enter-active .popup__box,
.popup-leave-active .popup__box {
  transition: 0.4s;
}
.popup-enter,
.popup-leave-active,
.popup-enter .popup__box,
.popup-leave-active .popup__box {
  opacity: 0;
}
.mode-simple.target-relation-workflow .nav,
.mode-simple.target-relation-workflow #sub_menu1,
.mode-simple.target-relation-workflow #sub_menu2,
.mode-simple.target-relation-workflow #sub_menu3,
.mode-simple.target-relation-workflow #sub_menu5,
.mode-simple.target-relation-workflow .header.clearfix,
.mode-simple.target-relation-workflow .main {
  display: none;
}
.mode-simple.target-relation-workflow .modal__popup {
  left: auto;
  top: auto;
  right: 50%;
  bottom: 50%;
  transform: translate(50%, 50%);
}
.mode-simple.target-relation-workflow .modal__slideup.slideup__box {
  margin: 0;
  transition: none;
  width: 100vw;
  height: 100vh;
  overflow: auto;
}
.gensenPDF {
  width: 900px;
  background-color: #fff;
  padding: 20px;
}
.gensenPDF h1 {
  margin: 0;
  padding: 0;
  font-size: 14pt !important;
  font-weight: normal;
  text-align: center !important;
  line-height: 1 !important;
}
.gensenPDF i.bl {
  margin: 0;
  padding: 0;
  width: 1pt;
}
.gensenPDF table {
  width: 100% !important;
  border-collapse: collapse;
}
.gensenPDF th,
.gensenPDF td {
  margin: 0;
  padding: 0;
  border-top: 0.5px solid #888;
  border-right: 0.5px solid #888;
  border-bottom: 0.5px solid #888;
  border-left: 0.5px solid #888;
  font-weight: normal;
}
.gensenPDF .th {
  font-size: 4.2pt;
}
.gensenPDF .td {
  font-size: 6.8pt;
}
.gensenPDF .td-small {
  font-size: 5.6pt;
}
.gensenPDF .non {
  background-color: #ccc;
}
.gensenPDF .td-f1 {
  font-size: 4.2pt;
}
.gensenPDF .bt-n {
  padding-top: 0;
  border-top: none;
}
.gensenPDF .br-n {
  padding-right: 0;
  border-right: none;
}
.gensenPDF .bb-n {
  padding-bottom: 0;
  border-bottom: none;
}
.gensenPDF .bl-n {
  padding-left: 0;
  border-left: none;
}
.gensenPDF .bt-d {
  border-top: 0.5px dotted #888;
}
.gensenPDF .br-d {
  border-right: 0.5px dotted #888;
}
.gensenPDF .bb-d {
  border-bottom: 0.5px dotted #888;
}
.gensenPDF .bl-d {
  border-left: 0.5px dotted #888;
}
.gensenPDF .bt-t {
  border-top: 0.2px solid #888;
}
.gensenPDF .br-t {
  border-right: 0.2px solid #888;
}
.gensenPDF .bb-t {
  border-bottom: 0.2px solid #888;
}
.gensenPDF .bl-t {
  border-left: 0.2px solid #888;
}
.gensenPDF .b {
  font-weight: bold;
}
.gensenPDF .ta-l {
  text-align: left;
}
.gensenPDF .ta-c {
  text-align: center;
}
.gensenPDF .ta-r {
  text-align: right;
}
.gensenPDF .va-c {
  vertical-align: middle;
}
.gensenPDF .centering {
  vertical-align: middle;
  text-align: center;
}
.react-dual-listbox {
  display: block;
  margin: 0 auto;
  width: 700px;
}
.react-dual-listbox .rdl-listbox {
  display: inline-block;
  width: 270px;
}
.react-dual-listbox .rdl-listbox .rdl-control-container {
  height: 250px;
}
.react-dual-listbox .rdl-listbox .rdl-control-container .rdl-control-label {
  display: none;
}
.react-dual-listbox .rdl-listbox .rdl-control-container .rdl-control {
  width: 100%;
  height: 100%;
  border: 1px solid #eee;
  border-radius: 3px;
  padding: 5px 0;
  overflow-y: scroll;
}
.react-dual-listbox .rdl-listbox .rdl-control-container .rdl-control option {
  font-size: 13px;
  padding: 2px 10px;
  color: var(--color-font-default);
  text-shadow: none;
}
.react-dual-listbox .rdl-actions {
  display: inline-block;
  width: 34px;
  margin: 0 30px;
}
.react-dual-listbox .rdl-actions .rdl-actions-right .rdl-move {
  width: 59px;
  height: 34px;
  background-repeat: no-repeat;
  background-position: center;
}
.react-dual-listbox .rdl-actions .rdl-actions-right .rdl-move-right {
  background-image: url("/images/ic_arrow_r.png");
}
.react-dual-listbox .rdl-actions .rdl-actions-right .rdl-move-all {
  background-image: url("/images/ic_arrows_r.png");
}
.react-dual-listbox .rdl-actions .rdl-actions-left .rdl-move {
  width: 59px;
  height: 34px;
  background-repeat: no-repeat;
}
.react-dual-listbox .rdl-actions .rdl-actions-left .rdl-move-left {
  background-image: url("/images/ic_arrow_l.png");
}
.react-dual-listbox .rdl-actions .rdl-actions-left .rdl-move-all {
  background-image: url("/images/ic_arrows_l.png");
}
.skillDetailInfoLabel {
  vertical-align: top;
  line-height: 18px;
}
.skillDetailInfoIc {
  display: inline-block;
  width: 18px;
  height: 18px;
  background-image: url("/images/ic_hatena.png");
  margin-left: 12px;
  background-size: contain;
  cursor: pointer;
  vertical-align: top;
}
.skillDetailSideView {
  position: fixed;
  top: 65px;
  bottom: 0px;
  width: 440px;
  z-index: 14;
  box-shadow: var(--ds-shadow-overlay, 0 2px 4px 0 rgba(0,0,0,0.5), 0 0 0 1px rgba(9,30,66,0.08));
  background: var(--ds-surface, #fff);
  height: calc(100vh - 195px);
  border-radius: 10px;
  margin: 2px;
}
.skillDetailSideView.left {
  left: 200px;
}
.skillDetailSideView.middle {
  left: 42%;
}
.skillDetailSideView.right {
  right: 0px;
}
.skillDetailSideView .viewArea {
  margin: 20px;
}
.skillDetailSideView .viewHead {
  margin-bottom: 20px;
}
.skillDetailSideView .viewTitle {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}
.skillDetailSideView .viewItem {
  padding-bottom: 5px;
}
.skillDetailSideView .viewItem label {
  font-size: 11px;
}
.skillDetailSideView .viewItem div {
  font-size: 16px;
  margin: 5px 0px;
  border-bottom: 1px solid var(--color-border);
  overflow-wrap: break-word;
  white-space: pre-line;
}
.skillDetailSideView .scrollArea {
  overflow-y: scroll;
  height: calc(100vh - 320px);
}
.skillDetailSideView .viewTable table {
  width: 380px;
}
.skillDetailSideView .viewTable tr {
  text-align: left;
  border-bottom: 1px solid var(--color-border);
}
.skillDetailSideView .viewTable tr th:first-child {
  width: 65px;
}
.skillDetailSideView .viewTable th,
.skillDetailSideView .viewTable td {
  vertical-align: top;
  text-align: left;
  padding: 5px;
  word-break: break-word;
}
.skillDetailSideView .viewTable th {
  font-size: 11px;
}
.skillDetailSideView .viewTable td {
  font-size: 16px;
}
.menuPermission {
  padding-bottom: 1em;
}
.menuPermission--gray {
  background: #f7f7f7;
}
.menuPermission__field {
  min-width: 270px;
  padding-bottom: 10px;
}
.menuPermission__field__head {
  font-size: 1.1em;
  font-weight: bold;
  text-align: center;
  padding: 1.1em;
}
.menuPermission__field__head--gray {
  background: #c8c8c8;
}
.menuPermission__field__text {
  margin-left: 20px;
  padding-right: 1em;
}
.menuPermission__field__label {
  display: flex;
  cursor: pointer;
  white-space: nowrap;
  padding-right: 1em;
  justify-content: space-between;
  min-width: 0;
  padding-right: 1.5em;
}
.menuPermission__field__label::after {
  content: '';
  width: 14px;
  height: 14px;
  background: url("/images/form_check_off.png") no-repeat;
}
.menuPermission__field__label--active::after {
  background: url("/images/form_check_on.png") no-repeat;
}
.menuPermission__field__checkbox {
  display: none;
}
.definition__layout__row {
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
}
.definition__layout__row--small {
  padding-top: 10px;
  padding-bottom: 10px;
}
.definition__layout__label {
  width: 200px;
  line-height: 1.5;
}
.definition__layout__content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-left: 20px;
}
.definition__layout__content--w720 {
  width: 720px;
}
@-moz-keyframes stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}
@-webkit-keyframes stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}
@-o-keyframes stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}
@keyframes stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}
