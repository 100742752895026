
.dialog
	display: none
	background: #fff;
	border-radius: 5px;
	left: 50%;
	padding: 60px;
	position: relative;
	text-align: center;
	top: 17vh;
	z-index: 10
	&--calender
		display: block
		width: 550px
		margin-left: -275px
	&__container
		display: none;
		left: 0;
		position: absolute;
		top: 0;
		&.modal--appear
			opacity: 1
	.btns
		letter-spacing: -.4em;
	.months_item
		display: inline-block;
		letter-spacing: normal;
		margin: 0 9px;
		span
			background: #999;
			color: #fff;
			display: block;
			padding: 12px 0 10px;
			width: 150px;
		&--active
			span
				background: #f08200;
	&--delete
		margin-left: -295px;
		width: 590px;
		p
			color: #999;
			font-size: 18px;
			margin-bottom: 40px;

.dialog .btns
	letter-spacing: -.4em;
.dialog .btn
	display: inline-block;
	letter-spacing: normal;
	margin: 0 9px;
.dialog .btn a
	background: #999;
	color: #fff;
	display: block;
	padding: 12px 0 10px;
	width: 150px;
.dialog .btn--active a
	background: #f08200;
.dialog--delete
	margin-left: -295px;
	width: 590px;
.dialog--delete p
	color: #999;
	font-size: 18px;
	margin-bottom: 40px;

