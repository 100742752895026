.menuPermission
	padding-bottom: 1em
	&--gray
		background: #f7f7f7
	&__field
		min-width: 270px
		padding-bottom: 10px
		&__head
			font-size: 1.1em
			font-weight: bold
			text-align: center
			padding: 1.1em
			&--gray
				background: #c8c8c8
		&__text
			margin-left: 20px
			padding-right: 1em
		&__label
			display: flex
			cursor: pointer
			white-space: nowrap
			padding-right: 1em
			justify-content: space-between
			min-width: 0
			padding-right: 1.5em
			&::after
				content: ''
				width: 14px
				height: 14px
				background: url(/images/form_check_off.png) no-repeat
			&--active
				&::after
					background: url(/images/form_check_on.png) no-repeat
		&__checkbox
			display: none
