.definition__layout
	&__row
		display: flex
		padding-top: 20px
		padding-bottom: 20px
		&--small
			padding-top: 10px
			padding-bottom: 10px
	&__label
		width: 200px
		line-height: 1.5
	&__content
		display: flex
		flex-wrap: wrap
		align-items: center
		padding-left: 20px
		&--w720
			width: 720px
