.analy-table__cell
	padding: 10px;
	text-align: center
	vertical-align: middle
	&__wrap
		display: block
	&--th
		white-space: nowrap;
		border: 1px solid var(--color-border)
	&--ylabel
		width: 20px;
		vertical-align: top
		background: #dfdfdf
		padding: 20px 12px
	&--xlabel
		text-align: left
		background: #dfdfdf
		padding: 12px 20px
	&--result
		border-right: 1px solid var(--color-border)
		border-bottom: 1px solid var(--color-border)
		padding: 0
		text-align: left
		display: block
		position: relative
		&__data-wrapper
			width: 100%
			height: 100%
		.analy-table
			border: 1px solid var(--color-border)
			tr:nth-child(odd):not(:first-child)
				background: #f9fcfd
		.analy-v-header,
		.analy-h-header
			position: relative
			z-index: 10
			tr
				background-color: #fff
		.analy-v-header
			left: 0
		.analy-h-header
			top: 0
			td:first-child
				border-left: none
		&__inner
			overflow: hidden
			display: flex
			width: 100%
		&__col
			&--vscroll,
			&--hscroll
				display: inline-block
				position: relative
				vertical-align: top
				float: left
			&--vscroll
				background-color: #fff
				padding-top: 34px
				flex: 1
				&:before
					content: ''
					display: block
					width: 100%
					height: 36px
					background-color: #fff
					position: absolute
					top: 0
					left: 0
					z-index: 20
					border: 1px solid var(--color-border)
	&--pointer
		cursor: pointer
	&--yaxis
		width: 20px;
		border-bottom: solid 2px #666
		border-right: solid 2px #666
		background: #999
		color: #fff
	&--xaxis
		border-bottom: solid 2px #666
		border-right: solid 2px #666
		background: #999
		color: #fff
	&--value
		width: 200px
		height: 150px
		border-top: solid 2px #fff
		border-left: solid 2px #fff
		background: #f4f4f4
		box-shadow: 2px 2px 2px #999
		color: var(--color-font-default)
.analy
	> *
		display: inline-block
		vertical-align: top
	&-labels
		overflow: hidden
		margin-top: 7px
		&__btn
			float: left
			margin-left: 10px
			padding: 10px 20px
			border-radius: 40px
			background: #f4f4f4
			color: var(--color-font-default)
			display: inline-block;
			vertical-align: middle
			text-align: center
	&-table
		&__wrap
			display: block
			max-height: 70vh
			overflow: auto
		&--result
			border: 1px solid #999
