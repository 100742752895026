.mode-simple.target-relation-workflow
	.nav,
	#sub_menu1,
	#sub_menu2,
	#sub_menu3,
	#sub_menu5,
	.header.clearfix,
	.main
		display: none

	.modal__popup
		left: auto
		top: auto
		right: 50%
		bottom: 50%
		transform: translate(50%, 50%)

	.modal__slideup.slideup__box
		margin: 0
		transition: none
		width: 100vw
		height: 100vh
		overflow: auto
