@import url(//fonts.googleapis.com/earlyaccess/notosansjapanese.css);
@require '_const.styl'
@-ms-viewport {
	width: auto !important
}
.noto {
	font-family: 'Noto Sans Japanese', serif
}
:root
	--color-font-default: $color-font-default
	--color-font-default-rgb: $color-font-default-rgb
	--color-font-gray-01: rgba($color-font-default-rgb,0.76)
	--color-font-gray-02: rgba($color-font-default-rgb,0.59)
	--color-border: $color-border
	--color-border-rgb: $color-border-rgb
	--color-border-30: rgba($color-border,0.3)
	--color-orange-01: $color-orange-01

html
	overflow-y: scroll
	background: $color-main-bg-gray
	color: var(--color-font-default)
	font-size: 62.5%
body
	font-family:'ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic Pro','メイリオ',Meiryo,'ＭＳ Ｐゴシック',sans-serif
	font-size: 1.4rem
	height: 100%
	min-width: 1050px
.responsive
	min-width: 0

a
	color: $color-link-default
	text-decoration: none
	word-break: break-all

.js--dev
.hidden
	display: none

.error
	color: $color-red
	font-size: 13px
	margin-top: 4px
	display: inilne-block
.p30
	padding: 30px;
	border: 1px solid var(--color-border)
	border-top: none
	&--noborder
		padding: 30px;

*,*:before,*:after
	box-sizing: border-box
.clearfix
	clear: both
	content: " "
	display: block


.spinner {
  margin: 100px auto;
  width: 50px;
  height: 60px;
  text-align: center;
  font-size: 10px;
}

.spinner > div {
  background-color: #f08200;
  height: 100%;
  width: 6px;
  display: inline-block;

  -webkit-animation: stretchdelay 1.2s infinite ease-in-out;
  animation: stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% {
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
}

.br1 {
	border-right: 1px solid #999999;
}
.openedTray .linkedTray {
	bottom: 140px !important;
}
.actionTray {
	position: absolute;
	left: 0;
	right: 0;
	bottom: -100px;
	height: 100px;
	background: #ffffff;
	transition: bottom linear 0.1s;
	z-index: 10;
}
.openedTray .actionTray {
	bottom: 0;
}
.actionTrayToggle {
	position: absolute;
	right: 50%;
	transform: translateX(50%);
	top: -30px;
	height: 30px;
	color: #fff;
	background: #fe9902;
	text-align: center;
	font-size: 16px;
	padding: 7px;
	cursor: pointer;
	width: 120px;
    display: block;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
.actionTrayToggle::after {
	content: "\2191";
}
.openedTray .actionTrayToggle::after {
	content: "\2193";
}
.actionTrayMenu {
	width: 100%;
	display: flex;
	justify-content: space-evenly;
	margin-top: 30px;
}
.actionTrayMenuItem {
	width: 200px;
	height: 40px;
	border-radius: 8px;
	background: #ffdd99;
	cursor: pointer;
	text-decoration: none;
	color: var(--color-font-default);
	display: block;
	font-size: 12px;
	padding: 0px 12px;
	text-align: center;
	line-height: 40px;
}
.actionTrayMenuItem:hover {
	background: #ffcc88;
}
.actionTrayMenuItem.disabled {
	background: #cccccc;
	cursor: not-allowed;
	color: #fff;
}
.actionTrayMenuItem.disabled:hover {
	background: #cccccc;
}
.isModalOn {
	display: block !important;
}
.formItemsArea {
	display: block;
	width: 100%;
	height: calc(100% - 120px);
	overflow-y: scroll;
}
.modalActionButtonArea {
	margin-top: 40px;
	display: flex;
	width: 100%;
	justify-content: space-evenly;
}
.modalActionButtonArea button {
	width: 200px;
	height: 40px;
	border-radius: 8px;
	background: #ffdd99;
	cursor: pointer;
	text-decoration: none;
	color: var(--color-font-default);
	display: block;
	font-size: 12px;
	padding: 0px 12px;
	text-align: center;
	line-height: 40px;
}
.modalActionButtonArea button.typeCancel {
	background: #eeeeee;
}
.dataSelectorTabs-is-active {
	background: #ffffff !important;
	box-shadow: -2px 0 0 rgba(0, 0, 0, 0.5) inset !important;
	cursor: auto !important;
}
.skill_high {
	background: #99ff99;
}
.skill_mid {
	background: #ccff99;
}
.skill_low {
	background: #ffff99;
}
.formItemsArea div {
	display: flex;
	margin-bottom: 10px;
}
.formItemsArea div label {
	width: 200px;
	text-align: right;
	margin-right: 10px;
	line-height: 36px;
}
/* ----------------------------------------
	layout
---------------------------------------- */

@require '_layout'


/* ----------------------------------------
	modules
---------------------------------------- */

@require '_login'
@require '_link'
@require '_modal'
@require '_dialog'
@require '_calender'
@require '_notice'
@require '_loading'
@require '_pager'
@require '_data_table'
@require '_tree'
@require '_tabs'
@require '_form'
@require '_button'
@require '_table'
@require '_list'
@require '_box'
@require '_toggle'
@require '_listBox'
@require '_layouter'
@require '_facecard'
@require '_analysis'
@require '_program'
@require '_history_table'
@require '_tree_modal'
@require '_flow'
@require '_payroll'
@require '_info'
@require '_local'
@require '_adjustment'
@require '_text'
@require '_import_export'
@require '_error'
@require '_flex-util'
@require '_slideup'
@require '_popup'
@require '_simple-mode-target-relation-workflow'
@require '_gensen_pdf'
@require '_esign'
@require '_assessment_skill'
@require '_menu_permission'
@require '_definition'
