
.years
	margin: 0 15px 30px
	justify-content: space-between
	align-items: center
	display: flex
	&__item
		a
		span
			display: block
			color: #999
			font-size: 24px
		&--prev
		&--next
			display: flex
			align-items: center
			cursor: pointer
			a
			span
				color: $color-gray-02
				font-size: 14px
			&:before,
			&:after
				color: $color-orange-01
				font-size: 24px
				margin-top: -2px
		&--prev
			span
				margin-left: 12px
			&:before
				content: '<'
		&--next
			span
				margin-right: 12px
			&:after
				content: '>'

.months
	&__item
		display: inline-block
		margin: 0 10px 20px
		width: 120px
		cursor: pointer
		a
		span
			background: $color-bav-bg-gray
			border-radius: 2px
			color: #fff
			display: block
			line-height: 1.5
			padding: 9px 0 7px
		.num
			font-size: 20px
		.en
			font-size: 13px
		&--selected
			span
				background: #f08200

