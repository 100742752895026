@require '_const'

.error__header
	transform: initial;

.error__body
	position: relative
	width: calc(100vw - 5em)
	height: calc(100vh - 5em - 63px)
	box-sizing: border-box
	margin: calc(2.5em + 63px) 2.5em 2.5em
	background: #fff

.error__innerbody
	position: absolute
	right: 50%
	bottom: 50%
	transform: translate(50%, 50%)
	text-align: center
	line-height: 2.3
	font-size: 1.2em

.error__headline
	color: $color-orange-01
	font-size: 1.5em
	font-weight: bold

.error__logo
	width: 25em
	height: 12.5em
	background: url(/images/logo.png) no-repeat center center / contain;
	margin: 0 auto
