// オーバーライド
.layouter
	.form__text
	.form__textarea
	.form__select
	.form__date
		width: 100%
		&:disabled
			background-color: #fff
	.form__text--number
		text-align: right
	.form__text--currency
		width: 80%
		text-align: right
	.val__currency
		width: 20%
		padding: 0 1.5%
		display: inline-block
		text-align: center
	.text__currency
		width: 80%
		display: inline-block
	.form__textarea
		height: auto
		min-height: auto
		resize: vertical
	.input-calendar
		display: block
	.input-calendar-wrapper
		z-index: 11
	.buttons
		padding-top: 20px
	:disabled
		cursor: not-allowed

.main__contents--layouter
	background-color: #f4f4f4
	border: none

// ユーティリティ
.layouter
	&__clear-fix
	&__clearfix
		&:before
		&:after
			content: " "
			display: table
		&:after
			clear: both
	&__text
		&--left
			text-align: left
		&--center
			text-align: center
		&--right
			text-align: right
		&--small
			font-size: 10px
		&--medium
			font-size: 13px
		&--large
			font-size: 16px
		&--bold
			font-weight: bold

// アイコン
.layouter__icon
	display: block
	background-repeat: no-repeat
	background-position: center
	&--trash
		background-image: url("/images/ic_layouter_trash.png")
		width: 15px
		height: 15px
	&--ban
		background-image: url("/images/ic_layouter_trash_unremovable.png")
		width: 15px
		height: 15px
	&--times-circle
		background-image: url("/images/ic_del3.png")
		width: 20px
		height: 20px
	&--cog
		background-image: url("/images/ic_tools.png")
		width: 22px
		height: 22px
	&--history
		background: url("/images/ic_layouter_history.png")
		width: 52px
		height: 32px

// ツールボックス
.layouter__toolbox
	position: absolute
	width: 202px
	background-color: $color-white
	border: 1px solid #afafaf
	border-radius: 5px
	z-index: 9
	&__header
		height: 30px
		padding: 4px
		background-color: #ff8d02
		border-top-left-radius 4px
		border-top-right-radius 4px
		cursor move
	&__collapse
		float: right
		cursor: pointer
	&__body
		margin: -1px
	&__group
		@extend .layouter__clear-fix
		padding-left: 10px
		padding-top: 10px
		border-top: 1px solid var(--color-border)
		&:first-child
			border-top: none
	&__item
		float: left
		margin-right: 10px
		margin-bottom: 10px
	&--collapsed
		width 32px
	&--collapsed &__header
		border-bottom-left-radius 4px
		border-bottom-right-radius 4px
	&--collapsed &__body
		display none

.layouter__toolbox__item
	position: relative
	background-repeat: no-repeat
	background-position: center
	border-radius: 2px
	width: 86px
	height: 36px
	line-height: 36px
	cursor: pointer
	&:active
		box-shadow: 1px 1px 2px 0 #999999
	&--number
		background-image: url("/images/ic_layouter_number.png")
	&--link
		background-image: url("/images/ic_layouter_link.png")
	&--date
		background-image: url("/images/ic_layouter_date.png")
	&--birthday
		background-image: url("/images/ic_layouter_birthday.png")
	&--text
		background-image: url("/images/ic_layouter_text.png")
	&--textarea
		background-image: url("/images/ic_layouter_textarea.png")
	&--select
		background-image: url("/images/ic_layouter_select.png")
	&--upload
		background-image: url("/images/ic_layouter_upload.png")
	&--currency
		background-image: url("/images/ic_layouter_currency.png")
	&--mail
		background-image: url("/images/ic_layouter_mail.png")
	&--tel
		background-image: url("/images/ic_layouter_tel.png")
	&--autodate
		background-image: url("/images/ic_layouter_autodate.png")
	&--calc
		background-image: url("/images/ic_layouter_calc.png")
	&--from_to
		background-image: url("/images/ic_layouter_fromto.png")
	&--display_text
		background-image: url("/images/ic_layouter_display_text.png")
	&--display_file
		background-image: url("/images/ic_layouter_display_file.png")
	&--display_link
		background-image: url("/images/ic_layouter_display_link.png")
	&--label
		background-image: url("/images/ic_layouter_label.png")
	&--separator
		background-image: url("/images/ic_layouter_separator.png")
	&--height
		background-image: url("/images/ic_layouter_height.png")
	&__overlay
		position: absolute
		left: 0
		top: 0
		width: 100%
		height: 100%
		color: #fff
		background-color: rgba(0,0,0,0.4)
		font-size: 12px
		text-align: center
	&__overlay
	&:active &__overlay
		display: none
	&:hover &__overlay
		display: block

// グリッド
.layouter
	&__row
	&__field
		display: flex
		width: 100%
	&__col
	&__label
	&__input
		display: table-cell
		vertical-align: middle
		&--12
			width: 100%
		&--11
			width: 91.66666667%
		&--10
			width: 83.33333333%
		&--9
			width: 75%
		&--8
			width: 66.66666667%
		&--7
			width: 58.33333333%
		&--6
			width: 50%
		&--5
			width: 41.66666667%
		&--4
			width: 33.33333333%
		&--3
			width: 25%
		&--2
			width: 16.66666667%
		&--1
			width: 8.33333333%

// タブ
.layouter .ReactTabs
	&__TabList
		@extend .layouter__clear-fix
	&__Tab
		position: relative
		bottom: -1px
		//display: inline-block
		margin-top: 4px
		margin-right: 4px
		float: left
	&__TabPanel
		padding: 20px
		background: $color-white
		border: 1px solid var(--color-border)
	&__TabPanel__borderless
		padding: 0px
		background: $color-white

// ワークフロー 従業員フォーム パネルグループ
.layouter
	.member-form__panel-group
		background: $color-white
		border: 1px solid var(--color-border)
		width: 80%


.layouter--wide
	&.member-form
		.ReactTabs
			width: 100%
	.ReactTabs
		width: 100%

.layouter__tab
	position: relative
	background: $color-gray-03
	border: 1px solid var(--color-border)
	border-top-left-radius: 2px
	border-top-right-radius: 2px
	padding-right: 25px
	&__label
	&__input
		//box-sizing: content-box
		font-family: inherit
		font-size: 14px
		height: 38px
		line-height: 38px
		padding-left: 20px
		padding-right: 10px
	&__label
		opacity: 1
		overflow: hidden
		white-space: nowrap;
		text-overflow: ellipsis;
	&__input
		position: absolute
		top: -9999px
		left: -9999px
		opacity: 0
		border: none
	&--focus &__label
		position: absolute
		top: -9999px
		left: -9999px
		opacity: 0
	&--focus &__input
		position: static
		top: 0
		left: 0
		opacity: 1
	&__add
		background: $color-orange-01
		width: 40px
		height: 39px
		line-height: 40px
		text-align center
	&__remove
		position: absolute
		top: 9px
		right: 9px
	.layouter .ReactTabs__Tab--selected &
		background: $color-white
		border-bottom-color: $color-white
	&--locked
		padding-right: 0
	&--locked &__label
	&--locked &__input
		padding-right: 20px

// パネルグループ
.layouter__panel-group
	//

// パネル
.layouter__panel
	border-top: 1px solid var(--color-border)
	padding: 19px
	&:first-child
		border-top: none
	&__header
		font-size: 16px
		margin-bottom: 20px

// 行
.layouter__row
	position: relative
	border: 1px solid var(--color-border)
	border-top-width: 0
	&:first-child
		border-top-width: 1px
	&__insert-before
	&__insert-after
		left: -19px
		color: transparent
		background: url("/images/ic_layouter_add.png") no-repeat
		width: 53px
		height: 37px
		z-index: 12
	&__insert-before
	&__insert-after
	&__remove
	&__history
		display: none
		position: absolute
		cursor: pointer
		&:hover
			display: block
	&__insert-before
		top: -18px
	&__insert-after
		bottom: -20px
	&__remove
		top 50%
		left 50%
		width 42px
		height 42px
		margin-top -21px
		margin-left -21px
		padding 12px
		background #ffffff
		border 1px dashed #c7c7c7
	.layouter__tab-panel--empty &
	.layouter__panel--empty &
		border-color: transparent
	&:hover &__insert-before
	&:hover &__insert-after
	&:hover &__remove
	&--popover &__insert-before
	&--popover &__insert-after
	.layouter__tab-panel--empty &__insert-before
	.layouter__panel--empty &__insert-before
		display: block

// 列
.layouter__col
	padding: 9px



// フィールド
.layouter__field
	position: relative
	border: 1px dashed transparent
	padding: 9px
	padding-right: 25px;
	min-height: 55px
	&--empty
		background-color: #fbfbfb
		border-color: #c7c7c7
		&:hover
			.layouter__field__history
				display: none
	&--empty
		height: 55px
	&--error
		border-left: 4px solid red
		border-right: 4px solid red
		&.first
			border-top: 4px solid red
		&.last
			border-bottom: 4px solid red

	.layouter__col--locked &--empty
		background-color: transparent
		border-color: transparent
	&__remove
		display: none
		width 16px
		height 16px
		cursor: pointer
		position: absolute
		top 50%
		right: 4px
		margin-top: -8px
	&:hover &__remove
		display: block
	&__history
		display: none
		width: 52px
		height: 32px
		cursor: pointer
		position: absolute
		top: 50%
		right: -38px
		margin-top: -16px
		z-index: 20
	&:not(.layouter__field--workflow):hover &__history
		display: block
	&--check
		background: #F4F4F4 !important;
	&--required .layouter__label:after
		content: " (必)"

// ラベル
.layouter__label
	color: var(--color-font-gray-02)
	font-size: 13px
	word-wrap: break-word;
	margin-right: 0.5rem;

// 項目
.layouter__input
	width: 100%
	&--LAYOUTER_SEPARATOR_FIELD
		hr
			height: 2px
			background-color: gray
	& option
		&:checked
			color: var(--color-font-default)

// ポップオーバー
.layouter__popover
	display: none
	position: absolute
	top: 0
	left: 20px
	background: $color-white
	border: 1px solid var(--color-border-30)
	box-shadow: 0 0 3px rgba(0,0,0,.2)
	z-index: 11
	margin-top: -17px
	&--bottom
		top: 100%
	&--in
		display: block
	&__title
		margin: 0
		padding: 0 14px
		color: #a1a1a1
		font-size: 13px
		line-height: 30px
		background-color: #f4f4f4
	&__content
		@extend .layouter__clear-fix
		padding: 9px 14px

.layouter__cols
	width: 200px
	height: 40px
	padding: 3px
	border: 1px solid #c7c7c7
	margin-top: 8px
	&:first-child
		margin-top: 0
	&:hover
		border-color: #ff8228
	&__1
	&__2
		display: block
		position: relative
		border: 1px dashed #c7c7c7
		height: 100%
		background: #f4f4f4
		&:after
			content: ""
			display: block
			width: 30%
			height: 2px
			background-color: #a3a3a3
			position: absolute
			left: 50%
			top: 50%
			margin-left: -15%
			margin-top: -1px
	&__1
		width: 100%
	&__2
		float: left
		margin-left: 4px
		width: 94px
		&:first-child
			margin-left: 0

// モーダル
.layouter__modal
	display: none
	&--open
		display: block
		width: 100%
		height: 100%
		position: fixed
		top: 0
		left: 0
		z-index: 10
		overflow: auto
	&__dialog
		position: absolute
		left: 50%
		top: 150px
		width: 740px
		margin-left: -370px
		margin-bottom: 100px
		padding: 40px
		border-radius: 5px
		background-color: $color-white
		z-index: 20
	&__close
		position: absolute
		top: 16px
		right: 16px

// 従業員フォーム
.member-form
	.ReactTabs
		width: 80%
		&__TabPanel
			min-height: 600px

// 施策フォーム
.program-form
	width: 80%
	background: $color-white
	border: 1px solid var(--color-border)

.member-edit
.program-edit
	.layouter__field
		padding: 9px
		min-height: auto
		&:hover
		&--empty
			background-color: transparent
			border-color: transparent

// 従業員作成・編集
.member-edit
	.layouter__tab
		padding-right: 0
		&__label
			padding-right: 20px
			cursor: pointer
	&__form
		padding: 20px
		background-color: $color-white
	&--preview
		display: table
		width: 100%
		.member-edit__tabs
		.member-edit__preview
			display: table-cell
		.member-edit__preview
			width: 200px
		.member-edit__tabs
			padding-left: 20px
		.ReactTabs
			margin-top: -5px

// 従業員編集プレビュー
.member-edit__preview
	background: #999
	min-height: 600px
	padding: 30px 20px
	&__image
		display: block
		margin: 0 auto 10px
	&__name
		color: $color-white
		font-size: 16px
		text-align: center
		margin-bottom: 30px
	&__fields
		color: $color-white
		font-size: 12px
	&__field
		padding: 13px 0
		border-bottom: solid 1px #aaa
		&__key
			border-left: solid 2px #eee
			padding-left: 6px
			color: #eee
			font-size: 11px
			line-height: 1.2
		&__value
			margin-top: 5px
			line-height: 1.7


// 従業員作成・編集
.program-edit
	background: $color-white
	border: 1px solid var(--color-border)

#programHistoryModal
	.layouter
		&__panel-group
			padding-bottom: 60px
		&__field
			&:hover
				background-color: transparent
				border-color: transparent

// Utils
.member-form__utils
	.input-calendar-wrapper
		display: none

.workflow
	&__apply
		&__form
			&__layouter
				width: 590px
				.tabs
					bottom: -1px
