.flow
	margin-top: 40px
	&__row
		border-top: 1px solid var(--color-border-30)
		border-bottom: 1px solid var(--color-border-30)
		background: #f7fdff
		padding: 14px 0
		display: flex
		flex-wrap: wrap
		justify-content: space-around
		&--arrow
			text-align: center
			padding: 14px 0
			&:before
				content: ""
				background: url(/images/ic_arrow_down_03.png) no-repeat
				background-size: contain
				width: 12px
				height: 14px
				display: inline-block
	&__column
		&--left
		&--right
			width: 30%
			text-align: left
			font-size: 1.3rem
			line-height: 1.5
		&--center
			width: 40%
			text-align: center
			display: flex
			flex-wrap: wrap
			justify-content: center
		&--left
			padding-left: 20px
		&--right
			&.checked
				background: url(/images/ic_check.png) no-repeat right 20px center
				background-size: 16px 13px
	&__check-user
		display: block
		margin: 0 3px
		position: relative
		text-align: center
		&__icon
			display: block
			width: 14px
			height: 19px
			background: url(/images/ic_flowuser_off.png) no-repeat
			background-size: contain
			&.checked
				background: url(/images/ic_flowuser.png) no-repeat
			&:hover + .flow__check-user__name
				opacity: 1
				visibility: visible
		&__name
			position: absolute
			opacity: 0
			visibility: hidden
			top: -35px
			left: 0
			right: 0
			margin: 0 -60px
			background: #fff
			border: 1px solid var(--color-border-30)
			padding: 5px 0
			border-radius: 2px
			box-shadow: 0 1px 1px rgba(100,100,100,.15)
			&:after
				content: ""
				position: absolute
				bottom: -4px
				left: 0
				right: 0
				margin: auto
				width: 5px
				height: 5px
				display: block
				background: #fff
				border-top: 1px solid var(--color-border-30)
				border-left: 1px solid var(--color-border-30)
				transform: rotate(-135deg)
	&__table
		color: $color-gray-01
		font-size: 14px
		&__cell
			padding: 10px 10px 10px 0
			vertical-align: top
			text-align: left
			&--verticalMiddle
				vertical-align: middle
		&__arrow
			height: 14px
			background: url(/images/ic_arrow_down_03.png) no-repeat left 20px center
			background-size: contain
		&__btns
			padding: 10px 0
			&> *
				margin: 0 3px
