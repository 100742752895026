//
// 顔写真付きのカード（従業員一覧）
//
.faceCard
	background-color: #fff
	color: var(--color-font-default)
	height: 100%
	padding: 30px 12px 0 20px
	position: absolute
	right: 0
	top: 0
	width: 580px
	&__section-name
		font-size: 16px
		line-height: 1
	&__button
		clearfix()
		height: 30px
		position: absolute
		right: 20px
		top: 20px
		width: 250px
		&--add-user,
		&--delete-section
			background-color: #f08200
			border-radius: 2px
			color: #fff
			cursor: pointer
			font-size: 12px
			height: 30px
			line-height: 1
			padding: 9px 0
			text-align: center
			width: 120px
		&--add-user
			float: right
		&--delete-section
			float: left
			&.disabled
				background-color: #f4f4f4
				cursor: default
				color: #d0d0d0
	&__employees
		font-size: 12px
		height: calc(100% - 45px)
		overflow: auto
		padding: 0 20px
		border-left 1px solid var(--color-border)
	&__employee
		background-color: #fff
		border: 2px solid transparent
		color: var(--color-font-default)
		height: 140px
		list-style: none
		margin-bottom: 20px
		position: relative
		width: 240px
		float: left
		margin: 0 1px 20px
		&.is_primary
			border: 2px solid #ffe0ca
			.tree__employee-list__employee__inner
				border: 3px solid #ffe0ca
		&__inner
			display: block
			color: var(--color-font-default)
			border: 3px solid #f4f4f4
			height: 100%
			padding: 10px
			padding-bottom: 0
			position: relative
			width: 100%
		&__photo
			background-color: #ddd
			height: 96px
			width: 96px
		&__button--remove
			background-image: url(/images/ic_del3.png)
			cursor: pointer
			height: 20px
			position: absolute
			right: -10px
			top: -10px
			width: 20px
		&__data
			font-size: 12px
			left: 114px
			line-height: 1.5
			position: absolute
			top: 10px
			> *
				margin-bottom: 6px
		&--dragging
			opacity: .6
			position: absolute
			z-index: 500
			margin: 0
			pointer-events: none
		&__handle
			height: 140px
			left: -1px
			position: absolute
			top: -1px
			width: 240px
			z-index: 50
		&__power-badges
			margin-top: 2px
			text-align: center
			width: 106px
		&__joined-section-count
			background-color: #f08200
			border-radius: 10px
			bottom: 5px
			color: #fff
			font-size: 12px
			height: 20px
			line-height: 20px
			position: absolute
			right: 5px
			text-align: center
			width: 20px
		&__power-badge
			background-color: #f2f2f2
			border-radius: 9px
			display: inline-block
			font-size: 12px
			height: 18px
			line-height: 1
			margin-right: 2px
			padding: 3px 10px
		&__relativePhoto
			width: 100%
			height: 100%
			object-fit: scale-down
			&__wrap
				display: block
				width: 100%
				height: 100%
				max-width: 96px
				max-height: 96px
