
/* フォームデフォルトスタイルリセット */
input, button, textarea, select
	margin: 0;
	padding: 0;
	background: none;
	border: none;
	border-radius: 0;
	outline: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;

/* フォーム内見出し */
.formHead
	margin-bottom: 20px
	display: flex
	flex-wrap: wrap
	justify-content: flex-start
	align-items: center
	color: var(--color-font-default)
	&--mt30
		margin-top: 30px
	&--mb0
		margin-bottom: 0
	&--justify
		justify-content: space-between
.formHeading
	margin-right: 20px
	color: var(--color-font-default)
	font-size: 16px
	font-weight: bold
.sso-formHeading
	text-align: left;
	color: var(--color-font-default)
	font-size: 16px
	font-weight: bold

/* フォーム内注釈 */
.formNote
	color: var(--color-font-default)
	font-size: 10px
	line-height: 1.5

/* フォーム内エラー */
.formError
	color: #f00
	font-size: 10px
	line-height: 1.5
	&--sections
		color: #f00
		font-size: 14px
		line-height: 1.5
	&--large
		color: #f00
		font-size: 14px
		line-height: 1.5
	&--middle
		color: #f00
		font-size: 11px
		line-height: 1.5
.formWarn
	color: var(--color-orange-01)
	font-size: 10px
	line-height: 1.5

/* フォームテーブル */
.formTable
	color: var(--color-font-default)
	font-size: 14px
	&--large
		min-width: 500px
	&__row
		&--blank
			height 65px
		&--h30
			height 30px
	&__cell
		padding: 10px
		vertical-align: top
		text-align: left
		&--middle
			vertical-align: middle
		&__box
			padding: 5px 0
		&__item
			display: inline-block
		&__input
			&__disabled
				input
					background-color: #ddd
	&__th
		min-width: 200px
		vertical-align: top
		&--label
			vertical-align: middle
			padding: 10px
		&--auto
			min-width: initial
			vertical-align: middle
		&--top
			padding-top: 20px
			vertical-align: top
		&--vmiddle
			vertical-align: middle
	&--center
		margin: auto
	&--center02
		margin: 20px auto 0
	&__block
		display: block
		margin-bottom: 10px

/* ログインフォーム */
.login__form
    .sso-input
		&--middle
			width: 280px
			height: 35px
			font-size: 16px
			&::placeholder
				padding-left: 0
				font-size: 16px
		&--submit
			display: block
			width: 264px
			margin: 35px auto 0
			border: 0;
			background: $color-orange-01
			border: solid 1px $color-orange-01
			border-radius: 0
			font-size: 16px
			color: $color-white;
			text-align: center
			line-height: 1.5
			letter-spacing: 3px
			cursor: pointer
			border-radius: 6px
			transition: all ease .3s
			&:hover
				background: #fd9419e8;
	input
		font-size: 14px
		background: $color-white
		color: var(--color-font-default)
		border: solid 1px var(--color-border)
		padding: 9px 14px
		overflow: hidden
		border-radius: 3px
		outline: none
		&:focus
			border: solid 1px $color-orange-01
		&::placeholder
			color: var(--color-font-gray-02)
		&[type="date"]
			padding: 4px 8px
	.error
		display: none;
	.js-error
		input
			border: solid 1px #d80c0c
			background: #fff7f7;
		.error
			display: block;
	.input
		&--middle
			width: 342px
			height: 46px
			font-size: 16px
			&::placeholder
				padding-left: 0
				font-size: 16px
		&--label
			display: inline-block
			margin: 15px auto 0
			span
				padding-left: 12px
				font-size: 13px
				color: var(--color-font-default)
			&--co
				&--login
					display: none;
					&:before
						content: url(/images/ic_company_01.png);
						position: relative;
						left: 0;
						top: 0;
						z-index: 1;
						vertical-align: middle;
				position: relative;
				padding-left: 2em;
				&:before
					content: url(/images/ic_company_01.png);
					position: absolute;
					left: 0;
					top: 0;
					z-index: 1
			&--id
				&--login
					display: none;
					&:before
						content: url(/images/ic_user_01.png);
						position: relative;
						left: 0;
						top: 0;
						z-index: 1;
						vertical-align: middle;
				position: relative;
				padding-left: 2em;
				&:before
					content: url(/images/ic_user_01.png);
					position: absolute;
					left: 0;
					top: 0;
					z-index: 1
			&--pass
				&--login
					display: none;
					&:before
						content: url(/images/ic_pw_01.png);
						position: relative;
						left: 0;
						top: 0;
						z-index: 1;
						vertical-align: middle;
				position: relative;
				padding-left: 2em;
				&:before
					content: url(/images/ic_pw_01.png);
					position: absolute;
					left: 0;
					top: 0;
					z-index: 1
		&--checkbox
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			background: url("/images/ic_checkbox.png") no-repeat;
			display: inline-block;
			height: 14px;
			margin: 0;
			padding: 0;
			vertical-align: middle
			width: 14px;
			&:checked
				background: url("/images/ic_checkbox_c.png") no-repeat;
		&--submit
			display: block
			width: 342px
			margin: 35px auto 0
			border: 0;
			background: $color-orange-01
			border: solid 1px $color-orange-01
			border-radius: 0
			font-size: 16px
			color: $color-white;
			text-align: center
			line-height: 1.5
			letter-spacing: 3px
			cursor: pointer
			border-radius: 6px
			transition: all ease .3s
			&:hover
				background: #fd9419e8;

.inputflatpickr__mutlcol
	float: left
	width: 11em

.react-input-flatpickr-Box
	width: 140px
	position: relative;

	.main__filter__item &
		float: left
	.inputflatpickr__mutlcol > &
		width: 100% !important
	.member-edit.member-edit &
		float: left
		width: 180px !important
	.member-edit__preview .main__filter__item &
		width: 8em !important
	.layouter &
		width: 100% !important
	.tree__modal--save__container &
		width: 220px !important
	.tree__modal--save__userplace &
		width: 110px !important
.react-input-flatpickr-Input
	border: solid 1px var(--color-border)
	background-color: $color-white
	padding: 6px 5px 5px
	width: calc(100% - 25px)
	margin-right: -20px
	color: var(--color-font-default)
	font-size: 13px
	&:focus
		border-color: $color-orange-01
	&--w60
		width: 60px
	&--w100
		width: 100px
		height: 2.5em
	&--w100-ml10
		width: 100px
		height: 2.5em
		margin-left: 10px
	&--w400
		width: 400px
	&--wide
		width: 100%
	&:disabled
		background-color: #ddd
	&:not([value=""])::-webkit-input-placeholder
		color: transparent
.react-input-flatpickr-Button
	position: absolute
	right: 0
	top: 0
	border: solid 1px var(--color-border)
	font-size: 13px
	margin-left: -1px
	background: #eee
	transition: .2s
	cursor: pointer
	&:hover
		background: #ccc;
.react-input-flatpickr-Button
	display: inline-block
	font-size: 1em
	padding: 4.5px

@media all and (-ms-high-contrast:none)
	.flatpickr-day
		flex-basis: 13.28% !important

/* 汎用フォームパーツ */
.form
	// 1行テキストボックス
	.react-input-flatpickr-Box
		width: 270px

	&__text
		border: solid 1px var(--color-border)
		background-color: $color-white
		padding: 6px 5px 5px
		width: 270px
		color: var(--color-font-default)
		font-size: 13px
		&:focus
			border-color: $color-orange-01
		&--w60
			width: 60px
		&--w100
			width: 100px
			height: 2.5em
		&--w100-ml10
			width: 100px
			height: 2.5em
			margin-left: 10px
		&--w400
			width: 400px
		&--wide
			width: 100%
		&:disabled
			background-color: #ddd
		&:not([value=""])::-webkit-input-placeholder
			color: transparent
		&::placeholder
			color: var(--color-font-default)
			opacity: 0.5
		&--fz14
			font-size: 14px

	// テキストエリア
	&__textarea
		border: solid 1px var(--color-border)
		background-color: $color-white
		padding: 6px 5px 5px
		width: 540px
		min-height: 140px
		color: var(--color-font-default)
		font-size: 13px
		&:focus
			border-color: $color-orange-01
		&--w100
			width: 100px
		&--w270
			width: 270px
		&--w400
			width: 400px
		&--w430
			width: 430px
		&:disabled
			background-color: #ddd
	// ラジオボタン
	&__radio__label
		white-space: normal !important
		word-break: break-all
		display: inline-block
		margin-right: 20px
		padding: 5px 0 5px 20px
		background: url(/images/form_radio_off.png) no-repeat left center
		cursor: pointer
		&--vertical
			display: block
		&--vertical2
			width: 145px
			margin-right: 0
		// テキストがない場合
		&--notext
			display: inline-block
			width: 14px
			height: 14px
			padding: 0
			margin: 0
		// 歯車マーク
		&.data__edit
			background-image: url(/images/ic_controll.png)
			width: 16px
			height: 16px
		// チェックされた状態
		&--checked
			background-image: url(/images/form_radio_on.png)
			&.data__edit
				background-image: url(/images/ic_controll.png)
		&--disabled
			cursor: default
	// ラジオボタン
	&__radio
		display: none
	// チェックボックスラベル
	&__checkbox__label
		white-space: normal !important
		word-break: break-all
		display: inline-block
		margin-right: 20px
		padding: 5px 0 5px 20px
		background: url(/images/form_check_off.png) no-repeat left center
		cursor: pointer
		&--vertical
			display: block
		&--vertical2
			width: 10em
			margin: 10px 0
		&--vertical3
			width: 180px
			margin-right: 0
		// テキストがない場合
		&--notext
			display: inline-block
			width: 14px
			height: 14px
			padding: 0
			margin: 0
		// チェックされた状態
		&--checked
			background-image: url(/images/form_check_on.png)
		&--disabled
			cursor: default
	// チェックボックス
	&__checkbox
		display: none
	// セレクトボックス
	&__select
		border: solid 1px var(--color-border)
		background: $color-white url(/images/form_select.png) no-repeat 95% center
		padding: 6px 5px 5px
		width: 270px
		color: var(--color-font-default)
		font-size: 13px
		&--multi
			background-image: none
		&:focus
			border-color: $color-orange-01
		&::-ms-expand
			display: none
		&--short
			width: 100px
			margin: 0 10px
		&--w80
			width: 80px
		&--w120
			width: 120px
		&--w190
			width: 190px
		&--w240
			width: 240px
		&--w320
			width: 320px
		&--w100p
			width: 100%
		&--auto
			width: auto
			height: 2.5em
			padding-right: 1.5em
			margin-left: 10px
		&--withpull
			color: var(--color-font-default)
			background: $color-white url(/images/form_select.png) no-repeat 98% center
		option
			&:checked
				color: var(--color-font-default)
			&:focus
				color: var(--color-font-default)

		& + .form__select
			margin-left: 10px
		&--h30
			line-height: 16px
	// ファイル選択
	&__file
		display: none
	&__file__btn
		display: inline-block
		cursor: pointer
		vertical-align: bottom
		&--disabled
			cursor: default!important
			&:hover
				opacity: 1!important
	&__file__image
		vertical-align: bottom
		width: calc(100% - 100px)
		margin-right: 10px
	&__file__name
		display: inline-block
		padding-left: 10px
		&--import
			width: 280px
			margin-top: 5px
			color: #a9a9a9
			line-height: 1.3
		&__link
			text-decoration: underline
			&:hover
				text-decoration: none
	&__file__preview
		display: inline-block
		position: relative
		z-index: 1
		img
			max-width: 150px
			width: auto
			height: auto
		.deselect__btn
			display: block
			width: 32px
			height: 32px
			background: url(/images/ic_del2.png) no-repeat center center
			background-size: contain
			position: absolute
			top: -12px
			right: -12px
			cursor: pointer
			z-index: 10
		&.deletable
			&:after
				content: ""
				display: block
				width: 32px
				height: 32px
				background: url(/images/ic_del2.png) no-repeat center center
				background-size: contain
				position: absolute
				top: -12px
				right: -12px
				cursor: pointer
				z-index: 10
	&__file__output__list
		display: flex
		flex-wrap: wrap
		justify-content: flex-start
		margin-left: -5px
		li
			width: 110px
			padding: 5px
			overflow: hidden
			text-overflow: ellipsis
			white-space: nowrap
			img
				width: 100%
				height: auto
	// ボタン
	&__button
		border: 0
		background: none
		padding: 0
		&--cancel
			position: absolute
			top: 50px
			right: 51px
	&__toggle
		&--note
			display: inline-block
			color: #84bafd
			cursor: pointer
			font-size: 13px
			line-height: 1.4
			&::after
				content: ""
				display: inline-block
				width: 10px
				height: 10px
				border-top: 2px solid #999
				border-right: 2px solid #999
				transform: translateY(-2px) rotate(135deg)
				margin-left: 6px
		&--open
			&::after
				transform: translateY(2px) rotate(-45deg)
	&--note
		font-size: 13px
		line-height: 1.4
		color: #999
		padding: 5px 0

@media screen and (max-width: 1050px)
	input[data-login]
		&.input--middle
			width: 85%;
			height: initial
	input[data-sso-login]
		&.sso-input--middle
			width: 85%;
			height: initial
	.login__form
		input
			padding: 9px calc(7px + 1vmin)
		.error
			display: none;
		.js-error
			input
				border: solid 1px $color-red
				background: #fff7f7;
			.error
				display: block;
		.input
			&--middle
				width: 100%
				&::placeholder
					padding-left: 0
			&--label
				display: inline-block
				margin: 15px auto
				span
					padding-left: 1vmin
					font-size: 13px
					color: var(--color-font-default)
				&--co
					&--login
						display: unset;
					position: relative;
					padding-left: 2em;
					&:before
						content: url(/images/ic_company_01.png);
						position: absolute;
						left: 0;
						top: 0;
						z-index: 1
				&--id
					&--login
						display: unset;
					position: relative;
					padding-left: 2em;
					&:before
						content: url(/images/ic_user_01.png);
						position: absolute;
						left: 0;
						top: 0;
						z-index: 1
				&--pass
					&--login
						display: unset;
					position: relative;
					padding-left: 2em;
					&:before
						content: url(/images/ic_pw_01.png);
						position: absolute;
						left: 0;
						top: 0;
						z-index: 1
			&--checkbox
				-webkit-appearance: none;
				-moz-appearance: none;
				appearance: none;
				background: url("/images/ic_checkbox.png") no-repeat;
				display: inline-block;
				height: 14px;
				margin: 0;
				padding: 0;
				vertical-align: middle
				width: 14px;
				&:checked
					background: url("/images/ic_checkbox_c.png") no-repeat;
			&--submit
				display: block
				width: 78%
				margin: 15px auto 0
				border: 0;
				background: $color-orange-01
				border-radius: 3px
				font-size: 16px
				font-weight: 700
				color: $color-white
				text-align: center
				line-height: 2
		.sso-input
			&--middle
				width: 100%
				&::placeholder
					padding-left: 0
			&--submit
				display: block
				width: 80%
				border: 0;
				background: $color-orange-01
				border-radius: 3px
				font-size: 16px
				font-weight: 700
				color: $color-white
				text-align: center
				line-height: 2

.member-edit__preview
	.main__filter__item
		padding: 0 !important;
	.input-calendar
		width: 75%
		float: left

// カレンダーから日付入力
.form__date
	border: solid 1px var(--color-border)
	background-color: $color-white
	padding: 6px 5px 5px
	width: 270px
	color: var(--color-font-default)
	font-size: 13px
	&--w190
		width: 190px
	&:focus
		border-color: $color-orange-01

.input-calendar
	display: inline-block
	position: relative

.input-calendar-wrapper
	// .layouter__input.layouter__input--date
	// front_dev_react/src/js/view/components/PersonalEmployeeField.jsx 80行目付近
	// whiteSpace: 'pre' との競合
	white-space: normal !important

	position: absolute
	padding: 5px 12px
	border: 1px solid #c4c4c4
	border-radius: 5px
	box-shadow: 0 0 9px 5px rgba(0,0,0,0.05)
	background-color: #fff
	text-align: center
	left: 140px
	-webkit-touch-callout: none
	-webkit-user-select: none
	-moz-user-select: none
	-ms-user-select: none
	user-select: none
	z-index: 20

.input-calendar-wrapper--calendaronly
	.input-calendar
		display: inline-block !important
		position: relative


.input-calendar .input-calendar-field
	width: 120px
	border: 1px solid #ccc
	border-radius: 4px
	font-family: 'Pontano Sans', sans-serif
	font-size: 16px
	padding: 2px 8px

.input-calendar .input-calendar-field:focus
	outline: none
	border: 1px solid $color-orange-01

.input-calendar .calendar-icon
	cursor: pointer
	width: 36px
	height: 0
	overflow: hidden
	padding-top: 29px
	background: var(--color-border) url("/images/ic_calendar.png") no-repeat center center
	color: #ffffff
	position: absolute
	right: 0
	top: 0

.input-calendar .days,
.input-calendar .months,
.input-calendar .years
	width: 189px
	padding-bottom: 2px

.input-calendar .cell
	display: inline-block
	text-align: center
	cursor: pointer
	border: 1px solid #fff
	-webkit-touch-callout: none
	-webkit-user-select: none
	-moz-user-select: none
	-ms-user-select: none
	user-select: none

.input-calendar .cell:hover
	color: $color-orange-01
	border: 1px solid $color-orange-01
	border-radius: 4px

.input-calendar .cell.current
	background: $color-orange-01
	color: #fff
	border-radius: 4px
	opacity: .8

.input-calendar .day
	width: 25px
	height: 25px
	line-height: 25px

.input-calendar .day.prev,
.input-calendar .day.next
	opacity: .4

.input-calendar .month
	width: 58px
	height: 38px
	line-height: 38px

.input-calendar .year
	width: 58px
	height: 38px
	line-height: 38px

.input-calendar .days-title .cell
	height: 25px
	line-height: 28px
	opacity: 0.5
	cursor: default

.input-calendar .days-title .cell:hover
	color: var(--color-font-default)
	border: 1px solid #fff

.input-calendar .navigation-title
	width: 100px
	display: inline-block
	cursor: pointer

.input-calendar .years-view .navigation-title
	cursor: default

.input-calendar .years-view .navigation-title:hover
	color: var(--color-font-default)

.input-calendar .navigation-title:hover
	color: $color-orange-01

.input-calendar .icon-wrapper
	cursor: pointer

.input-calendar .icon:hover,
.input-calendar .today-btn:hover,
.input-calendar .icon-wrapper:hover
	color: $color-orange-01

.input-calendar .icon
	cursor: pointer
	width: 20px

.input-calendar .today-btn
	cursor: pointer

.input-calendar .cell.day.today
	position: relative

.input-calendar .cell.day.today:after
	content: '.'
	position: absolute
	bottom: 15px
	font-size: 20px
	color: $color-orange-01

.fromToCalendar
	.input-calendar
		width: 124px
		.form__date
			width: 100%
	.input-calendar-wrapper
		left: 50px
	&--wide
		.input-calendar
			width: 200px
			margin: 0 10px

// 絞り込み条件
.main__filter
	margin-top: 10px
	background: #fff
	box-shadow: 0 1px 0 #dfdfdf
	&__item
		display: inline-block
		padding: 10px 15px
		&:first-child
			padding-left: 20px
		.form__date
			width: auto;
		.input-calendar-wrapper
			z-index: 10
	&__button
		display: inline-block
		font-size: 13px
		a
			cursor: pointer
			&:hover
				text-decoration: underline
	&__save
		display: inline-block

// 仮
.clearSearch
	margin-top: 20px
	font-size: 13px
	text-align: center
