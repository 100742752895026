.tree__modal
	color: var(--color-font-default)
	&--save,
	&--user-add,
	&--user-edit,
	&--history,
	&--export
		display: none
		&__container
			background-color: #fff
			border-radius: 5px
			left: 50%
			margin-left: -259px
			position: absolute
			width: 520px
			z-index: 20
		&__button--close
			position: absolute
			right: 12px
			top: 12px
		&__validation-error
			color: #ff0000
			font-size: 12px
			line-height: 1.5
			text-align: center
	&--save
		&__container
			height: 90%
			max-height: 640px
			padding: 30px
			top: 20%
		&__inner
			height: calc(100% - 20px)
			margin: 10px 0
			overflow: auto
			.form__text,
			.form__textarea,
			.form__date
				width: 220px!important
			.form__date
				height: auto
			.form__textarea
				resize: none
			.input-calendar-wrapper
				left: 0px
			.form__select
				width: 220px
		&__form-radio
			white-space: normal !important
			word-break: break-all
			display: inline-block
			margin-right: 5px
			padding: 8px 0 8px 20px
			background: url(/images/form_radio_off.png) no-repeat left center
			cursor: pointer
			&--checked
				background-image: url(/images/form_radio_on.png)
		&__user-place-text
			padding-left: 10px;
			font-size: 12px;
			width: 100px;
		.buttons
			padding-top: 144px
	&--user-add
		&__container
			color: var(--color-font-default)
			height: 90%
			max-height: 616px
			padding: 30px 10px 30px 36px
			top: 20%
		&__inner
			height: 100%
		&__header
			height: 36px
			width: 435px
		&__heading
			float: left
			font-size: 16px
			line-height: 36px
		&__input--search-name
			background-image: url(/images/ic_search.png)
			background-position: 11px center
			background-repeat: no-repeat
			border: 1px solid var(--color-border)
			border-radius: 2px
			float: right
			font-size: 12px
			height: 36px
			line-height: 1
			padding: 11px
			padding-left: 40px
			width: 220px
		&__button--initial-switch-wrapper
			height: 34px
			margin-top: 20px
			position: relative
			width: 435px
		&__button--initial-switch
			background-color: #f4f4f4
			cursor: pointer
			font-size: 14px
			height: 34px
			line-height: 1
			padding: 10px 0
			position: absolute
			text-align: center
			top: 0px
			width: 34px
			&.selected
				background-color: #f08200
				color: #fff
				cursor: default
			for i in (0..10)
				&:nth-child({i + 1})
					left: 40px * i
		&__user-container
			border-top: 1px solid var(--color-border)
			height: calc(100% - 160px)
			margin-top: 12px
			margin-bottom: 20px
			max-height: 396px
			overflow: auto
			width: 448px
		&__user
			border: 1px solid var(--color-border)
			height: 54px
			position: relative
			width: 100%
			&:nth-child(1)
				border-top-width: 0px
			&:nth-child(odd)
				background-color: #fff
			&:nth-child(even)
				background-color: #f9fcfd
			&__checkbox
				left: 20px
				position: absolute
				top: 20px
			&__name
				font-size: 13px
				left: 65px
				line-height: 1
				position: absolute
				top: 20px
			&__cover
				cursor: pointer
				height: 52px
				left: 0px
				position: absolute
				top: 0px
				width: 432px
		&__button--select
			background-color: #f08200
			color: #fff
			cursor: pointer
			font-size: 12px
			height: 38px
			margin: 20px auto 0
			padding: 13px 0
			text-align: center
			width: 150px
	&--user-edit
		&__container
			font-size: 12px
			padding: 50px 120px
			top: 27%
			width: 520px
		&__inner
			width: 100%
		&__user-data
			line-height: 2
			overflow: hidden
			zoom: 1
			&__image
				float: left
				height: 96px
				width: 96px
				background-color: #ddd
			&__relativeImage
				width: 100%
				height: 100%
				object-fit: scale-down
				&__wrap
					display: block
					width: 96px
					height: 96px
					float: left
			&__text
				float: right
				width: 151px
		&__input-user-power
			margin-top: 25px
			&__input
				&--is-super
					margin-right: 48px!important
				&--is-approval
					margin-right: 0!important
		&__input-position
			margin-top: 30px
			&:after
				clear: both
				content: ''
				display: block
			&__label
				float: left
				line-height: 36px
			&__select
				float: right
				height: 36px
				width: 230px!important
		&__input-primary-section
			&__label
				display: block!important
				padding: 10px 0 10px 20px!important
	&--user-select
		&__user
			cursor: pointer
	&--history
		&__container
			color: var(--color-font-default)
			max-height: max-content
			padding: 40px 34px 30px
			top: 135px
		&__inner
			height: 100%
		&__heading
			font-size: 16px
		&__list-wrapper
			height: calc(100% - 50px)
			margin-top: 25px
			overflow: auto
		&__list
			width: 436px
		&__list-header,
		&__list-item
			border: 1px solid var(--color-border)
			font-size: 12px
			overflow: hidden
			text-align: center
			width: 100%
			&__base-date,
			&__name,
			&__created_at,
			&__delete
				float: left
				line-height: 1.5
				padding: 11px 5px
			&__base-date,
			&__created_at
				letter-spacing: -0.3px
				width: 90px
			&__name
				width: 148px
				&--link
					color: #49b0be
					&:hover
						text-decoration: underline
						cursor: pointer
			&__delete
				width: 106px
			&__button--delete
				background-color: #999
				border-radius: 5px
				color: #fff
				cursor: pointer
				display: inline-block
				height: 30px
				line-height: 30px
				padding: 0
				text-align: center
				width: 80px
				a
					color: #fff
					display: block
					height: 100%
					width: 100%
					&:link, &:visited, &:hover, &:active
						color: #fff
		&__list-header
			font-weight: bold
			> *
				height: 50px
				line-height: 28px
		&__list-item
			border-top-width: 0px
			> *
				height: 54px
			&:nth-child(odd)
				background-color: #fff
			&:nth-child(even)
				background-color: #f9fcfd
			&__base-date,
			&__name,
			&__created_at
				border-right: 1px solid var(--color-border)
			&.is_current
				.tree__modal--history__list-item__base-date
					border-left: 4px solid #f08200
					padding-left: 1px
	&--export
		&__container
			color: var(--color-font-default)
			max-height: max-content
			padding: 40px 34px 30px
			top: 135px
		&__inner
			height: 100%
		&__heading
			font-size: 16px
		&__body
			height: calc(100% - 50px)
			margin-top: 25px
			overflow: auto
			&__output-type
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				margin: 1rem 0 0 0;
				&__ttl
					margin: 0 10% 0 0;
				&__csv-remark
					width: 100%;
					line-height: 1;
					text-align: right;
					padding: 0 71px 0 0;
					margin: 2px 0 0 0;
					font-size: 1.2rem;
	&--import
		display: none;
		&__container
			background-color: #fff
			border-radius: 5px
			left: 50%
			margin-left: -365px
			position: absolute
			z-index: 20
			width: 730px;
			height: 90%
			max-height: 660px
			padding: 30px
			top: 20%
		&__inner
			height: calc(100% - 20px)
			margin: 10px 0
			overflow: auto
			.form__text,
			.form__textarea,
			.form__date
				width: 220px!important
			.form__date
				height: auto
			.form__textarea
				resize: none
			.input-calendar-wrapper
				left: 0px
			.form__select
				width: 220px
.tree__modal--user-add__user
	.form__checkbox__label
		font-size: 13px
		left: 40px
		line-height: 1
		position: absolute
		top: 19px
