.modal__popup--parent.popup__box
	display: block

.modal__popup.popup__box
	transition: .4s

.popup-enter-active,
.popup-leave-active,
.popup-enter-active .popup__box,
.popup-leave-active .popup__box
	transition: .4s

.popup-enter,
.popup-leave-active
.popup-enter .popup__box,
.popup-leave-active .popup__box
	opacity: 0
