.toggle
	display: none
	& + .toggle__btn
		outline: 0
		display: block
		height: 38px
		position: relative
		cursor: pointer
		user-select: none
		&:after,
		&:before
			position: relative
			display: block
			content: ""
			height: 100%
			background: $color-white
		&:after
			right: 0
		&:before
			display: none
.toggle--check01
	& + .toggle__btn
		background: $color-gray-02
		border-radius: 2em
		border: 1px solid $color-gray-02
		padding: 2px
		transition: all .4s ease
		width: 70px
		display: inline-block
		vertical-align: middle
		margin: 0 5px
		&:after
			width: 50%
			border-radius: 50%
			background: #fff
			transition: all .2s ease
			left: 0
	&:checked + .toggle__btn
		&:after
			left: 50%
.toggle--name__before
.toggle--name__after
	display: inline-block
	vertical-align: middle
.toggle--name__before
.toggle--name__after
	&--list
	&--block
		position: relative
		display: inline-block
		top: 8px
		width: 28px
		height: 28px
	&--list
		background: url('/images/ic_list.png') no-repeat;
	&--block
		background: url('/images/ic_block.png') no-repeat;
	&--self
	&--others
		position: relative
		display: inline-block
		top: 14px
		width: 22px
		height: 40px
	&--self
		background: url('/images/ic_self.png') no-repeat center bottom;
	&--others
		background: url('/images/ic_others.png') no-repeat center bottom;

.toggle--check02
	& + .toggle__btn
		backface-visibility: hidden
		font-family: sans-serif
		border: 1px solid $color-gray-03
		border-radius: 1px
		width: 160px
		&:after,
		&:before
			display: inline-block
			width: 80px
			text-align: center
			position: absolute
			top: 0
			line-height: 38px
		&:before
			left: 0
			content: attr(data-tg-off)
			color: $color-white
			background: $color-gray-02
			border-top-left-radius: 1px
			border-bottom-left-radius: 1px
			z-index: 10
			-webkit-font-smoothing: subpixel-antialiased
		&:after
			right: 0
			color: var(--color-font-default)
			content: attr(data-tg-on)
			background: $color-white
			border-top-right-radius: 1px
			border-bottom-right-radius: 1px
			z-index: 0
			-webkit-font-smoothing: antialiased
	&:checked + .toggle__btn
		&:before
			color: var(--color-font-default)
			background: $color-white
			z-index: 0
			-webkit-font-smoothing: antialiased
		&:after
			color: $color-white
			background: $color-gray-02
			z-index: 10
			-webkit-font-smoothing: subpixel-antialiased
	& + .toggle__btn--reverse
		&:before
			left: auto
			right: 0
		&:after
			left: 0
			right: auto


.toggle__item
	display: inline-block
	vertical-align: middle
	& + .toggle__item
		margin-left: 15px

.toggle--check03
	& + .toggle__btn
		background: $color-white
		border-radius: 2em
		border: 3px solid $color-gray-02
		padding: 2px
		transition: all .4s ease
		width: 70px
		display: inline-block
		vertical-align: middle
		margin: 0 5px
		height: 36px
		&:after
			width: 46%
			border-radius: 50%
			background: #999
			transition: all .2s ease
			left: 0
	&:checked + .toggle__btn
		&:after
			left: 50%

.toggle--check04
	& + .toggle__btn
		position: relative
		display: inline-block
		width: 60px
		height: 34px
		background-color: #ccc
		border-radius: 100vh
		transition: all .4s
		&:before
			display: block
			position: absolute
			content: ''
			width: 43.33%
			height: 0
			top: 0
			bottom: 0
			left: 6.66%
			padding-top: 43.33%
			box-sizing: content-box
			border-radius: 50%
			margin: auto
			transition: all .4s
		&:after
			display: block
			position: absolute
			content: 'OFF'
			color: #fff
			font-size: .7em
			text-align: center
			line-height: 1em
			width 50%
			height: 1em
			top: 0
			bottom: 0
			right: 0
			background: none
			margin: auto
	&:checked
		& + .toggle__btn
			background-color: $color-orange-01
			&:before
				left: 50.01%
			&:after
				content: 'ON'
				right: 50%

.toggle--check05
	& + .toggle__btn
		position: relative
		display: inline-block
		width: 40px
		height: 24px
		background-color: #ccc
		border-radius: 100vh
		transition: all .4s
		margin-left: 10px
		margin-right: 10px
		&:before
			display: block
			position: absolute
			width: 43.33%
			height: 0
			top: 0
			bottom: 0
			left: 6.66%
			padding-top: 43.33%
			box-sizing: content-box
			border-radius: 50%
			margin: auto
			transition: all .4s
		&:after
			display: block
			position: absolute
			color: #fff
			font-size: .7em
			text-align: center
			line-height: 1em
			width 50%
			height: 1em
			top: 0
			bottom: 0
			right: 0
			background: none
			margin: auto
	&:checked
		& + .toggle__btn
			background-color: $color-orange-01
			&:before
				left: 50.01%
			&:after
				right: 50%
