.tree__employee-list
	background-color: #fff
	color: var(--color-font-default)
	height: 100vh
	padding: 30px 20px
	position: absolute
	right: 10px
	top: 0
	width: 510px
	&__section-name
		font-size: 14px
		width: 215px
		height: 30px
		line-height: 16px
		&__deleteButton:hover
			color: var(--color-font-default)

	&__button
		clearfix()
		height: 30px
		width: 250px
		&--add-user,
		&--delete-section
			background-color: #f08200
			border-radius: 2px
			color: #fff
			cursor: pointer
			font-size: 12px
			height: 30px
			line-height: 1
			padding: 9px 0
			text-align: center
			width: 120px
		&--add-user
			float: right
		&--delete-section
			float: left
			&.disabled
				background-color: #f4f4f4
				cursor: default
				color: #d0d0d0
	&__employees
		font-size: 12px
		height: calc(100% - 55px)
		overflow: auto
	&__employee
		background-color: #fff
		border: 2px solid transparent
		color: var(--color-font-default)
		height: 140px
		list-style: none
		margin-right: 15px
		margin-bottom: 20px
		position: relative
		width: 220px
		float: left;
		&.is_primary
			border: 2px solid #ffe0ca
			.tree__employee-list__employee__inner
				border: 3px solid #ffe0ca
		&__inner
			border: 3px solid #f4f4f4
			height: 100%
			padding: 10px
			padding-bottom: 0
			position: relative
			width: 100%
		&__photo
			background-color: #ddd
			height: 36px
			width: 36px
		&__relativePhoto
			width: 100%
			height: 100%
			object-fit: scale-down
			&__wrap
				display: block
				width: 36px
				height: 36px
		&__button--remove
			background-image: url(/images/ic_del3.png)
			cursor: pointer
			height: 20px
			position: absolute
			right: -10px
			top: -10px
			width: 20px
			z-index: 10
		&__data
			font-size: 12px
			left: 114px
			line-height: 1.5
			position: absolute
			top: 10px
			> *
				margin-bottom: 6px
		&--dragging
			opacity: .6
			position: absolute
			z-index: 500
			margin: 0
			pointer-events: none
		&__handle
			cursor: all-scroll
			height: 140px
			left: -1px
			position: absolute
			top: -1px
			width: 240px
			z-index: 8
			&--disabled
				cursor: default
		&__power-badges
			margin-top: 2px
			text-align: center
			width: 106px
		&__joined-section-count
			background-color: #f08200
			border-radius: 10px
			bottom: 5px
			color: #fff
			font-size: 12px
			height: 20px
			line-height: 20px
			position: absolute
			right: 5px
			text-align: center
			width: 20px
		&__power-badge
			background-color: #000
			border-radius: 9px
			color: #fff
			display: inline-block
			font-size: 12px
			height: 18px
			line-height: 1
			margin-right: 2px
			padding: 3px 5px



#tabli {
  width: 50%;
  float: left;
  text-align: center;
  background: #ddd;
}
#tabli div {
  display: block;
  width: 100%;
  line-height: 36px;
  text-decoration: none;
  color: var(--color-font-default)
}
#tabcontent1, #tabcontent2 {
  position: absolute;
  overflow: hidden;
  top: 36px;
  width: 100%;
  // height: 100vh;
  box-sizing: border-box;
  padding: 10px;
}
#tabcontent1, #tabcontent2 {
  display: none;
  width: 100%;
  background: #fff;
  padding-left: 2em;
  padding-right: 2em;
}

.active {
  background: #fff;
}
/* #tabcontent1:target, #tabcontent2:target {
  display: block;
}
#tabcontent1:target ~ .tabnav #tabli {
  background: #ddd;
  color:var(--color-font-default);
}
#tabcontent1:target ~ .tabnav #tabli:first-child {
  background: #fff;
  color: #fff;
}
#tabcontent2:target ~ .tabnav #tabli {
  background: #ddd;
  color: var(--color-font-default);
}
#tabcontent2:target ~ .tabnav #tabli:last-child {
  background: #fff;
  color: #fff;
} */
.employeeListTable {
  position: relative;
  border-collapse: collapse;
  border: 1px solid var(--color-border);
  border-left: none;
  border-right: none;
  box-sizing: border-box;
  font-size: 1.1rem;

  & th,
  & td {
    padding: 1em;
    text-align: center;
    vertical-align: top;
	white-space: pre;
  }

  & th {
	  font-weight: bold;
  }

  & th:nth-child(n + 3) {
	  // text-align: left;
  }

  & td {
    // border: 1px solid #ccc;
	border-top: 1px solid var(--color-border);
	vertical-align: middle;
  }

  & td:nth-child(n + 2) {
	background: linear-gradient(to right, #dfdfdf 1px, #fff 1px);
	background-size: 100% 70%;
	background-position: left center;
	background-repeat: no-repeat;
  }
}

.wrapper {
    display: grid;
    column-gap: 1.5em;
		grid-template-columns: 36px 135px;
		background-color: #fff;
		color: #444;
	}

	.box {
		color: var(--color-font-default)
	}

	.a {
		grid-column: 2 / 3;
		grid-row: 1 ;
		text-align: left;
	}
	.b {
		grid-column: 1 ;
		grid-row: 1 / 3;
	}
	.d {
		grid-column: 2;
		grid-row: 2;
		margin-right: auto;
	}
	.sectionName {
		font-size: 120%;
	}

.button-bar {
  width: 100%;
  height: 40px;
  display: grid;
  font-size: 12px;
  grid-template-columns: 140px 140px 100px 55px 105px;
  background-color: #fff;
  color: #444;
  margin-top: 17px;
  margin-bottom: -11px;
	border-top: 1px solid var(--color-border);
	border-bottom: 1px solid var(--color-border);

	.bar.aa,
	.bar.bb {
		color: #f08200;
		// padding: 0 .5em;
	}

	.bar.bb {
		border-left: 1px solid var(--color-border);
		// 非アクティブ時
		&.inactive {
			color: #f4f4f4
			cursor: default !important

			span {
				background: #f4f4f4
			}
		}
	}

	.bar.cc,
	.bar.dd {
		height: 22px;
		margin-top: 7px;
		font-size: 10px;
	}

	.bar.dd {
		margin-left: .3em;
		margin-right: .3em;
		background: #f4f4f4;
		color: #fff;
		border-radius: 2px;
	}

	.bar.ee {
		height: 22px;
		margin-top: 11px;
		margin-left: auto;
		margin-right: auto;
	}
}

.button-bar.button-bar--independence {
	grid-template-columns: 100px 55px 105px;
}

.bar {
  background-color: #fff;
  color: var(--color-font-default)

  .ee{
	width: 100%;
    height: 28px;
	text-align: center;
    line-height: 28px;
	font-size: 12px;
  }
}

.detail-wrapper {
    display: grid;
    grid-gap: 10px;
	grid-template-columns: 10% 90%;
	background-color: #fff;
	color: #444;
	}

	.detail-photo {
		background-color: #ddd
			height: 96px;
			width: 96px;
	}

	.detail-box {
		background-color: #fff;
		color: var(--color-font-default)
	}

	.d-a {
		grid-column: 2 / 3;
		grid-row: 1 ;
	}
	.d-b {
		grid-column: 1 ;
		grid-row: 1 / 3;
		width: 36px;
	}
	.d-d {
		grid-column: 2;
		grid-row: 2;
	}
	.d-e {
		grid-column: 2;
		grid-row: 3;
	}
	.d-f {
		grid-column: 2;
		grid-row: 4;
	}
	.d-g {
		grid-column: 2;
		grid-row: 5;
	}
	.d-h {
		grid-column: 2;
		grid-row: 6;
	}
	.d-i {
		grid-column: 2;
		grid-row: 7;
	}
	.d-j {
		grid-column: 2;
		grid-row: 8;
	}
	.d-k {
		grid-column: 2;
		grid-row: 9;
	}
