/* ----------------------------------------
	program
---------------------------------------- */

.ProgramMemberDataTable
    // スクロールしないテーブル
    .dataTable_fix
        // width: 400px
        .dataTable
            width: 100%
    // スクロールするテーブル
    .dataTable_scroll
        max-width: none
        width: auto

.programMemberHistory
	&__member
		overflow: hidden
	&__image
		float: right
		width: 96px
		text-align: center
		a
			color: $color-gray-02
	&__relativeImage
		width: 100%
		height: 100%
		object-fit: scale-down
		&__wrap
			display: block
			width: 96px
			height: 96px
	&__name
		display: block;
		margin-top: 8px

	// スクロールしないテーブル
	.dataTable_fix
		width: 280px
		.dataTable
			width: 100%

	.main__base
		box-shadow: none
		border: 1px solid var(--color-border)

	.tab__item
		&.is-active
			border-color: $color-gray-02
			background-color: $color-gray-02
			.tab__txt
            	color: $color-white
