.tree__section-list
	background-color: #fff
	height: 42vh
	bottom: -45vh
	left: 0
	min-width: 700px
	padding: 28px 12px 0 13px
	position: absolute
	width: calc(100% - 545px)
	&--extends
		top: 0
		bottom: auto
		width: 100%
		min-width: none
		height: 100%
	&__caption
		color: var(--color-font-gray-02)
		font-size: 16px
		line-height: 1
		margin-left: 7px
	&__sections
		height: calc(100% - 44px)
		margin-top: 23px
		overflow: auto
		display: grid;
		row-gap: 1rem;
		grid-auto-rows: 40px;
		grid-template-columns: 50% 50%;
		.tree__section-list__section--col2
			width: calc(100% - 1em);
	&__section
		border-radius: 2px
		position: relative
		width: 225px
		&__parent
			display: flex;
			height: 100%;
		&__handle
			background-image: url(/images/ic_draggable.png);
			background-repeat: no-repeat;
			background-size: 2em 100%;
			cursor: all-scroll
			width: 2em;
			height: 100%;
		&--dragging
			position: absolute
			z-index: 500
			margin: 0
			pointer-events: none
		&__name
			width: calc(100% - 2em);
			height: 100%;
			padding: .8em 7px
			background-color: #f4f4f4
			color: var(--color-font-default)
			font-size: 12px
		&--col2
			width: 328px
		&--enter
			.tree__section-list__section__parent
				z-index: 100
				box-shadow: 0 1px 4px rgba(0, 0, 0, .2)
