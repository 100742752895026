// SSO用 モーダル
.modal__sso
	background: $color-white
	border-radius: 5px
	background: $color-white
	width: 390px
	max-height: 500px
	padding: 60px 25px
	color: var(--color-font-default)
	font-size: 18px
	text-align: center
	position: absolute
	left: 50%
	overflow: auto
	top: 50%
	transform: translate(-50%,-50%)
	z-index: 20
	&--parent
		display: none
		position: fixed
		width: 100vw
		height: 100vh
		top: 0px
		left: 0px
		z-index: 40

// パスワードリマインダー用 モーダル
.modal__reminder
	background: $color-white
	border-radius: 5px
	background: $color-white
	width: 590px
	max-height: 500px
	padding: 60px
	color: var(--color-font-default)
	font-size: 18px
	text-align: center
	position: absolute
	left: 50%
	overflow: auto
	top: 50%
	transform: translate(-50%,-50%)
	z-index: 20
	&--parent
		display: none
		position: fixed
		width: 100vw
		height: 100vh
		top: 0px
		left: 0px
		z-index: 40

.reminder_message
	margin-bottom: 40px

// 認証アプリ用 モーダル
.modal__authapp
	background: $color-white
	border-radius: 5px
	background: $color-white
	width: 460px
	max-height: 900px
	padding: 60px 25px
	color: var(--color-font-default)
	font-size: 18px
	text-align: center
	position: absolute
	left: 50%
	overflow: auto
	top: 50%
	transform: translate(-50%,-50%)
	z-index: 20
	&--parent
		display: none
		position: fixed
		width: 100vw
		height: 100vh
		top: 0px
		left: 0px
		z-index: 40

.modal
	position: absolute
	top: 0
	left: -24px
	width: calc(100% + 48px)
	height: calc(100% + 48px)
	opacity: 0;
	transition: .3s opacity
	&__bg
		display: block
		position: absolute
		top: 0
		bottom: 0
		left: 0
		right: 0
		background: gray
		opacity: 0.7
		z-index: 90
	&__content
		display: block
		position: absolute
		padding: 10px
		width: 50%
		top: 0
		bottom: 0
		left: 0
		right: 0
		margin: auto
		background: $color-white
		z-index: 100
	&.slideIn
		opacity: 1
		&.slideOut
			opacity: 0
.modal
	&--hide
		position: fixed;
		top: 0
		left: 0
		z-index: 100
		transition: opacity .3s
		opacity: 0
	&--appear
		position: fixed;
		top: 0
		left: 0
		transition: opacity .3s
		opacity: 1
		z-index: 100
		width: 100%



// オーバーレイ（メイン部分のみ）
.main__overlay
	display: none
	width: 100%
	height: 100vh
	position: fixed
	left: 0
	top: 0
	z-index: 10
	&--black
		display: block;
		background-color: rgba(66,66,66,.8)
	&--clear
		background-color: transparent

// モーダル（スライドアップ）
.modal__slideup
	background: $color-white
	width: calc(100% - 354px)
	height: calc(100% - 100px)
	padding: 60px 60px 0
	box-shadow: 0 0 5px 1px rgba(0,0,0,0.4)
	position: fixed
	top: 100%
	z-index: 20
	margin-top: 100px
	margin-left: 254px
	overflow: scroll
	transition: margin-left .5s ease-in-out
	&.def
		margin-left: 0
	&--noshadow
		box-shadow: none
	.form
		display: block
		padding-bottom: 160px
	&--large
		width: calc(100% - 250px)
		margin-left: 225px


.modal__close
	position: absolute
	top: 16px
	right: 16px
	z-index: 30

.nav--close
	.modal__slideup
		margin-left: 64px

// モーダル（ポップアップ）
.modal__popup
	background: $color-white
	border-radius: 5px
	background: $color-white
	width: 590px
	max-height: 500px
	padding: 60px
	color: var(--color-font-default)
	font-size: 18px
	text-align: center
	position: absolute
	left: 50%
	margin-left: -165px
	overflow: auto
	top: 20%
	z-index: 20
	.ReactTabs__TabPanel &
		margin-left: -295px
	&--parent
		display: none
		position: fixed
		width: 100vw
		height: 100vh
		top: 0px
		left: 0px
		z-index: 40
		.ReactTabs__TabPanel &
			width: 100%
			height: 100%
	&--responsive
		width: 80%
		height: 80%
		padding: 40px 60px
		margin-left: -40%
		top: 10%
		text-align: left
		.scrollable
			padding: 20px 0
	.scrollable
		height: 100%
		overflow: scroll
		&--auto
			overflow: auto

	&--employee-conditions
		width: 900px
		height: 80%
		top: 0
		bottom: 0
		left: 0
		right: 0
		margin: auto
		.form
			height: 100%
		table
			width: 100%
			th
				height: 48px
				text-align: left
				padding: 8px 8px 8px 28px
				font-size: 1.3rem
				vertical-align: middle
				&.px-8
					padding-left: 8px
					padding-right: 8px
				&.py-4
					padding-top: 4px
					padding-bottom: 4px
				&.py-12
					padding-top: 12px
					padding-bottom: 12px
				&.text-center
					text-align: center
				&.height-auto
					height: auto
			td
				padding: 14px
			td:first-child
				padding: 8px 14px
				font-size: 1.3rem
			td:last-child
				padding: 8px
		.employeeSearchConditionTable
			.modTable__tr:nth-child(even)
				.modTable__th
				.modTable__td
					background: $color-table-bg-even
	&--analysis
		overflow-y: scroll
		top: 0
		bottom: 0
		margin-top: auto
		margin-bottom: auto
		&XY
			min-height: 370px
			height: 40%
		&Value
			min-height: 480px
			height: 50%
	&--field-list-sort
		top: 50%
		width: 100%
		max-width: 570px
		height: 640px
		max-height: 100%
		margin-left: 0
		transform: translate(-50%, -50%)
	&--w800
		width: 100%
		max-width: 800px
	&--w900
		width: 100%
		max-width: 900px
	&--w1100
		width: 100%
		max-width: 1100px
	&--square
		width: 700px
		height: 700px
	&--center
		margin-left: 0
		transform: translateX(-50%)
		&.modal__popup--verticalCenter
			top: 50%
			transform: translate(-50%, -50%)
	&--scroll
		overflow: scroll

// flexbox
.modal__flex
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	justify-content: flex-start;
	align-items: stretch;
	align-content: stretch;
.modal__flexItem
	box-sizing: border-box
	padding-right: 50px
	&--2col
		width: 50%;
		.formTable__cell
			min-width: 120px

// formBlock
.formBlock
	border-top: solid 1px var(--color-border)
	padding: 40px 0
	&:first-child
		border-top: 0
		padding-top: 0
	&:last-child
		padding-bottom: 0

// モーダル（ポップアップ）
.modal__popup2
	border-radius: 5px
	background: $color-white
	width: 518px
	height: 80%
	padding: 33px 8px 30px 36px
	color: var(--color-font-default)
	font-size: 18px
	text-align: center
	position: absolute
	top: 0
	bottom: 0
	left: 0
	right: 0
	margin: auto
	z-index: 10
	&--parent
		display: none
		position: fixed
		width: 100%
		height: calc(100vh - 62px)
		top: 62px
		left: 0
		z-index: 1
	.main__overlay
		&--black
			display: block;
			background-color: rgba(0,0,0,.4)
	.modal__inner
		overflow: auto
		max-height: 100%
		padding-right: 41px
	.formHeading
		font-weight: normal
	.dataTable
		width: 100%
		border: 1px solid var(--color-border)
		font-size: 1.3rem
		tr.currentData__row
			td:first-child
				border-left: 7px solid #ef7a00;
		td
			height: 54px

.modal__popup
	&-close
		position: absolute
		top: 10px
		right: 10px

.modalFlex
	display: flex
	flex-direction: row
	flex-wrap: nowrap
	justify-content: flex-start
	align-items: stretch
	align-content: stretch
	min-height: 754px
	&__item
		display: inline-block
		width: 100%
		overflow: scroll
		&--profile
			width: 250px
			margin-right: 40px
			background-color: #333
			padding: 0 20px
			color: #fff

.modal--edit-form-history
	// display: flex
	align-items: center
	justify-content: center
	position: fixed
	top: 20%
	left: 30%
	width: 100vw
	height: 100vh
	z-index: 40
	&__inner
		background-color: #fff
		border-radius: 5px
		box-sizing: border-box
		padding 64px 40px 80px
		width: 620px
		z-index: 20
		position: relative
	&__label
		font-size: 16px
		color: var(--color-font-default)
		line-height: 1
	&__table
		margin-top: 28px
		overflow: auto
		width: 100%
		max-height: 270px
		border-top: 1px solid var(--color-border)
		border-left: 1px solid var(--color-border)
		td
			position: relative

@media screen and (max-width: 1050px)
	.reminder_message
		font-size: 5vmin
	.modal__reminder
		background: $color-white
		border-radius: 5px
		width: 90vmin
		max-height: unset
		padding: 60px 25px
		color: $color-gray-01
		font-size: unset
		position: absolute
		left: 50%
		top: 50%
		transform: translate(-50%,-50%)
		margin: 0
	.modal__sso
		background: $color-white
		border-radius: 5px
		width: 90vmin
		max-width: 390px
		max-height: unset
		padding: 60px 25px
		color: $color-gray-01
		font-size: unset
		position: absolute
		left: 50%
		top: 50%
		transform: translate(-50%,-50%)
		margin: 0
