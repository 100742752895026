.text
	&--note
		padding-left: 30px
		padding-bottom: 10px;
		margin-top: -10px;
		font-size: 11px
		color: #49b0be
		border-left: 1px solid var(--color-border)
	&--indent
		text-indent: -1em
		padding-left: 1em
	&--lh125
		line-height: 1.25
	&--lh15
		line-height: 1.5
	&--red
		color: #f00
	&--fz12
		font-size: 12px
	&--fz13
		font-size: 13px
	&--fz14
		font-size: 14px
	&--fz16
		font-size: 16px
	&--fz18
		font-size: 18px
	&--alignLeft
		text-align: left
	&--alignRight
		text-align: right
	&--alignCenter
		text-align: center
	&--bold
		font-weight: bold
	&--whiteSpaceNorap
		white-space: nowrap
	&--verticalAlignTop
		vertical-align: top
	&--verticalAlignMiddle
		vertical-align: middle
	&--check
		position: relative
		padding-left: 1.25em
		&:before
			content: ''
			display: block
			position: absolute
			top: 0
			left: 0
			width: 1em
			height: 1em
			background: url(/images/ic_check_glay.png) no-repeat
			background-size: contain
