
/* 線で囲ったボックス
---------------------------------------- */
.react-dual-listbox {
	display: block;
	margin: 0 auto;
	width: 700px;
	.rdl-listbox {
		display: inline-block;
		width: 270px;
		.rdl-control-container {
			height: 250px;
			.rdl-control-label {
				display: none;
			}
			.rdl-control {
				width: 100%;
				height: 100%;
				border: 1px solid #eee;
				border-radius: 3px;
				padding: 5px 0;
				overflow-y: scroll;
				option {
					font-size: 13px;
					padding: 2px 10px;
					color: var(--color-font-default);
					text-shadow: none;
				}
			}
		}
	}
	.rdl-actions {
		display: inline-block;
		width: 34px
		margin: 0 30px;
		.rdl-actions-right {
			.rdl-move {
				width: 59px;
				height: 34px;
				background-repeat: no-repeat;
				background-position: center;
			}
			.rdl-move-right {
				background-image: url('/images/ic_arrow_r.png')
			}
			.rdl-move-all {
				background-image: url('/images/ic_arrows_r.png')
			}
		}
		.rdl-actions-left {
			.rdl-move {
				width: 59px
				height: 34px
				background-repeat: no-repeat
			}
			.rdl-move-left {
				background-image: url('/images/ic_arrow_l.png')
			}
			.rdl-move-all {
				background-image: url('/images/ic_arrows_l.png')
			}
		}
	}
}


/**


<div class="react-dual-listbox" id="fj9c~74">
	<div class="rdl-listbox rdl-available">
		<div class="rdl-control-container">
			<label class="rdl-control-label" for="fj9c~74-available">Available</label>
			<select class="rdl-control" id="fj9c~74-available" multiple=""></select>
		</div>
	</div>
	<div class="rdl-actions">
		<div class="rdl-actions-right">
			<button aria-label="Move all right" class="rdl-move rdl-move-all rdl-move-right" id="fj9c~74-move-all-right" title="Move all right" type="button">
				<span class="fa fa-chevron-right"></span>
				<span class="fa fa-chevron-right"></span>
			</button>
			<button aria-label="Move right" class="rdl-move rdl-move-right" id="fj9c~74-move-right" title="Move right" type="button">
				<span class="fa fa-chevron-right"></span>
			</button>
		</div>
		<div class="rdl-actions-left">
			<button aria-label="Move left" class="rdl-move rdl-move-left" id="fj9c~74-move-left" title="Move left" type="button">
				<span class="fa fa-chevron-left"></span>
			</button>
			<button aria-label="Move all left" class="rdl-move rdl-move-all rdl-move-left" id="fj9c~74-move-all-left" title="Move all left" type="button">
				<span class="fa fa-chevron-left"></span>
				<span class="fa fa-chevron-left"></span>
			</button>
		</div>
	</div>
	<div class="rdl-listbox rdl-selected">
		<div class="rdl-control-container">
			<label class="rdl-control-label" for="fj9c~74-selected">Selected</label>
			<select class="rdl-control" id="fj9c~74-selected" multiple="">
			<option data-index="0" data-real-value="luna" id="fj9c~74-option-luna" value="luna">Moon</option>
			<option data-index="1" data-real-value="phobos" id="fj9c~74-option-phobos" value="phobos">Phobos</option>
			<option data-index="2" data-real-value="deimos" id="fj9c~74-option-deimos" value="deimos">Deimos</option>
			<option data-index="3" data-real-value="io" id="fj9c~74-option-io" value="io">Io</option>
			<option data-index="4" data-real-value="europa" id="fj9c~74-option-europa" value="europa">Europa</option>
			<option data-index="5" data-real-value="ganymede" id="fj9c~74-option-ganymede" value="ganymede">Ganymede</option>
			<option data-index="6" data-real-value="callisto" id="fj9c~74-option-callisto" value="callisto">Callisto</option>
			<option data-index="7" data-real-value="mimas" id="fj9c~74-option-mimas" value="mimas">Mimas</option>
			<option data-index="8" data-real-value="enceladus" id="fj9c~74-option-enceladus" value="enceladus">Enceladus</option>
			<option data-index="9" data-real-value="tethys" id="fj9c~74-option-tethys" value="tethys">Tethys</option>
			<option data-index="10" data-real-value="rhea" id="fj9c~74-option-rhea" value="rhea">Rhea</option>
			<option data-index="11" data-real-value="titan" id="fj9c~74-option-titan" value="titan">Titan</option>
			<option data-index="12" data-real-value="iapetus" id="fj9c~74-option-iapetus" value="iapetus">Iapetus</option>
			</select>
		</div>
	</div>
	<input type="hidden" value="luna,phobos,deimos,io,europa,ganymede,callisto,mimas,enceladus,tethys,rhea,titan,iapetus">
</div>
 */
