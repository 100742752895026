.slideup__overlay
	display block

.slideup__box
	transition: .5s
	top: 0%;

.slideup-enter .slideup__box
	transition: none

.slideup-enter .slideup__box
.slideup-leave-active .slideup__box
	will-change: top;
	top: 100%;

.slideup__overlay
	display block

.slideup-on-tab__box,
.slideup-on-tab-enter-active .slideup-on-tab-enter-active
	transition: .5s
	top: 0%;
	margin-left: 10%

.slideup-on-tab-enter .slideup__box
	transition: none

.slideup-on-tab-enter .slideup-on-tab__box
.slideup-on-tab-leave-active .slideup-on-tab__box
	transition: none;
	will-change: top;
	top: 260%;
