.import-member,
.export-member,
.export-program-member,
.program-history-import,
.import-program,
.item-master-import
	&--template,
	&--fields
		.listBox
			width: auto
			&__option
				&--left:not([disabled])
					padding-left: 22px
		&__heading
			line-height: 29px
			display: inline-block
		&__template-box
			display: inline-block
			vertical-align: top
			margin-left: 30px
			&__save,
			&__select
				display: inline-block
			&__save
				margin-left: 30px
				&__input-name,
				&__save-button
					display: inline-block
					vertical-align: top
				&__input-name
					width: 206px
				&__save-button
					margin: 0 0 0 4px
					height: 29px
					font-size: 14px
					padding: 0
					line-height: 29px
					width: 60px
			&__select
				&__select,
				&__delete-button
					display: inline-block
					vertical-align: top
				&__select
					height: 29px
					width: 206px
				&__delete-button
					margin: 0 0 0 4px
					height: 29px
					font-size: 14px
					padding: 0
					line-height: 29px
					width: 60px
	&--listbox
		width: 660px
		text-align: right
		margin-top: 20px
		&__caption
			font-size 13px
			line-height: 32px
			width: 270px
			text-align: center
			display: inline-block
		.listBox
			width: 100%
		&__button--download-template
			background-image: url(/images/ic_download.png)
			height: 38px
			margin: 0
			margin-top: 10px
			display: inline-block
			width: 70px
			&.disabled
				cursor: default
				opacity: .3
	&--listbox-rightonly
		width: 270px
	&--file
		margin-top: 30px
		&__caption,
		&__upload
			display: inline-block
			vertical-align: top
		&__caption
			line-height: 35px
		&__upload
			margin-left: 24px
	.formTable__th
		padding-top: 17px
	&--base-date
		margin-bottom: 20px
	&--radio
		margin-top: 30px
	.main__base
		overflow: auto
