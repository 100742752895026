
/* シンプルなリスト（リストタイプ：square）
---------------------------------------- */
.modList
	&__item
		padding: 8px 0
		&--company
			color: #49b0be
			text-decoration: underline
			cursor: pointer
			display: inline-block
			&:hover
				text-decoration: none
	&--square
		&__item
			padding: 15px 0
			list-style-type: square
			margin-left: 1.2em
	&__option
		margin-left: 3.6em

