
/* ----------------------------------------
	history data table
---------------------------------------- */

.historyData
	position: relative
	overflow: hidden

.historyDataTable
	font-size: 1.1rem
	vertical-align: top

	&__row
		&:nth-child(even)
			background: $color-table-bg-even
		&.selected
			.dataTable__td
				background: $color-table-bg-selected
	&__cell
		&--check
			width: 40px
			padding: 0
		&--control
			width: 40px
			padding: 0
	&__th,
	&__td
		white-space: nowrap

	&__th
		padding: 0 20px
		height: 48px
		vertical-align: middle
		font-size: 1.3rem
		&:last-child
			border-right: 1px solid var(--color-border)

	&__td
		padding: 10px 20px
		vertical-align: middle
		text-align: center
		border: 1px solid var(--color-border)
		//background-color: #fff
		height: 48px
		&--link
			cursor: pointer
			color: #49b0be
	&--full
		width: 100%
		&__th.last-child
		&__td.last-child
			border-right: 0

.historyData
	.dataTable_fix
		.historyDataTable__th
			&:first-child, &:nth-child(2)
				border: 0
				padding: 0
			&:first-child
				width: 16px
			&:nth-child(2)
				width: 36px
			&:nth-child(3)
				border-left: 1px solid var(--color-border)
			&:nth-child(3),
			&:nth-child(4)
				border-top: 1px solid var(--color-border)
			&:last-child
				border-right: 0
		.historyDataTable__td
			&:first-child, &:nth-child(2)
				border: 0
				padding: 0 3px
				background-color: $color-white

	.dataTable_scroll
		width: calc(100% - 281px)
		.historyDataTable__row
			&:nth-child(1)
				border-top: 1px solid var(--color-border)
		.historyDataTable__th,
		.historyDataTable__td
			&:first-child
				border-left: 0
		.historyDataTable__th_label
			display: block
			min-width: 5em

	.data__overlay
		height: 48px

.historyTitle
	margin-bottom: 10px
	display: flex
	flex-wrap: wrap
	justify-content: flex-start
	align-items: flex-start
	color: var(--color-font-default)

	&__text
		margin-right: 80px
