
// ローディング
.loading_wrap
	display: none
	background-color: rgba(255,255,255,0.3)
	width: 100%
	height: 100%
	position: fixed
	left: 0
	right: 0
	top: 0
	bottom: 0
	z-index: 10000
	margin: auto
.loading
	color: $color-orange-01
	font-size: 90px
	text-indent: -9999em
	overflow: hidden
	width: 1em
	height: 1em
	border-radius: 50%
	margin: 72px auto
	position: absolute
	left: 0
	right: 0
	top: 0
	bottom: 0
	z-index: 11000
	margin: auto
	-webkit-transform: translateZ(0)
	-ms-transform: translateZ(0)
	transform: translateZ(0)
	-webkit-animation: load6 1.7s infinite ease
	animation: load6 1.7s infinite ease
	&__login
		color: $color-orange-01
		border-radius: 50%;
		width: 10em;
		height: 10em;
		font-size: 10px;
		z-index: 9999;
		position: absolute;
		text-indent: -9999em;
		border-top: 1.1em solid rgba(240,130,0, 0.2);
		border-right: 1.1em solid rgba(240,130,0, 0.2);
		border-bottom: 1.1em solid rgba(240,130,0, 0.2);
		border-left: 1.1em solid #f08200;
		transform: translate3d(-50%,-50%,0);
		top: 50%;
		left: 50%;
		animation: load8 1.1s infinite linear;
		&::after
			border-radius: 50%;
			width: 10em;
			height: 10em;
@-webkit-keyframes load6 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@keyframes load6 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}

@keyframes load8 {
  0% {
	transform-origin: 0% 0%;
    transform: rotate(0deg) translate3d(-50%,-50%,0);
  }
  100% {
	transform-origin: 0% 0%;
    transform: rotate(360deg) translate3d(-50%,-50%,0);
  }
}
