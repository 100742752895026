
for row in 0..20
	margin = row * 5
	.mt{margin}
		margin-top: 1px * margin
	.pt{margin}
		padding-top: 1px * margin
	.pl{margin}
		padding-left: 1px * margin
	.pr{margin}
		padding-right: 1px * margin
	.pb{margin}
		padding-bottom: 1px * margin
	.px{margin}
		padding-left: 1px * margin
		padding-right: 1px * margin
	.py{margin}
		padding-top: 1px * margin
		padding-bottom: 1px * margin
	.ml{margin}
		margin-left: 1px * margin
	.mr{margin}
		margin-right: 1px * margin
	.mb{margin}
		margin-bottom: 1px * margin

hr.page_separator
	margin: 30px 0

.w100
	width: 100%

.text__align--right
	text-align: right

.text__align--center
	text-align: right
