
/* ----------------------------------------
	pager
---------------------------------------- */
.pager
	display: inline-block
	&__item
		display: inline-block
		vertical-align: middle
		width: 26px
		height: 26px
		line-height: 26px
		color: var(--color-font-gray-02)
		text-align: center
		cursor: pointer
		margin: 0 3px
		&:hover
			background: $color-gray-04
	&--disabled
		opacity: .2
		pointer-events: none
	&__current
		background: $color-orange-01
		color: $color-white
		pointer-events: none
		&:hover
			background: $color-orange-01
	&__last
		margin-right: 5px
		background: url(/images/ic_first.png) no-repeat center
		&:hover
			background: $color-gray-04 url(/images/ic_first.png) no-repeat center
	&__prev
		margin-right: 5px
		background: url(/images/ic_prev.png) no-repeat center
		&:hover
			background: $color-gray-04 url(/images/ic_prev.png) no-repeat center
	&__next
		margin-left: 5px
		background: url(/images/ic_next.png) no-repeat center
		&:hover
			background: $color-gray-04 url(/images/ic_next.png) no-repeat center
	&__first
		margin-left: 5px
		background: url(/images/ic_last.png) no-repeat center
		&:hover
			background: $color-gray-04 url(/images/ic_last.png) no-repeat center

.page__current
	display: inline-block
	margin-right: 20px
	vertical-align: middle
	line-height: 27px
	color: var(--color-font-default)

.page__view
	display: inline-block
	&__item
		display: inline-block
		vertical-align: middle
	&__btn
		display: inline-block
		width: 20px
		height: 26px
		margin-left: 8px
		opacity: .5
		cursor: pointer
		&:hover
			opacity: 1
	&--current
		opacity: 1

	&--20
	&--2
		background: url(/images/ic_view_20.png) no-repeat center
	&--30
	&--3
		background: url(/images/ic_view_30.png) no-repeat center
	&--50
	&--5
		background: url(/images/ic_view_50.png) no-repeat center
	&--100
	&--10
		background: url(/images/ic_view_100.png) no-repeat center
	&--10e
		background: url(/images/ic_view_10.png) no-repeat center

.pagination
	display: inline-block
	&__item
		display: inline-block
		vertical-align: middle
	&__btn
		display: block
		width: 26px
		height: 26px
		line-height: 26px
		color: var(--color-font-gray-02)
		&:hover
			background: $color-gray-04
	&--disabled
		opacity: .2
		pointer-events: none
	&__current
		background: $color-orange-01
		color: $color-white
		&:hover
			background: $color-orange-01
	&__first
		margin-right: 5px
		background: url(/images/ic_first.png) no-repeat center
		&:hover
			background: $color-gray-04 url(/images/ic_first.png) no-repeat center
	&__prev
		margin-right: 5px
		background: url(/images/ic_prev.png) no-repeat center
		&:hover
			background: $color-gray-04 url(/images/ic_prev.png) no-repeat center
	&__next
		margin-left: 5px
		background: url(/images/ic_next.png) no-repeat center
		&:hover
			background: $color-gray-04 url(/images/ic_next.png) no-repeat center
	&__last
		margin-left: 5px
		background: url(/images/ic_last.png) no-repeat center
		&:hover
			background: $color-gray-04 url(/images/ic_last.png) no-repeat center
