.login__parent
  display:flex
  width: 100%


// .login__right,
.login__left
  // display: table-cell //不能设置 margin
  // table-layout: fixed //优点 1.加速table渲染。2.实现了布局优先
  // background:red;
  width: 50%
	
// box-shadow: 10px 10px 10px red inset;
.login__right
  // background:green;
  text-align: center
  flex-grow:1
  width: 50%
  margin: 180px auto 30px

.logo-images
	z-index: -2
	position relative
	// border: 5px solid #f08200
	cursor: pointer

.logo-link-container:hover
	box-shadow: inset 0 0 0 3px #F18103
	// cursor: pointer
	// animation: hoverEffect 1s
	// animation-fill-mode: forwards

.partners
	color: var(--color-font-default)
	font-size: 24px
	font-weight: bold
	margin-right: 50px
	margin-bottom: 20px

.logo__title
	color: var(--color-font-default)
	font-size: 14px
	font-weight: bold
	margin-bottom: 5px

.logo__sub__title
	color: var(--color-font-default)
	font-size: 10px
	font-weight: bold

.sidebar
	grid-column-gap: 20px
	grid-row-gap: 12px
	display: grid
	grid-template-columns: 260px 260px
	grid-template-rows: 65px 45px 65px 45px 65px 65px
	color: var(--color-font-default)
	margin-right: 50px

.login
	padding-bottom: 20px
	&__logo
		margin: 130px auto 40px
		text-align: center
	&__image
		max-width: 94px
		max-height: 94px
	&__container
		display: flex
		flex-wrap: wrap
		width: 70%
		margin: 0 auto 40px
		padding: 80px 30px
		background: $color-white;
		box-shadow: 10px 10px 10px rgba(0,0,0,.2)
	&__left__container
		display: flex
		flex-direction: column
		width: 48%
		.items
			margin-bottom: 20px
			::placeholder
				color: #aaa
				padding-left: 15px
		.link--text
			margin-top 10px
			font-size 12px
		.link--sso--text
			font-size 20px
			margin-top: 2.4rem
			cursor: pointer
	&__right__container
		display: flex
		flex-direction: column
		width: 48%
		position: relative
		.description
			opacity: 0
			white-space: pre-wrap;
			color: rgb(104, 104, 104)
			font-size: 11px
			text-align: center
			line-height: 20px
			font-family: 'Noto Sans Japanese', serif;
			margin: -5px auto 20px
			max-width: 560px
			min-height: 60px
		.hr_cockpit__container
			opacity: 0
			max-width: 480px
			min-width: 340px
			margin: 0 auto
			position: relative
			background: #f4f4f4
			.hr_cockpit
				width: 100%
				height: 280px
			.slider__container
				position: absolute
				top: 70%
				width: 100%
				min-width: 340px
				height: 200px // .hr_cockpitの画像に余計な空白があるため、高さを出して覆い隠す目的で設定
				p
					color: rgb(225, 108, 35)
					font-size: 13px
					font-weight: bold
					text-align: center
					margin-bottom: 18px
					letter-spacing: 2px
					min-width: 340px
				.slick-list
					padding: 0
					margin: 0 20px
				.slider_img
					height: 30px
					padding: 0 5px
				.arrow
					position: absolute
					display: inline-block
					cursor: pointer
					z-index: 1
					&::before
						content: ''
						width: 15px
						height 15px
						border: 0px
						border-top: 3px solid rgb(225, 108, 35)
						border-right: 3px solid rgb(225, 108, 35)
						position: absolute
					&__prev
						padding-top: 7.5px
						padding-left: 5px
						left: 0
						&::before
							-ms-transform: rotate(225deg)
							-webkit-transform: rotate(225deg)
							transform: rotate(225deg)
					&__next
						padding-right: 15px
						margin-top: -22.5px
						right: 5px
						&::before
							-ms-transform: rotate(45deg)
							-webkit-transform: rotate(45deg)
							transform: rotate(45deg)
	&__text
		margin-top: 20px
		margin-bottom: 20px
		text-align: center
		font-weight: bold
		font-size: 16px
		color: $color-gray-01;
	&__form
		background: $color-white;
		text-align: center
		&--remind
			background: $color-white;
			box-shadow: 0 0 1px rgba(0,0,0,.2)
			width: 562px
			margin: 20px auto 50px;
			padding: 30px;
			text-align: center;
		&--table
			text-align: left
		&--th
			width: 132px;
			font-size: 13px;
			font-family: 'Noto Sans Japanese', serif;
			text-align: left;
			color: $color-gray-01;
			vertical-align: inherit
			padding-top: 13px
		&--td
			width: 342px;
			height: 75px;
		&__rules
			line-height: 1.5;
			&--table
				text-align: left;
				margin-bottom: 30px;
				&--th
					padding-right: 20px;
.password__reminder__form
	box-shadow: 0 0 1px rgba(0,0,0,.2)
	width: 562px
	margin: 20px auto 30px
	padding: 30px;

.auth_code_sender
	&__text
		margin-top: 20px
		margin-bottom: 20px
		text-align: center
		font-weight: bold
		font-size: 24px
		color: $color-gray-01;
	&__formHeading
		text-align: left;
		color: $color-gray-01;
		font-size: 16px;
		font-weight: bold;
	&__input
		width: 280px;
		height: 40px;
		text-align: center;
		margin-top: 28px;
		border:2px orange solid;
	&__anchor
		font-size: 12px;
		cursor: pointer;
	&__buttons
		width: 100%
		overflow: hidden
		padding-top: 40px
		display: flex
		flex-wrap: wrap
		justify-content: center
		align-items: center
	&__button
		width: 120px;
		margin: 0 9px
		border-radius: 2px
		background-color: $color-orange-01
		padding: 12px 20px 10px
		letter-spacing: normal
		color: $color-white
		font-size: 14px
		text-align: center
		cursor: pointer
		&--disable
			color: $color-gray-03
			background-color: $color-disable
			pointer-events: none


.auth_code_sender__form
	box-shadow: 0 0 1px rgba(0,0,0,.2)
	width: 360px
	margin: 20px auto 30px
	padding: 30px;
	border-radius: 10px

.auth_code_sender_app
	&__heading
		text-align: left;
		color: $color-gray-01;
		font-size: 16px;
		font-weight: bold;
	&__note
		text-align: left;
		color: $color-gray-02;
		font-size: 14px;
		font-weight: normal;
	&__flex-column
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	&__anchor
		display: inline-block;
		font-size: 16px;
		cursor: pointer;
	&__input
		width: 180px;
		height: 40px;
		text-align: center;
		border:1px black solid;
	&__button
		margin: 0 9px
		border-radius: 2px
		padding: 12px 20px 10px
		letter-spacing: normal
		background-color: $color-orange-01
		color: $color-white
		font-size: 14px
		text-align: center
		cursor: pointer

//経営人用コンテナスタイル
.keiei
	&__container
		opacity: 0
		max-width: 520px
		margin: 0 auto
		height: 100%
		width: 100%
		display: flex
		padding: 15px
		background: #f2f6f7
		border-radius: 6px
	&__left
		display: flex
		flex-direction: column
		justify-content: space-around
		width: 50%
		height: 100%
		padding: 20% 3%
	&__logo
		object-fit: contain
	&__button
		display: flex
		justify-content: center
		align-items: center
		border-radius: 25px
		background: #1ea50a
		padding: 8px 24px
		color: #FFF
		transition: all ease .3s
		&__image
			margin-left: 6px
		&:hover
			background: #22bd0b
	&__iframe
		width: 50%
		height: 100%
		&__container
			display: flex
			width: 100%
			height: 100%
			padding: 15px
			background: #f2f6f7
	&__description
		font-size: 12px;
		line-height: 1.9;

// 奨学金バンク
.shogakukinbank
	&__container
		display: flex
		height: 100%
		justify-content: center
		align-items: center
	&__image
		max-width: 100%
		height: auto

@media screen and (max-width: 1050px)
	.login
		&__logo
			margin-top: 100px;
		&__container
			width: 100vmin
			padding: 2.5vmin 1.5vmin
		&__form
			background: $color-white;
			box-shadow: 0 0 1px rgba(0,0,0,.2);
			margin: 0 auto;
			padding: 30px 3vmin 40px;
			text-align: center;
			width: 100%
			max-width: 402px
			&--table
				text-align: left
				width: 100%
			&--th
				display: block
				font-size: 13px;
				font-family: 'Noto Sans Japanese', serif;
				text-align: left;
				color: $color-gray-01;
				vertical-align: top
				padding: 13px 0
				width: 100%
			&--td
				display: block
				height: auto
				width: 100%
			&__rules
				width: 100%
				line-height: 1.5;
				font-size: 13px;
				font-family: 'Noto Sans Japanese', serif;
				color: $color-gray-01;
				height: 4em;
				&--th
					display: inline-block
					text-align: left;
				&--td
					display: block
					text-align: left;
.password__reminder__form
	.login__right
		display: none
	.login__left
		width: 100%
