.link
	&--text
		color: $color-blue
		font-size: 13px
		display: inline-block
		margin-top: 30px
		&:hover
			text-decoration: underline
		&:after
			position: relative
			content: ''
			display: inline-block
			width: 10px
			height: 10px
			border-right: 2px solid $color-blue
			border-top: 2px solid $color-blue
			transform: rotate(45deg)
	&--text--line
		color: $color-blue
		font-size: 13px
		&:hover
			text-decoration: underline

.link
	&:hover
		text-decoration: underline
	&.is-active
		font-weight: bold
	&--iconArrow
		font-size: 13px
		line-height: 1.5
		&:after
			position: relative
			content: ''
			display: inline-block
			margin-left: 5px
			width: 10px
			height: 10px
			border-right: 2px solid $color-blue
			border-top: 2px solid $color-blue
			transform: rotate(45deg)
	&--iconBlank
		font-size: 13px
		line-height: 1.5
		&:after
			content: ''
			display: inline-block
			margin-left: 5px
			width: 15px
			height: 15px
			background: url(/images/ic_blank.png);
			vertical-align: sub
	&--iconPdf
		font-size: 13px
		line-height: 1.5
		&:after
			content: ''
			display: inline-block
			margin-left: 5px
			width: 38px
			height: 17px
			background: url(/images/ic_pdf.png);
			vertical-align: sub

